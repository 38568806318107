import type { ConnectwareError, SSOAuthenticationMethod } from '../../../domain'

import { LogoutUsecase } from '.'

export class SSOLoginUsecase extends LogoutUsecase {
    async fetchSupported (): Promise<void> {
        const supportedSSOs = await this.authenticationService.fetchActiveSSOAuthenticationMethods().catch((error: ConnectwareError) => error)
        this.setState({ supportedSSOs })
    }

    async redirectToAuthenticationUrl (type: SSOAuthenticationMethod): Promise<void> {
        const url = await this.authenticationService.fetchSSOAuthenticationUrl(type)
        this.browserService.redirect(url)
    }
}

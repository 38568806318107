import { LogoutUsecase } from '.'

export class LoginFromTokenUsecase extends LogoutUsecase {
    loginBrowserFromToken (): boolean {
        const tokenOrError = this.browserService.extractURLAuthentication()

        if (tokenOrError === null) {
            /** There is no token, give up */
            return false
        }

        if (typeof tokenOrError === 'string') {
            /** Attempt to fetch session and load it and drop the args from the URL */
            this.updateAuthenticationFromToken(tokenOrError).finally(() => this.browserService.removeURLAuthentication())
        } else {
            this.updateAuthentication(tokenOrError)
        }

        return true
    }
}

import React, { type FC } from 'react'
import type { SxProps, Theme } from '@mui/material'
import { DeleteForever, Pause, PlayArrow } from '@mui/icons-material'

import { selectExplorerIsTailing } from '../../../../domain'

import { useAppState, useAppUsecase } from '../../State'

const baseButtonStyle: SxProps<Theme> = { my: 'auto', mx: 1, cursor: 'pointer' }
const playButtonStyle: SxProps<Theme> = { ...baseButtonStyle, color: 'green.600' }
const clearButtonStyle: SxProps<Theme> = { ...baseButtonStyle, color: 'red.600' }
const pauseButtonStyle: SxProps<Theme> = { ...baseButtonStyle, color: 'grey.600' }

export const Controls: FC = () => {
    const updateExplorerTail = useAppUsecase('updateExplorerTailUsecase')
    const isTailing = useAppState(selectExplorerIsTailing)

    if (isTailing === null) {
        return null
    }

    return (
        <>
            {isTailing ? (
                <Pause id="pauseTailButton" sx={pauseButtonStyle} onClick={() => updateExplorerTail.toggleTailing()} />
            ) : (
                <PlayArrow id="playTailButton" sx={playButtonStyle} onClick={() => updateExplorerTail.toggleTailing()} />
            )}
            <DeleteForever id="clearTailButton" sx={clearButtonStyle} onClick={() => updateExplorerTail.clearTail()} />
        </>
    )
}

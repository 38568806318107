import {
    type CybusRole,
    type RoleCreationForm,
    type RoleEditingForm,
    selectUsersManagementRoleCreation,
    selectUsersManagementRoleUpdating,
} from '../../../domain'
import { LoadingUsecase } from '../Loading'

import { FormWithTemplatesUsecase } from './Template'

export class EditRoleUsecase extends FormWithTemplatesUsecase<RoleEditingForm> {
    protected readonly selected = 'roles'

    protected readonly formName = 'roleUpdating'

    protected readonly selectForm = selectUsersManagementRoleUpdating

    protected async request (): Promise<void> {
        const { id, name, permissions, ldapGroupDn, msEntraIdGroupIds } = this.getCurrentForm()
        await this.userService.updateRole({ id, name, permissions, ldapGroupDn, msEntraIdGroupIds })
    }

    async delete (id: CybusRole['id']): Promise<void> {
        await this.userService.deleteRole(id)
        await this.reload()
    }

    async start ({ id, name, permissions, users, ldapGroupDn, canDelete, canEditOthers, canEditName, msEntraIdGroupIds }: CybusRole): Promise<void> {
        const { isLdapEnabled, isEntraEnabled } = await this.userService.fetchManagementConfiguration()

        this.initializeForm({
            id,
            name,
            permissions,
            users,
            ldapGroupDn: isLdapEnabled ? ldapGroupDn : null,
            msEntraIdGroupIds: isEntraEnabled ? msEntraIdGroupIds : null,
            canDelete,
            canEditOthers,
            canEditName,
            ...EditRoleUsecase.derivedTemplateValues,
        })
    }

    async load (roleName: CybusRole['name']): Promise<void> {
        const loadindUsecase = this.getUsecase(LoadingUsecase)
        await loadindUsecase.withLoading(this.userService.fetchRole(roleName).then((role) => this.start(role)))
    }
}

export class CreateRoleUsecase extends FormWithTemplatesUsecase<RoleCreationForm> {
    protected readonly selected = 'roles'

    protected readonly formName = 'roleCreation'

    protected readonly selectForm = selectUsersManagementRoleCreation

    protected async request (): Promise<void> {
        const { name, permissions, ldapGroupDn, msEntraIdGroupIds } = this.getCurrentForm()
        await this.userService.createRole({ name, permissions, ldapGroupDn, msEntraIdGroupIds })
    }

    async start (): Promise<void> {
        const loadindUsecase = this.getUsecase(LoadingUsecase)
        await loadindUsecase.withLoading(
            this.userService.fetchManagementConfiguration().then(({ isLdapEnabled, isEntraEnabled }) => {
                this.initializeForm({
                    name: '',
                    permissions: [],
                    ldapGroupDn: isLdapEnabled ? '' : null,
                    msEntraIdGroupIds: isEntraEnabled ? [] : null,
                    canDelete: true,
                    canEditOthers: true,
                    canEditName: true,
                    ...CreateRoleUsecase.derivedTemplateValues,
                })
            })
        )
    }
}

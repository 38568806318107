import type { PromiseType } from 'utility-types'

import { ConnectwareError, ConnectwareErrorType, type CybusDetailedCoreContainer, type CybusDetailedServiceContainer } from '../../../../../../../domain'

import { CONTAINER_MANAGER_AGENT, mapDetailedCoreContainer, mapDetailedServiceContainer } from '../../../../../../Connectware'
import { CONTAINER_MANAGER_CLASSNAME, CONTAINER_MANAGER_CORE_INSTANCE, CONTAINER_MANAGER_DOCKER_INSTANCE } from '../../../../../constants'
import type { ContainerManagerOrchestratorProxy as ContainerManagerProxy } from '../../../../../proxies'
import { SubscriptionHandlerType, type VrpcHandlerMappingPropertiesArgs, type VrpcInstanceToVirtualOneSubscriptionHandler } from '../..'
import { createContainerManagerOrchestratorProxyHandler } from '../Listener'

type ContainerInspectionResponse = PromiseType<ReturnType<ContainerManagerProxy['inspectContainer']>>

const mapValidContainer = <C>(
    id: string,
    container: ContainerInspectionResponse,
    mapper: (id: string, container: Exclude<ContainerInspectionResponse, void>) => C
): C => {
    if (!container) {
        throw new ConnectwareError(ConnectwareErrorType.NOT_FOUND, 'Container not found', { id })
    }

    return mapper(id, container)
}

type CybusDetailedServiceContainerHandler = VrpcInstanceToVirtualOneSubscriptionHandler<ContainerManagerProxy, CybusDetailedServiceContainer>
type CybusDetailedServiceContainerHandlerArgs = VrpcHandlerMappingPropertiesArgs<CybusDetailedServiceContainerHandler>
export class VrpcContainerManagerOrchestratorProxyToDetailedServiceContainerHandler implements CybusDetailedServiceContainerHandler {
    readonly type = SubscriptionHandlerType.INSTANCE_ONE_TO_VIRTUAL_ONE

    readonly agent = CONTAINER_MANAGER_AGENT

    readonly classNameFilter = CONTAINER_MANAGER_CLASSNAME

    readonly ignoreInstanceByFilter = null

    readonly sourceInstanceName = CONTAINER_MANAGER_DOCKER_INSTANCE

    readonly ignoreInstances = null

    readonly optionalFilters = []

    readonly requiredFilters = []

    readonly onChange = createContainerManagerOrchestratorProxyHandler

    mapToDomain ({ instance, id }: CybusDetailedServiceContainerHandlerArgs['DomainMapperArgs']): Promise<CybusDetailedServiceContainerHandlerArgs['Domain']> {
        return instance.inspectContainer(id).then((container) => mapValidContainer(id, container, (i, c) => mapDetailedServiceContainer(i, c)))
    }
}

type CybusDetailedCoreContainerHandler = VrpcInstanceToVirtualOneSubscriptionHandler<ContainerManagerProxy, CybusDetailedCoreContainer>
type CybusDetailedCoreContainerHandlerArgs = VrpcHandlerMappingPropertiesArgs<CybusDetailedCoreContainerHandler>
export class VrpcContainerManagerOrchestratorProxyToDetailedCoreContainerHandler implements CybusDetailedCoreContainerHandler {
    readonly type = SubscriptionHandlerType.INSTANCE_ONE_TO_VIRTUAL_ONE

    readonly agent = CONTAINER_MANAGER_AGENT

    readonly classNameFilter = CONTAINER_MANAGER_CLASSNAME

    readonly ignoreInstanceByFilter = null

    readonly ignoreInstances = null

    readonly sourceInstanceName = CONTAINER_MANAGER_CORE_INSTANCE

    readonly optionalFilters = []

    readonly requiredFilters = []

    readonly onChange = createContainerManagerOrchestratorProxyHandler

    mapToDomain ({ instance, id }: CybusDetailedCoreContainerHandlerArgs['DomainMapperArgs']): Promise<CybusDetailedCoreContainerHandlerArgs['Domain']> {
        return instance.inspectContainer(id).then((container) => mapValidContainer(id, container, (i, c) => mapDetailedCoreContainer(i, i, c)))
    }
}

import { areDetailedCybusServiceEquals, Capability, type CybusDetailedService } from '../../../../../../domain'

import { type CybusServiceDependencies, type CybusServiceProxyParams, mapDetailedService } from '../../../../../Connectware'
import { BaseSubscriptionStrategy } from '../Base'

export class DetailedServiceStrategy extends BaseSubscriptionStrategy<'service'> {
    readonly requiredPermissions = [Capability.SERVICE_READ]

    protected override readonly areEquals = areDetailedCybusServiceEquals

    override retrieveOne (id: string): Promise<CybusDetailedService> {
        return Promise.all([
            this.retrieve({
                capability: Capability.SERVICE_READ,
                method: 'GET',
                path: '/api/services/+',
                pathParams: [id],
                mapper: (j: CybusServiceProxyParams) => j,
            }),
            this.retrieve({
                capability: Capability.SERVICE_READ,
                method: 'GET',
                path: '/api/services/+/dependencies',
                pathParams: [id],
                mapper: (j: CybusServiceDependencies) => j,
            }),
        ]).then(([params, dependencies]) => mapDetailedService(params, dependencies))
    }
}

import { objectEntries } from '../../../../utils'
import type { CybusResourceDeviation, CybusServiceDeviation } from '../../../../domain'

import type { CybusServiceProxyInfo, CybusServiceProxyParams } from '../../proxies'
import { AsyncMapper, mapToStatusType } from '..'

export const mapCybusResourceDeviations = (deviation: CybusServiceProxyParams['deviation']): CybusResourceDeviation[] =>
    objectEntries(deviation).map(([id, { expected, actual, reason }]) => ({
        resourceId: id,
        expected: mapToStatusType(expected),
        actual: mapToStatusType(actual),
        reason: reason || null,
    }))

export class CybusServiceDeviationMapper extends AsyncMapper<Pick<CybusServiceProxyInfo, 'id' | 'isDeviated'>, CybusServiceDeviation> {
    protected override map ({ id, isDeviated }: Pick<CybusServiceProxyInfo, 'id' | 'isDeviated'>): Promise<CybusServiceDeviation | null> | null {
        return isDeviated ? Promise.resolve({ name: id }) : null
    }
}

import { isArray, normalize, QueuePool, search, splitByLength } from '../../../../../../utils'
import { type CybusServiceDataResource, CybusServiceDataResourceType } from '../../../../../../domain'
import type { SubscriptionsTypes } from '../../../../../../application'

import { SERVICE_AGENT } from '../../../../../Connectware'
import { SERVICE_CLASSNAME } from '../../../../constants'
import type { EndpointProxy, MappingProxy, NodeProxy, ServiceProxy } from '../../../../proxies'
import {
    createProxyEventsHandler,
    type VrpcHandlerMappingPropertiesArgs as HandlerArgs,
    type VrpcInstanceToPageSubscriptionHandler as PageHandler,
    SubscriptionHandlerType,
} from '..'

const resourceMap = [
    { pattern: /^Cybus::Node::\w+$/, type: CybusServiceDataResourceType.NODE },
    { pattern: /^Cybus::Endpoint$/, type: CybusServiceDataResourceType.ENDPOINT },
    { pattern: /^Cybus::Mapping$/, type: CybusServiceDataResourceType.MAPPING },
] as const

type ResourcesPageHandler = PageHandler<ServiceProxy, SubscriptionsTypes['serviceResources']>

export class VrpcServiceProxyToServiceDataResourceInstanceHandler implements ResourcesPageHandler {
    readonly type = SubscriptionHandlerType.INSTANCE_ONE_TO_PAGE

    readonly optionalFilters = ['service' as const]

    readonly requiredFilters = ['service' as const]

    readonly classNameFilter = SERVICE_CLASSNAME

    readonly agent = SERVICE_AGENT

    readonly ignoreInstances = null

    readonly ignoreInstanceByFilter = null

    readonly sourceInstanceName = null

    readonly onChange = createProxyEventsHandler<ServiceProxy>('state', 'deviation')

    async mapToDomain ({
        filter: { service },
        instance,
        instanceName,
        pagination,
        getInstance,
    }: HandlerArgs<ResourcesPageHandler>['DomainMapperArgs']): Promise<HandlerArgs<ResourcesPageHandler>['Domain']> {
        if (service !== instanceName) {
            return null
        }

        /**
         * Out of an abundance of caution, there is a queue pool aux variable here
         * It prevents too many requests to go through at once
         *
         * The pool size is chosen to not overwhelm the backend with too many requests
         */
        const pool = new QueuePool(25)

        const normalizedSearch = (pagination.search && pagination.search.split(/\W+/).map((t) => normalize(t))) || []

        const { resources } = await pool.push(() => instance.getParams())
        const serviceResources: Pick<CybusServiceDataResource, 'id' | 'type'>[] = Object.values(resources)
            .flatMap(({ physicalId, type }) => {
                if (typeof physicalId !== 'string') {
                    /** Flow not expected to be relevant for this mapper */
                    return []
                }

                const match = resourceMap.find(({ pattern }) => pattern.test(type))
                if (!match) {
                    /** Not a relevant resource */
                    return []
                }

                if (normalizedSearch.length > 0 && ![physicalId, match.type].some((content) => search(content, normalizedSearch))) {
                    /** Being searched, and not found */
                    return []
                }

                return [{ id: physicalId, type: match.type }]
            })
            /** Sort the data */
            .sort((a, b) => {
                const aValue = a[pagination.sort.column]
                const bValue = b[pagination.sort.column]

                return pagination.sort.asc ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue)
            })

        /** Paginate and extract the wanted page */
        const pages = splitByLength(serviceResources, pagination.pagination.pageSize)
        const wantedPage = (pages[pagination.pagination.page] ? pages[pagination.pagination.page] : pages[pages.length - 1]) ?? []

        /** Fetch topics of entries */
        const wantedPageWithTopics = wantedPage.map(async (entry) => {
            const topics = await pool
                .push(() => getInstance<NodeProxy | MappingProxy | EndpointProxy>(entry.id))
                .then((instance) => {
                    if ('getTopics' in instance) {
                        /** There is a method to get topics, so just call it */
                        return pool.push(() => instance.getTopics())
                    }

                    /** Iterate over the rest */
                    return Promise.all([pool.push(() => instance.getMappedEndpointTopics()), pool.push(() => instance.getParams())]).then(
                        ([topics, { mappings }]) => [
                            ...Object.values(topics).flat(),
                            ...mappings
                                .flatMap((m) => [m.publish, ...(isArray(m.subscribe) ? m.subscribe : [m.subscribe])])
                                .flatMap((ps) => ('topic' in ps ? [ps.topic] : [])),
                        ]
                    )
                })

            return { ...entry, topics }
        })

        return {
            current: await Promise.all(wantedPageWithTopics),
            totalCount: serviceResources.length,
            pageSize: pagination.pagination.pageSize,
            page: Math.max(pages.indexOf(wantedPage), 0),
        }
    }
}

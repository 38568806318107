import React, { type FC } from 'react'
import { Autocomplete, Button, Grid, Link, TextField, Typography } from '@mui/material'
import { People, Work } from '@mui/icons-material'

import { isArrayNotEmpty } from '../../../../utils'
import {
    areRoleFormsEqual,
    type CybusUser,
    isRoleCreatable,
    isRoleRemovable,
    isRoleUpdatable,
    type RoleCreationForm,
    type RoleEditingForm,
    selectUsersManagementRoleCreation,
    selectUsersManagementRoleUpdating,
    Translation,
} from '../../../../domain'

import { FormattedList, FormattedTranslation, useTranslator } from '../../Internationalization'
import { useAppUsecase } from '../../State'
import { useAsyncCallback } from '../../Callback'
import { CustomCopy } from '../../Copy'

import { PermissionsTable, PermissionsTemplateSection } from '../Permissions'
import { EditableWarning, EntryInput, EntryInputLabel, GridFormEntry, ManagedDialog, type ManagedDialogContentProps } from '../Modal'

const RoleModalContent = <Form extends RoleEditingForm | RoleCreationForm,>({
    loading,
    equals,
    form,
    usecase,
}: ManagedDialogContentProps<Form, 'createRoleUsecase' | 'editRoleUsecase'>): ReturnType<FC> => {
    const users = 'users' in form ? form.users : []
    const id = 'id' in form ? form.id : null

    const editUserUsecase = useAppUsecase('editUserUsecase')
    const [onUserClick] = useAsyncCallback((u: CybusUser['username']) => editUserUsecase.load(u))

    const disabled = !form.canEditOthers || loading
    const disabledEditName = !form.canEditName || loading

    const translator = useTranslator()

    return (
        <Grid container spacing={2} alignContent="center" alignItems="center">
            <EditableWarning canEditAll={form.canEditOthers} type="role" />
            <GridFormEntry
                label={<EntryInputLabel icon={Work} label={Translation.NAME} />}
                endAdornmentSize={1}
                endAdornment={
                    id && (
                        <CustomCopy button={(props) => <Button size="small" color="secondary" variant="contained" fullWidth {...props} />}>
                            {form.name}
                        </CustomCopy>
                    )
                }
            >
                <EntryInput
                    data-testid="name"
                    value={form.name}
                    placeholder={translator.formatTranslation(Translation.ROLE_NAME)}
                    disabled={disabledEditName}
                    onChange={({ target }) => usecase.update({ name: target.value.trim() })}
                />
            </GridFormEntry>
            {form.ldapGroupDn !== null && (
                <GridFormEntry label={<EntryInputLabel icon={Work} label={Translation.DN_OF_AD_GROUP} />}>
                    <EntryInput
                        data-testid="ldapGroupDn"
                        value={form.ldapGroupDn}
                        placeholder={translator.formatTranslation(Translation.DN_OF_AD_GROUP)}
                        disabled={disabled}
                        onChange={({ target }) => usecase.update({ ldapGroupDn: target.value.trim() })}
                    />
                </GridFormEntry>
            )}
            {form.msEntraIdGroupIds !== null && (
                <GridFormEntry label={<EntryInputLabel icon={Work} label={Translation.ENTRA_OBJECT_ID} />}>
                    <Autocomplete
                        data-testid="msEntraIdGroupIds"
                        multiple
                        freeSolo
                        size="small"
                        disabled={disabled}
                        renderInput={(params) => <TextField {...params} variant="standard" size="medium" />}
                        value={form.msEntraIdGroupIds}
                        options={form.msEntraIdGroupIds}
                        onChange={(_, data) => usecase.update({ msEntraIdGroupIds: data })}
                    />
                </GridFormEntry>
            )}
            {id && (
                <GridFormEntry label={<EntryInputLabel icon={People} label={Translation.USER} values={{ count: 2 }} />}>
                    <Typography data-testid="users-list" variant="body2">
                        {isArrayNotEmpty(users) ? (
                            <FormattedList
                                type="conjunction"
                                value={
                                    equals && !disabled
                                        ? users.map((u) => (
                                              <Link key={u} onClick={() => onUserClick(u)}>
                                                  {u}
                                              </Link>
                                          ))
                                        : users
                                }
                            />
                        ) : (
                            <FormattedTranslation id={Translation.ACTIVE_USER} values={{ count: users.length }} />
                        )}
                    </Typography>
                </GridFormEntry>
            )}
            <PermissionsTemplateSection
                endAdornmentSize={2}
                disabled={disabled}
                usecase={usecase}
                templateInput={form.templateInput}
                templates={form.templates}
                selectedTemplate={form.selectedTemplate}
            />
            <Grid item xs={12}>
                <PermissionsTable disabled={disabled} permissions={form.permissions} usecase={usecase} />
            </Grid>
        </Grid>
    )
}

export const RoleEditModal: FC = () => (
    <ManagedDialog
        maxWidth="md"
        title={Translation.EDIT_ROLE}
        deleteConfirmation={Translation.DELETE_ROLE_CONFIRMATION}
        usecase="editRoleUsecase"
        formSelector={selectUsersManagementRoleUpdating}
        isRemovable={isRoleRemovable}
        areEquals={areRoleFormsEqual}
        isConfirmable={isRoleUpdatable}
    >
        {RoleModalContent}
    </ManagedDialog>
)

export const RoleCreationModal: FC = () => (
    <ManagedDialog
        maxWidth="md"
        title={Translation.CREATE_ROLE}
        usecase="createRoleUsecase"
        formSelector={selectUsersManagementRoleCreation}
        areEquals={areRoleFormsEqual}
        isConfirmable={isRoleCreatable}
    >
        {RoleModalContent}
    </ManagedDialog>
)

import { isArrayNotEmpty } from '../../../../../../utils'
import type { SubscriptionsTypes } from '../../../../../../application'

import type { ServiceProxy } from '../../../../proxies'
import { SubscriptionHandlerType, type VrpcHandlerMappingPropertiesArgs, type VrpcInstanceToListSubscriptionHandler } from '..'
import { BaseServiceProxyHandler } from './Service'

type ServiceLinksHandler = VrpcInstanceToListSubscriptionHandler<ServiceProxy, SubscriptionsTypes['servicesLinks']>
type ServiceLinksHandlerArgs = VrpcHandlerMappingPropertiesArgs<ServiceLinksHandler>

export class VrpcLinkedServiceProxyInstanceHandler
    extends BaseServiceProxyHandler<SubscriptionHandlerType.INSTANCE_ONE_TO_LIST>
    implements ServiceLinksHandler {
    readonly type = SubscriptionHandlerType.INSTANCE_ONE_TO_LIST

    async mapToDomain (args: ServiceLinksHandlerArgs['DomainMapperArgs']): Promise<ServiceLinksHandlerArgs['Domain']> {
        const { id, name, links } = await this.mapToCybusService(args)

        if (isArrayNotEmpty(links)) {
            return [{ id, name, links }]
        }

        return []
    }
}

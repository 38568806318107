import type { ArrayType, ReadonlyRecord } from '../../../../utils'
import {
    type CybusPermission,
    type CybusPermissionOperations,
    type CybusPersistedPermission,
    type CybusRole,
    type CybusUser,
    CybusUserProvider,
    MFAStatus,
    type PaginatedData,
} from '../../../../domain'

import { type BackendDefinition, mapContextToPermissionContext, mapResponseToOperations } from '../../../Connectware'

import type { PaginatedPermissionsResponse, PaginatedRole, PaginatedRoles, PaginatedUsers, Pagination, Permission, PermissionWithUsage, User } from '../Types'
import { mapGrantTypeToAuthenticationMethod } from './Grant'
import { MutabilityMapper } from './MutabilityMapper'

export type MapUserDataConfiguration = Readonly<{ protectedUsers: Set<string>, protectedRoles: Set<string> }>

export type PaginationConfiguration = Readonly<{ pageSize: number, searchSize: number }>

const userProviderMapping: ReadonlyRecord<BackendDefinition<'auth-server', 'IdentityProvider'>, CybusUserProvider> = {
    ldap: CybusUserProvider.LDAP,
    local: CybusUserProvider.CONNECTWARE,
    msentraid: CybusUserProvider.MICROSOFT_ENTRA_ID,
}

const mapMfaStatus = (
    isMfaSetup: boolean | undefined,
    isMFAEnrolled: boolean,
    identityProvider: BackendDefinition<'auth-server', 'IdentityProvider'>
): MFAStatus => {
    if (!isMfaSetup) {
        return MFAStatus.NOT_SETUP
    }

    if (identityProvider === 'msentraid') {
        return MFAStatus.NOT_APPLICABLE
    }

    return isMFAEnrolled ? MFAStatus.ENABLED : MFAStatus.DISABLED
}

export class UserManagementMapper {
    private readonly userMutabilityMapper: MutabilityMapper
    private readonly roleMutabilityMapper: MutabilityMapper

    private static mapPage<T> (current: T[], { rowsPerPage, pageNumber, totalRows }: Pagination): PaginatedData<T> {
        return { current, totalCount: totalRows, pageSize: rowsPerPage, page: pageNumber }
    }

    private static mapCybusPersistedPermission ({ id, ...rawPermission }: Permission): CybusPersistedPermission {
        return {
            id,
            context: mapContextToPermissionContext(rawPermission.context),
            resource: rawPermission.resource,
            ...mapResponseToOperations(rawPermission.operation),
        }
    }

    private static mapPermission ({ context, resource, usage }: PermissionWithUsage): CybusPermission {
        const users: Record<string, CybusPermissionOperations> = {}
        const roles: Record<string, CybusPermissionOperations> = {}

        usage.forEach(({ isRoleShared, operation, roleName, username }) => {
            const operations = mapResponseToOperations(operation)

            if (isRoleShared) {
                roles[roleName] = operations
            }

            if (username) {
                users[username] = operations
            }
        })

        return { context: mapContextToPermissionContext(context), resource, users, roles }
    }

    constructor (config: MapUserDataConfiguration) {
        this.roleMutabilityMapper = new MutabilityMapper(config.protectedRoles)
        this.userMutabilityMapper = new MutabilityMapper(config.protectedUsers)
    }

    private mapUser (
        {
            id,
            username,
            grantTypes,
            tokens,
            identityProvider,
            roles,
            mqttPublishPrefix,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            mfa_is_enrolled: isMFAEnrolled,
            enforceMFAEnrollment,
        }: User,
        isMfaSetup: boolean
    ): CybusUser {
        const user: CybusUser = {
            permissions: [],
            allPermissions: [],
            roles: [],

            mfaStatus: mapMfaStatus(isMfaSetup, isMFAEnrolled, identityProvider),
            isMfaEnforced: isMfaSetup ? enforceMFAEnrollment : null,

            id,
            username,
            provider: userProviderMapping[identityProvider],
            authenticationMethods: grantTypes.map(mapGrantTypeToAuthenticationMethod),

            /**
             * @todo actually map this properly
             * @see https://cybusio.atlassian.net/browse/CYB-3609
             */
            lastSeen: tokens.reduce<Date | null>((r, t) => {
                const created = new Date(t.created_at)
                return r === null || r.getTime() < created.getTime() ? created : r
            }, null),
            mqttPublishPrefix,
            ...this.userMutabilityMapper.mapMutability(identityProvider, username),
        }

        roles.forEach(({ isShared, name, permissions }) => {
            if (isShared) {
                // This is actually a role, so add it
                user.roles.push(name)
            }

            permissions.forEach((rawPermission) => {
                const userPermission = UserManagementMapper.mapCybusPersistedPermission(rawPermission)

                if (!isShared) {
                    user.permissions.push(userPermission)
                }

                user.allPermissions.push(userPermission)
            })
        })

        return user
    }

    private mapRole ({ id, name, permissions, users, ldapgroupdn, msEntraIdGroupIds }: PaginatedRole, isEntraEnabled: boolean): CybusRole {
        const { canDelete, canEditOthers, canEditName } = this.roleMutabilityMapper.mapMutability('local', name)

        return {
            id,
            name,
            permissions: Array.from(
                (permissions as ArrayType<PaginatedRole['permissions']>[])
                    .reduce(
                        (m, p) => (p.context && p.resource && p.id && p.operation ? m.set(p.id, UserManagementMapper.mapCybusPersistedPermission(p)) : m),
                        new Map<string, CybusPersistedPermission>()
                    )
                    .values()
            ),
            users: users.flatMap((u) => (u.username ? [u.username] : [])),

            ldapGroupDn: ldapgroupdn,
            canDelete,
            canEditOthers,
            canEditName,
            msEntraIdGroupIds: isEntraEnabled ? (msEntraIdGroupIds ? msEntraIdGroupIds.split(',') : []) : null,
        }
    }

    mapUsers (users: User[], isMfaSetup: boolean): CybusUser[] {
        return users.map((user) => this.mapUser(user, isMfaSetup))
    }

    mapUsersPage ({ users, pagination }: PaginatedUsers, isMfaSetup: boolean): PaginatedData<CybusUser> {
        return UserManagementMapper.mapPage(this.mapUsers(users, isMfaSetup), pagination)
    }

    mapRoles (roles: PaginatedRole[], isEntraEnabled: boolean): CybusRole[] {
        return roles.map((role) => this.mapRole(role, isEntraEnabled))
    }

    mapRolesPage ({ roles, pagination }: PaginatedRoles, isEntraEnabled: boolean): PaginatedData<CybusRole> {
        return UserManagementMapper.mapPage(this.mapRoles(roles, isEntraEnabled), pagination)
    }

    mapPermissionsPage ({ permissions, pagination }: PaginatedPermissionsResponse): PaginatedData<CybusPermission> {
        return UserManagementMapper.mapPage(
            permissions.map((permission) => UserManagementMapper.mapPermission(permission)),
            pagination
        )
    }
}

import React, { type FC, useMemo } from 'react'
import { Link, type LinkProps } from '@mui/material'

import type { DocumentationType } from '../../application'

import { useAppUsecase } from './State'

type DocumentationLinkProps = Readonly<{ type: DocumentationType }> & Pick<LinkProps, 'sx' | 'variant' | 'color' | 'title' | 'className'>

export const DocumentationLink: FC<DocumentationLinkProps> = ({ type, ...props }) => {
    const usecase = useAppUsecase('documentationUsecase')
    const href = useMemo(() => usecase.createURL(type).toString(), [usecase, type])

    return <Link data-testid={`documentation-link-${type.toLowerCase().replaceAll(/_+/g, '-')}`} target="_blank" {...props} href={href} />
}

import { type ArrayType, isArray } from '../../../utils'
import { ConnectwareError, type ConnectwareErrorExtras, ConnectwareErrorType } from '../../Error'
import {
    type CommissioningFileField,
    CommissioningFileFieldType,
    type CommissioningFileValidatedValue,
    type CommissioningFileValidation,
    type CommissioningFileValue,
    CommissioningFileValueValidationError,
    type StringCommissioningFileField,
    type StringIndexCommissioningFileField,
} from '.'

const isString = (v: unknown): v is string => typeof v === 'string'

const arrayLessValidatorsByType = {
    [CommissioningFileFieldType.BOOLEAN]: (v: unknown): v is boolean => typeof v === 'boolean',
    [CommissioningFileFieldType.INTEGER]: (v: unknown): v is number => Number.isInteger(v),
    [CommissioningFileFieldType.NUMBER]: (v: unknown): v is number => typeof v === 'number' && !isNaN(v),
    [CommissioningFileFieldType.STRING]: isString,
    [CommissioningFileFieldType.STRING_INDEX]: isString,
    [CommissioningFileFieldType.OPTIONS]: isString,
} as const

const createArrayValidator =
    <T>(validate: (v: unknown) => v is T): ((v: unknown) => v is T[]) =>
    (v): v is T[] =>
        isArray(v) && v.every(validate)

const typeValidatorsByType = {
    ...arrayLessValidatorsByType,
    [CommissioningFileFieldType.BOOLEAN_ARRAY]: createArrayValidator(arrayLessValidatorsByType[CommissioningFileFieldType.BOOLEAN]),
    [CommissioningFileFieldType.INTEGER_ARRAY]: createArrayValidator(arrayLessValidatorsByType[CommissioningFileFieldType.INTEGER]),
    [CommissioningFileFieldType.NUMBER_ARRAY]: createArrayValidator(arrayLessValidatorsByType[CommissioningFileFieldType.NUMBER]),
    [CommissioningFileFieldType.STRING_ARRAY]: createArrayValidator(arrayLessValidatorsByType[CommissioningFileFieldType.STRING]),
} as const

export const doCommissioningFileFieldAndValueMatch = <F extends CommissioningFileField>(
    field: F,
    value: CommissioningFileValue<CommissioningFileField>
): value is CommissioningFileValue<F> | null => value === null || typeValidatorsByType[field.type](value)

export const assertCommissioningFileValidatedValueType = (
    field: CommissioningFileField,
    value: CommissioningFileValue<CommissioningFileField>
): void | never => {
    if (!doCommissioningFileFieldAndValueMatch(field, value)) {
        throw new ConnectwareError(ConnectwareErrorType.GENERAL_BUSINESS_RULE_INFRACTION, 'Type of given value is invalid', { field, value })
    }
}

const validateRange = <T>(min: number | null, value: number, max: number | null, ...[minError, maxError]: [min: T, max: T]): T | null => {
    if (min !== null && value < min) {
        return minError
    }

    if (max !== null && value > max) {
        return maxError
    }

    return null
}

const validateStringEmptyness = <F extends StringCommissioningFileField | StringIndexCommissioningFileField>(
    field: F,
    value: CommissioningFileValue<F>
): ConnectwareErrorExtras<CommissioningFileValueValidationError>['reason'] | null => {
    if (!value && !field.optional) {
        return 'REQUIRED'
    }

    return null
}

const validators: ((
    field: CommissioningFileField,
    value: CommissioningFileValue<CommissioningFileField>
) => ConnectwareErrorExtras<CommissioningFileValueValidationError>['reason'] | true | null)[] = [
    ({ optional }, value) => {
        if (value === null && optional) {
            /** There is no value but since its optional, all good */
            return true
        }

        return null
    },
    ({ optional }, value) => {
        if (value === null && !optional) {
            /** No value and required -> Error */
            return 'REQUIRED'
        }

        return null
    },
    ({ allowableValues }, value) => {
        if (allowableValues) {
            if ((allowableValues as (typeof value)[]).includes(value)) {
                /** Has allowed values and in allowed values -> No error */
                return true
            }

            /** Has allowed values and not in allowed values -> Error */
            return 'NOT_IN_ALLOWABLE_VALUES'
        }

        return null
    },
    /** Validate patterns */
    (field, value) => {
        if ('pattern' in field && typeValidatorsByType[field.type](value) && field.pattern !== null && !value.match(field.pattern)) {
            return 'INVALID_PATTERN'
        }

        return null
    },
    /** Validate string indexes */
    (field, value) => {
        if (field.type === CommissioningFileFieldType.STRING_INDEX && typeValidatorsByType[field.type](value)) {
            return validateStringEmptyness(field, value) ?? true
        }

        return null
    },
    /** Validate strings */
    (field, value) => {
        if (field.type === CommissioningFileFieldType.STRING && typeValidatorsByType[field.type](value)) {
            return (
                validateStringEmptyness(field, value) ??
                validateRange<'MIN_LENGTH' | 'MAX_LENGTH'>(field.minLength, value.length, field.maxLength, 'MIN_LENGTH', 'MAX_LENGTH') ??
                true
            )
        }

        return null
    },
    /** Validate numbers and integers */
    (field, value) => {
        if (
            (field.type === CommissioningFileFieldType.NUMBER || field.type === CommissioningFileFieldType.INTEGER) &&
            typeValidatorsByType[field.type](value)
        ) {
            return validateRange<'MIN' | 'MAX'>(field.min, value, field.max, 'MIN', 'MAX') ?? true
        }

        return null
    },
    /** Validate arrays */
    (field, value) => {
        if (
            (field.type === CommissioningFileFieldType.BOOLEAN_ARRAY ||
                field.type === CommissioningFileFieldType.INTEGER_ARRAY ||
                field.type === CommissioningFileFieldType.NUMBER_ARRAY ||
                field.type === CommissioningFileFieldType.STRING_ARRAY) &&
            typeValidatorsByType[field.type](value) &&
            field.uniqueItems &&
            value.length !== new Set<ArrayType<typeof value>>(value).size
        ) {
            return 'ITEMS_NOT_UNIQUE'
        }

        return null
    },
    /** Validate rest */
    (field, value) => {
        return doCommissioningFileFieldAndValueMatch(field, value) ? true : 'INVALID_TYPE'
    },
]

const mapEditableCommissioningFileValueValidation = <F extends CommissioningFileField>(
    field: F,
    value: CommissioningFileValue<F>
): CommissioningFileValidation => {
    let output: true | ConnectwareErrorExtras<CommissioningFileValueValidationError>['reason'] = true

    for (const validator of validators) {
        const validatorOutput = validator(field, value)

        if (validatorOutput !== null) {
            /** Finally there is a result, so stop */
            output = validatorOutput
            break
        }
    }

    /**
     * Warning
     *
     * This flow yielding true relies on at least one validator
     * always yielding an error or a validity response
     */
    return output === true ? output : new CommissioningFileValueValidationError(output, field)
}

export const mapCommissioningFileValidatedValue = <F extends CommissioningFileField>(
    field: F,
    value: CommissioningFileValue<F>
): CommissioningFileValidatedValue<F> => ({ value, validation: mapEditableCommissioningFileValueValidation(field, value) })

import { isArray, objectKeys, type ReadonlyRecord } from '../../utils'
import type { TopicPathConfiguration } from '../../domain'
import type { PersistedTopics, TopicsPersistenceService } from '../../application'

import { BaseWebStorage } from './Base'

export type CompressedPersistedTopics = Readonly<{ allTopics: string[], custom: number[], selected: number[] }>

type TypeMap = { string: string, number: number }

const isArrayOfType = <T extends keyof TypeMap>(type: T, arr: unknown): arr is Array<TypeMap[T]> =>
    // eslint-disable-next-line valid-typeof
    !isArray(arr) || arr.every((value) => typeof value === type)

export class WebStorageTopicsPersistenceService
    extends BaseWebStorage<PersistedTopics, PersistedTopics, CompressedPersistedTopics>
    implements TopicsPersistenceService {
    protected readonly fallbackValue = { custom: [], selected: [] }

    protected readonly key = 'explorerTopics'

    protected override readonly ignoreVersionMismatch = true

    // eslint-disable-next-line class-methods-use-this
    protected toSerializable ({ custom, selected }: PersistedTopics): CompressedPersistedTopics {
        const customTopics = custom.map(({ rawPath }) => rawPath)
        const selectedTopics = selected.map(({ rawPath }) => rawPath)

        const allTopics = Array.from(new Set([...customTopics, ...selectedTopics]))

        const compressed: CompressedPersistedTopics = {
            allTopics,
            custom: customTopics.map((t) => allTopics.indexOf(t)),
            selected: selectedTopics.map((t) => allTopics.indexOf(t)),
        }

        return compressed
    }

    // eslint-disable-next-line class-methods-use-this
    protected isValidSerializable (unknownValue: unknown): unknownValue is CompressedPersistedTopics {
        if (unknownValue && typeof unknownValue === 'object' && objectKeys(unknownValue).length !== 3) {
            return false
        }

        const { allTopics, custom, selected } = unknownValue as ReadonlyRecord<string, unknown>

        if (!isArrayOfType('string', allTopics) || !isArrayOfType('number', custom) || !isArrayOfType('number', selected)) {
            return false
        }

        if ((custom.length && custom.every((i) => i >= allTopics.length)) || (selected.length && selected.every((i) => i >= allTopics.length))) {
            return false
        }

        return true
    }

    // eslint-disable-next-line class-methods-use-this
    protected fromSerializable (compressed: CompressedPersistedTopics): PersistedTopics {
        const { allTopics, custom, selected } = compressed

        const topics = allTopics.map<TopicPathConfiguration>((rawPath) => ({ rawPath, path: rawPath.split('/') }))

        return {
            custom: custom.reduce<TopicPathConfiguration[]>((r, t) => [...r, topics[t] as TopicPathConfiguration], []),
            selected: selected.reduce<TopicPathConfiguration[]>((r, t) => [...r, topics[t] as TopicPathConfiguration], []),
        }
    }

    retrieveTopics (): PersistedTopics {
        return this.retrieveDeserialize()
    }

    persistTopics (topics: PersistedTopics): void {
        this.persistSerialize(topics)
    }
}

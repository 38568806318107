import { ConnectwareError, ConnectwareErrorType } from '../Error'

export class SessionFromTokenNotFoundError extends ConnectwareError {
    static override is (e: unknown): e is SessionFromTokenNotFoundError {
        return e instanceof SessionFromTokenNotFoundError
    }

    constructor () {
        super(ConnectwareErrorType.AUTHENTICATION, 'Session not found')
    }
}

export class TokenExpiredError extends ConnectwareError<ConnectwareErrorType.AUTHENTICATION> {
    static override is (e: unknown): e is TokenExpiredError {
        return e instanceof TokenExpiredError
    }

    constructor () {
        super(ConnectwareErrorType.AUTHENTICATION, 'Token expired')
    }
}

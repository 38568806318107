import type { JSONSchema7 } from 'json-schema'
import { validate } from 'jsonschema'

import { Cache, delay, encodeToBase64, type EventListener, executeOnce, isArray, isArrayNotEmpty, type NullableValues, objectEntries } from '../../../utils'
import {
    Capability,
    type CommissioningFileFields,
    type CommissioningFileOutput,
    CommissioningFileParsingError,
    type CommissioningFileValues,
    ConnectwareError,
    ConnectwareErrorType,
    ConnectwareServiceCreationError,
    type CybusDetailedService,
    type CybusServiceForm,
    type CybusServiceParameters,
    type CybusServiceSchema,
    Translation,
} from '../../../domain'
import type { ConfigurationService, ConnectwareServicesService, ServiceCreationOrUpdateRequest, TranslationService } from '../../../application'

import { readFileAsText } from '../../file'
import type { AllSchemasResponse, BackendJsonRequestContent, ServicePostRequest, UpdateServiceRequest, ValidationError } from '../../Connectware'
import { type ConnectwareHTTPServiceOptions, FetchConnectwareHTTPService, type HttpResponse } from '../Base'
import { isMinimalExpectedServiceData, type MinimalExpectedServiceData } from './types'
import {
    extractAlreadyExists,
    extractCreationError,
    extractErrors,
    fromCommissioningJsonToYaml,
    fromCommissioningYamlToJson,
    mapCommissioningFileFields,
    mapCommissioningFileValuesToJson,
    mapJsonToCommissioningFileValues,
    mapParserErrorsInformation,
    type ParserError,
} from './mappers'

const mapSchemaValues = (schema: JSONSchema7, currentServiceId: CybusServiceForm['id']): [CybusServiceForm['id'], NullableValues<CybusServiceParameters>] => {
    let id: CybusServiceForm['id'] = currentServiceId
    let parameters: NullableValues<CybusServiceParameters> = {}

    if (schema.properties) {
        objectEntries(schema.properties).forEach(([name, prop]) => {
            /**
             * If possible, retrieve the default value, otherwise, just declare the value is there
             */
            let defaultValue = typeof prop === 'boolean' || !('default' in prop) || prop.default === undefined ? undefined : prop.default

            if (name === 'id') {
                /** Use id if its valid and is not already set */
                id = id || typeof defaultValue !== 'string' ? id : defaultValue
                return
            }

            if (
                typeof defaultValue !== 'string' &&
                typeof defaultValue !== 'number' &&
                typeof defaultValue !== 'boolean' &&
                !(isArray(defaultValue) && defaultValue.every((v): v is string | number => typeof v === 'string' || typeof v === 'number'))
            ) {
                defaultValue = null
            }

            parameters = { ...parameters, [name]: defaultValue }
        })
    }

    return [id, parameters]
}

const validationErrorHandler = async (response: HttpResponse): Promise<ConnectwareError> => {
    const data = await response.getJson<[ValidationError]>()
    return new ConnectwareError(ConnectwareErrorType.SERVER_ERROR, data[0].message)
}

export class ConnectwareHTTPServicesService extends FetchConnectwareHTTPService implements ConnectwareServicesService {
    private readonly schemaCache = new Cache<Promise<JSONSchema7>>()

    private readonly yamlCache = new Cache<Promise<MinimalExpectedServiceData>>()

    private readonly validationCache = new Cache<Promise<void>>()

    protected updateBreathingRoom = 5_000

    readonly generateCommissioningFileFields = executeOnce(
        async (): Promise<CommissioningFileFields> =>
            this.request({
                capability: Capability.SERVICE_TEMPLATE_EDIT,
                method: 'GET',
                path: '/api/resources/schemas',
                authenticate: true,
                handlers: {
                    200: (response) => response.getJson<AllSchemasResponse>().then(mapCommissioningFileFields),
                },
            })
    )

    constructor (
        options: ConnectwareHTTPServiceOptions,
        private readonly configurationService: ConfigurationService,
        private readonly translationService: TranslationService,
        private readonly changeDoneListeners: Pick<EventListener<void>, 'trigger'>
    ) {
        super(options)
    }

    private async fetchYamlValidation (fileContent: string): Promise<void> {
        await this.validationCache.get(
            () =>
                this.request<'/api/validate/service', BackendJsonRequestContent<'/api/validate/service', 'post'>, void>({
                    capability: Capability.SERVICE_TEMPLATE_EDIT,
                    method: 'POST',
                    path: '/api/validate/service',
                    authenticate: true,
                    body: { commissioningFile: encodeToBase64(fileContent) },
                    handlers: {
                        200: () => Promise.resolve(),
                        422: async (response) => {
                            const { errors } = await response.getJson<Readonly<{ errors: ParserError[] }>>()
                            return new CommissioningFileParsingError(mapParserErrorsInformation(errors))
                        },
                    },
                }),
            fileContent
        )
    }

    private async fetchValidatedYamlJson (fileContent: string): Promise<MinimalExpectedServiceData> {
        return this.yamlCache.get(async () => {
            let yamlJson: unknown

            try {
                yamlJson = fromCommissioningYamlToJson(fileContent)
            } catch (e: unknown) {
                throw new ConnectwareError(
                    e as ConnectwareError,
                    this.translationService.translate(Translation.EDIT_SERVICE_UNPARSEABLE_COMMISSIONING_FILE, {
                        types: this.translationService.list(this.configurationService.getSupportedServiceCommissioningFileTypes(), 'options'),
                    })
                )
            }

            if (!isMinimalExpectedServiceData(yamlJson)) {
                throw new ConnectwareError(ConnectwareErrorType.MAPPING_ERROR, 'Provided commissioning file has invalid metadata', { yamlJson })
            }

            return yamlJson
        }, fileContent)
    }

    private async fetchEditableCommissioningFileValues (fileContent: string): Promise<CommissioningFileValues> {
        const [yamlJson, fields] = await Promise.all([this.fetchValidatedYamlJson(fileContent), this.generateCommissioningFileFields()])
        return mapJsonToCommissioningFileValues(yamlJson, fields)
    }

    async parseCommissioningFile (
        content: string,
        currentServiceId: CybusServiceForm['id']
    ): Promise<[CybusServiceSchema, CybusServiceForm['id'], NullableValues<CybusServiceParameters>]> {
        let schema = await this.schemaCache.get(
            () =>
                this.request<'/api/services/parametersSchema', BackendJsonRequestContent<'/api/services/parametersSchema', 'post'>, JSONSchema7>({
                    capability: Capability.SERVICES_CREATE_OR_UPDATE,
                    method: 'POST',
                    path: '/api/services/parametersSchema',
                    authenticate: true,
                    body: { commissioningFile: encodeToBase64(content) },
                    handlers: {
                        201: async (response) => {
                            const data = await response.getJson<{ schemas: JSONSchema7 }>()
                            return data.schemas
                        },
                        400: validationErrorHandler,
                        406: async (response) => {
                            const { message } = await response.getJson<{ message: string }>()
                            const errors = extractErrors({ message })
                            return errors
                                ? new CommissioningFileParsingError(mapParserErrorsInformation(errors))
                                : new ConnectwareError(ConnectwareErrorType.SERVER_ERROR, message)
                        },
                    },
                }),
            content
        )

        /**
         * The schema is a very complex json, see `json-schema`
         * But for what is relevant for the admin UI, only `ServiceSchema` is enough
         */
        const [id, values] = mapSchemaValues(schema, currentServiceId)

        /**
         * If there is an id
         * It means the service already exists
         * And therefore, the id cannot be set again
         * Then delete it from the outputted schema
         * As it is not necessary
         */
        if (currentServiceId) {
            if (schema.properties) {
                schema = {
                    ...schema,
                    properties: objectEntries(schema.properties)
                        .filter(([f]) => f !== 'id')
                        .reduce((r, [f, v]) => ({ ...r, [f]: v }), {}),
                }
            }

            if (schema.required) {
                schema = { ...schema, required: schema.required.filter((r) => r !== 'id') }
            }
        }

        return [schema as CybusServiceSchema, id, values]
    }

    async createOrUpdate ({ commissioningFile, id, parameters, catalog, isCreation }: ServiceCreationOrUpdateRequest): Promise<void> {
        if (!id) {
            throw new ConnectwareServiceCreationError(Translation.INVALID_SERVICE_ID)
        }

        const body: ServicePostRequest | UpdateServiceRequest = {
            commissioningFile: encodeToBase64(commissioningFile),
            parameters,
            marketplace: catalog ? { ...catalog, updatedAt: catalog.updatedAt.toISOString() } : undefined,
        }

        if (isCreation) {
            await this.request({
                capability: Capability.SERVICES_CREATE_OR_UPDATE,
                method: 'POST',
                path: '/api/services',
                authenticate: true,
                body: { id, ...body },
                handlers: {
                    201: () => Promise.resolve(),
                    400: validationErrorHandler,
                    406: async (response) => {
                        const data = await response.getJson<ValidationError>()
                        if (extractAlreadyExists(data)) {
                            return new ConnectwareServiceCreationError(Translation.SERVICE_ID_ALREADY_USED)
                        }
                        const creationErrorMessage = extractCreationError(data)
                        if (creationErrorMessage !== null) {
                            return new ConnectwareError(ConnectwareErrorType.SERVER_ERROR, creationErrorMessage)
                        }
                        return new ConnectwareError(ConnectwareErrorType.SERVER_ERROR, data.message)
                    },
                },
            })
        } else {
            await this.request({
                capability: Capability.SERVICES_CREATE_OR_UPDATE,
                method: 'PUT',
                path: '/api/services/+',
                pathParams: [id],
                authenticate: true,
                body,
                handlers: {
                    202: () => Promise.resolve(),
                    400: validationErrorHandler,
                    404: async (response) => {
                        const data = await response.getJson<{ message: string }>()
                        return new ConnectwareError(ConnectwareErrorType.NOT_FOUND, data.message)
                    },
                    406: validationErrorHandler,
                },
            })
        }

        if (!isCreation) {
            /**
             * Due to updating being done asynchronously
             * This here makes sure the UI waits a bit before fetching informaiton
             */
            await delay(this.updateBreathingRoom)
        }

        this.changeDoneListeners.trigger()
    }

    validate (schema: CybusServiceSchema, id: CybusServiceForm['id'], parameters: CybusServiceParameters): boolean {
        const result = validate(id !== null ? { ...parameters, id } : parameters, schema)
        return !isArrayNotEmpty(result.errors)
    }

    createFile ({ id, commissioningFile, updatedAt }: Pick<CybusDetailedService, 'id' | 'commissioningFile' | 'updatedAt'>): File {
        const fileName = `${id}.${this.configurationService.getServiceCommissioningFileType()}`
        const type = this.configurationService.getServiceCommissioningFileEncodingType()
        return new File([commissioningFile], fileName, { type, lastModified: updatedAt ? updatedAt.getTime() : Date.now() })
    }

    async generateCommissioningFileValues (file: File): Promise<CommissioningFileValues> {
        return this.fetchEditableCommissioningFileValues(await readFileAsText(file))
    }

    async generateCommissioningFileOutput (file: File, updated: CommissioningFileValues): Promise<CommissioningFileOutput> {
        const fileContent = await readFileAsText(file)

        /** Get the yaml for the original file, this should not throw an error as the file here should always be valid */
        const [yamlJson, fields] = await Promise.all([this.fetchValidatedYamlJson(fileContent), this.generateCommissioningFileFields()])

        /** Remap content */
        const newFileContent = fromCommissioningJsonToYaml(mapCommissioningFileValuesToJson(yamlJson, updated, fields))

        /** Validate new content */
        const newFileValidation = await this.fetchYamlValidation(newFileContent).catch((e: ConnectwareError) => e)

        /** Generate response */
        return [new File([newFileContent], file.name, { type: file.type, lastModified: Date.now() }), newFileValidation ?? null]
    }
}

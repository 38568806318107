import React, { type FC } from 'react'
import { SvgIcon, type SvgIconProps } from '@mui/material'

export const MSIcon: FC<SvgIconProps> = (props) => (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" width={20} height={21} fill="none" {...props}>
        <g clipPath="url(#a)">
            <path fill="#F35325" d="M.87 1.44h8.695v8.696H.87V1.44Z" />
            <path fill="#81BC06" d="M10.435 1.44h8.695v8.696h-8.695V1.44Z" />
            <path fill="#05A6F0" d="M.87 11.005h8.695V19.7H.87v-8.696Z" />
            <path fill="#FFBA08" d="M10.435 11.005h8.695V19.7h-8.695v-8.696Z" />
        </g>
        <defs>
            <clipPath id="a">
                <path fill="#fff" d="M0 .57h20v20H0z" />
            </clipPath>
        </defs>
    </SvgIcon>
)

import type { ObjectOrArray, ReadonlyRecord } from '../../../utils'

export type CybusPubSub = (Readonly<{ endpoint: string, label?: string }> | Readonly<{ topic: string }>) &
    Readonly<{ connection?: string, qos?: number, retain?: boolean }>

type Rule = ReadonlyRecord<string, unknown>

export type MappingExecutionError = Readonly<{ error: [string | null, string], rule: Rule, ruleName: string, message: ReadonlyRecord<string, unknown> }>

type Mapping = Readonly<{
    subscribe: ObjectOrArray<CybusPubSub>
    publish: CybusPubSub
    /**
     * @see https://docs.cybus.io/1-7-3/documentation/services/rule-engine/data-processing-rules
     */
    rules?: Rule[]
    executionError?: MappingExecutionError
}>

/**
 * @todo generate this type from BE images
 * @see https://cybusio.atlassian.net/browse/CC-2690
 * @see https://bitbucket.org/cybusio/cybus/src/e9747b43bf8d5e80b3a7976129d30a8a60e7cd8d/protocol-mapper/src/Mapping.js?at=main#lines-120
 * @see https://bitbucket.org/cybusio/cybus/src/db84a1b7ca09d3d37998da0664249760ffefe8b6/protocol-mapper/src/swagger/swagger.yaml?at=main#lines-549
 */
export type MappingProxyParams = Readonly<{
    id: string
    mappings: Mapping[]
    currentState: string
    targetState: string
    agentName: string
    inputBuffering?: unknown
}>

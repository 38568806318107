import type { BackendDefinition } from '../../../Connectware'

type MutabilityRight = Readonly<{ canEditName: boolean, canEditOthers: boolean, canDelete: boolean, canUpdateMFA: boolean }>

export class MutabilityMapper {
    constructor (private readonly protectedEntities: Set<string>) {}

    mapMutability (identityProvider: BackendDefinition<'auth-server', 'IdentityProvider'>, entityName: string): MutabilityRight {
        const isProtected = this.protectedEntities.has(entityName)

        const isLocal = identityProvider === 'local'
        const isMSEntraId = identityProvider === 'msentraid'

        return {
            canDelete: !isProtected,
            canEditOthers: isLocal,
            canEditName: isLocal && !isProtected,
            canUpdateMFA: !isMSEntraId,
        }
    }
}

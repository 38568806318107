import {
    type AuthenticationInformation,
    ConnectwareError,
    type ConnectwareErrorExtras,
    ConnectwareErrorType,
    SSOAuthenticationError,
    SSOAuthenticationMethod,
} from '../../domain'
import type { BrowserService, LoggerService } from '../../application'

export class HTML5BrowserService implements BrowserService {
    protected targetDocument: Pick<Document, 'cookie'> = document

    protected targetWindow: Pick<Window, 'open'> = window

    protected targetLocation: Pick<Location, 'reload' | 'search' | 'replace' | 'href'> = window.location

    protected targetHistory: Pick<History, 'pushState'> = window.history

    protected targetClipboard: Pick<Clipboard, 'writeText'> = navigator.clipboard

    constructor (
        private readonly logger: LoggerService,
        private readonly origin: URL['origin'],
        private readonly hostsWhitelist: Set<URL['host']>,
        private readonly cookiePaths: Set<string>
    ) {}

    private setAuthCookie (token: string | null, expiresAt: Date | null, path: string): void {
        const values: ([key: string, value: string] | [key: string])[] = [
            ['X-Auth-Token', encodeURIComponent(token ?? '')],
            ['path', path],
            ['SameSite', 'strict'],
            ['Secure'],
            ['Host', this.origin],
        ]

        if (expiresAt) {
            values.push(['expires', expiresAt.toUTCString()])
        }

        this.targetDocument.cookie = values.map((pair) => pair.join('=')).join('; ')
    }

    private getUrlParameters (): URLSearchParams {
        return new URLSearchParams(this.targetLocation.search)
    }

    private getUrlParameter (name: string): string | null {
        return this.getUrlParameters().get(name)
    }

    private openWithoutValidation (url: URL, name: string | null): void {
        const ref = url.toString()
        this.targetWindow.open(ref, name || ref)
    }

    /**
     * Checks if the given url is safe to be loaded onto the browser
     *
     * @throws {ConnectwareError}
     */
    private validateUrl (targetUrl: URL): void {
        const { href: redirectUrl, protocol: targetProtocol, host: targetHost } = targetUrl
        const { href: currentUrl, protocol: currentProtocol } = new URL(this.targetLocation.href)

        if (currentProtocol !== targetProtocol) {
            /**
             * If the browser is running in https then the new redirection must also be to https
             * Otherwise it will fail
             *
             * It also prevents javascript execution
             * @see https://developer.mozilla.org/en-US/docs/Web/URI/Schemes/javascript
             */
            throw new ConnectwareError(ConnectwareErrorType.GENERAL_BUSINESS_RULE_INFRACTION, 'Only redirection to the same protocol is allowed', {
                currentUrl,
                redirectUrl,
            })
        }

        if (!this.hostsWhitelist.has(targetHost)) {
            /**
             * Only links to white listed hosts are allowed
             */
            throw new ConnectwareError(ConnectwareErrorType.GENERAL_BUSINESS_RULE_INFRACTION, 'Redirection to given host not allowed', {
                targetHost,
                hostsWhitelist: Array.from(this.hostsWhitelist),
            })
        }
    }

    isInspecting (): boolean {
        return this.getUrlParameter('inspection') !== null
    }

    redirect (redirectUrl: URL): void {
        this.validateUrl(redirectUrl)
        this.targetLocation.replace(redirectUrl.toString())
    }

    getRedirectionURL (): URL | null {
        const redirectParameter = this.getUrlParameter('redirect')
        return redirectParameter ? new URL(redirectParameter, this.origin) : null
    }

    extractURLAuthentication (): string | ConnectwareError | null {
        const parameters = this.getUrlParameters()

        const apiToken = parameters.get('apiToken')
        if (apiToken) {
            /**
             * Authentication is present, so just yield it
             */
            return apiToken
        }

        const { ssoError, ...otherParameters } = Object.fromEntries(parameters)

        if (ssoError === String(true)) {
            const { ssoCallbackRedirect, ssoLoginRedirect, ...otherEntraErrorParameters } = otherParameters

            let reason: ConnectwareErrorExtras<SSOAuthenticationError>['reason'] = 'unknown'

            if (ssoCallbackRedirect === String(true) && otherEntraErrorParameters.error === 'invalid_client') {
                reason = 'invalid_client'
            }

            if (ssoCallbackRedirect === String(true) && otherParameters.statusCode === '409') {
                /** A 409 status code indicates that the user already exists in CW with a different provider */
                reason = 'user_already_exists'
            }

            if (ssoLoginRedirect === String(true)) {
                reason = 'failed_login_redirect'
            }

            return new SSOAuthenticationError({ ...otherEntraErrorParameters, method: SSOAuthenticationMethod.MICROSOFT_ENTRA_ID, reason })
        }

        return null
    }

    removeURLAuthentication (): void {
        const url = new URL(this.targetLocation.href)
        url.search = ''
        this.validateUrl(url)
        this.targetHistory.pushState({}, '', url)
    }

    authenticate (info: AuthenticationInformation | null): void {
        const token = info?.token ?? null
        const expiresAt = info?.expiresAt ?? null

        for (const path of this.cookiePaths) {
            this.setAuthCookie(token, expiresAt, path)
        }
    }

    reload (): void {
        this.targetLocation.reload()
    }

    open (url: URL, name: string | null): void {
        this.validateUrl(url)
        this.openWithoutValidation(url, name)
    }

    sendEmail (target: string, subject: string | null): void {
        const url = new URL(`mailto:${target}`)

        if (subject) {
            url.searchParams.append('subject', subject)
        }

        this.openWithoutValidation(url, null)
    }

    copy (value: unknown): string | null {
        let data: string | null = null

        switch (typeof value) {
            case 'string':
                data = value
                break
            default:
                /** Just quietly log the issue */
                this.logger.error(new ConnectwareError(ConnectwareErrorType.UNEXPECTED, 'Only string values are supported to be copied', { value }))
        }

        if (data === null) {
            return null
        }

        this.targetClipboard.writeText(data).catch(({ message, code }: DOMException) => {
            /** Just quietly log the issue */
            this.logger.error(new ConnectwareError(ConnectwareErrorType.UNEXPECTED, 'Could not write to clipboard', { message, code, value }))
        })

        return data
    }
}

import type { ValuesType } from 'utility-types'
import { useCallback } from 'react'
import { DataUsage, Tag } from '@mui/icons-material'

import { CybusPermissionContext, Translation } from '../../../../domain'
import { useTranslator } from '../../Internationalization'

const contextValues = {
    [CybusPermissionContext.HTTP]: { name: 'Http', label: Translation.ENDPOINT, icon: DataUsage },
    [CybusPermissionContext.MQTT]: { name: 'Mqtt', label: Translation.TOPIC, icon: Tag },
} as const

type ContextValue = ValuesType<typeof contextValues>

const getContextValue = <Prop extends keyof ContextValue>(prop: Prop, context: CybusPermissionContext): ContextValue[Prop] => contextValues[context][prop]

export const useContextName = (): ((context: CybusPermissionContext) => ContextValue['name']) => (context) => getContextValue('name', context)

export const useContextIcon = (context: CybusPermissionContext): ContextValue['icon'] => getContextValue('icon', context)

export const useContextLabel = (): ((context: CybusPermissionContext, count?: number) => string) => {
    const translator = useTranslator()
    return useCallback((context, count = 1) => translator.formatTranslation(getContextValue('label', context), { count }), [translator])
}

import { areDetailedCybusVolumeEquals, Capability, type CybusDetailedVolume } from '../../../../../../domain'

import { type DockerDetailedVolumeResponse, mapDetailedVolume } from '../../../../../Connectware'
import { BaseSubscriptionStrategy } from '../Base'

export class DetailedVolumeStrategy extends BaseSubscriptionStrategy<'volume'> {
    readonly requiredPermissions = [Capability.VOLUME_READ]

    protected override areEquals = areDetailedCybusVolumeEquals

    override retrieveOne (id: string): Promise<CybusDetailedVolume> {
        return this.retrieve({
            capability: Capability.VOLUME_READ,
            method: 'GET',
            path: '/api/volumes/+/inspect',
            pathParams: [id],
            mapper: (data: DockerDetailedVolumeResponse) => mapDetailedVolume(data),
            handleNotFound: true,
        })
    }
}

import React, { type FC } from 'react'
import { Autocomplete, Chip, type ChipProps, TextField } from '@mui/material'

import { isArray, isArrayNotEmpty } from '../../../../utils'
import { type ConnectwareError, type CybusRole, Translation } from '../../../../domain'

import { NoOptions, Option } from '../Autocomplete'

type Props = Readonly<{
    disabled: boolean
    roleInput: string | null
    roles: CybusRole[]
    required?: CybusRole['name'][]
    allRoles: CybusRole[] | ConnectwareError | null
    usecase: { searchRoles(search: string | null): void, selectRoles(roles: CybusRole[]): void }
}>

export const RolesDropdown: FC<Props> = ({ disabled, roleInput, roles, required = [], allRoles, usecase }) => {
    const noData = !roleInput && isArray(allRoles) && !isArrayNotEmpty(allRoles)

    return (
        <Autocomplete
            data-testid="roles"
            filterSelectedOptions
            multiple
            size="medium"
            options={isArray(allRoles) ? allRoles : roles}
            getOptionLabel={(option) => option.name}
            renderOption={(props, option) => <Option {...props} value={option} />}
            renderTags={(roles, getTagProps) =>
                roles.map((option, index) => {
                    const props: ChipProps = required.includes(option.name) ? { onDelete: undefined } : {}
                    return <Chip data-testid="selected-role" size="small" label={option.name} {...getTagProps({ index })} {...props} key={index} />
                })
            }
            renderInput={(params) => (
                <TextField {...params} variant="standard" size="medium" value={roleInput} onChange={(e) => usecase.searchRoles(e.currentTarget.value)} />
            )}
            isOptionEqualToValue={(a, b) => a.id === b.id}
            noOptionsText={<NoOptions data={allRoles} notFound={Translation.NO_ROLES_FOUND} />}
            value={roles}
            disabled={disabled || noData}
            onOpen={() => usecase.searchRoles('')}
            onClose={() => usecase.searchRoles(null)}
            onChange={(_, roles) => usecase.selectRoles(roles)}
            // If only the required items are selected, than they auto clearing is not available
            disableClearable={required.length >= roles.length}
        />
    )
}

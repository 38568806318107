import { isArray } from '../../../../utils'
import {
    type CommissioningFileForm,
    CommissioningFileFormState,
    ConnectwareError,
    ConnectwareErrorType,
    mapCommissioningFileValidatedValues,
} from '../../../../domain'

import { initialState } from '../../..'
import { EditServiceTemplateOutputUsecase } from './Output'

export class ManageEditServiceTemplateUsecase extends EditServiceTemplateOutputUsecase {
    async loadFile (file: File): Promise<void> {
        /** Make sure form is not loaded */
        this.assertTypedForm([CommissioningFileFormState.INITIAL])

        /** Create update that usecase can check later if is the most recent update stll */
        const loadingFieldsUpdate: CommissioningFileForm = { type: CommissioningFileFormState.LOADING_FIELDS }

        /** Update the form with loading state */
        this.setForm(loadingFieldsUpdate)

        /** Then load the structure of the form so it can operate */
        const fields = await this.connectwareServicesService.generateCommissioningFileFields().catch((fields: ConnectwareError) => fields)

        /** Update only the last update was made by the current execution */
        this.updateForm({ type: CommissioningFileFormState.LOADED_FIELDS, fields }, loadingFieldsUpdate)

        if (ConnectwareError.is(fields)) {
            return
        }

        /** Create now values update */
        const loadingValuesUpdate: CommissioningFileForm = { type: CommissioningFileFormState.LOADING_VALUES, fields, file }

        /** Update the state now to be loading the values */
        this.setForm(loadingValuesUpdate)

        /** Then fetch new values */
        await this.connectwareServicesService
            .generateCommissioningFileValues(file)
            .then((values) => {
                const loadingOutputUpdate: CommissioningFileForm = {
                    type: CommissioningFileFormState.LOADING_OUTPUT,
                    fields,
                    file,
                    values: mapCommissioningFileValidatedValues(fields, values),
                }

                this.updateForm(loadingOutputUpdate, loadingValuesUpdate)
                this.scheduleOutputRefresh()
            })
            .catch((values: ConnectwareError) => this.updateForm({ type: CommissioningFileFormState.LOADED_VALUES, fields, file, values }, loadingValuesUpdate))
    }

    reset (): void {
        this.setForm(ManageEditServiceTemplateUsecase.selectFormFromState(initialState))
    }

    async downloadOutputFile (): Promise<void> {
        const { output } = this.getTypedForm([CommissioningFileFormState.LOADED_OUTPUT])

        if (!isArray(output)) {
            throw new ConnectwareError(ConnectwareErrorType.STATE, 'Output is not downloadable')
        }

        const [file] = output

        this.fileService.download(file)
    }
}

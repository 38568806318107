import { type NonEmptyArray, objectEntries, type ReadonlyRecord, splitByLength } from '../../../utils'
import { Capability, type Certificate, CertificateExtendedKeyUsage, CertificateKeyUsage } from '../../../domain'
import type { CertificatesService } from '../../../application'

import type { BackendJsonRequestContent, BackendJsonResponseContent, BackendQueryParameters } from '../../Connectware'
import { FetchConnectwareHTTPService } from '../Base'

/**
 * @see https://github.com/fidm/x509/blob/4acca1b2919832178fd52c88f2f1ecb12a14088f/src/x509.ts#L449
 */
const keyUsageMap: ReadonlyRecord<string, CertificateKeyUsage> = {
    critical: CertificateKeyUsage.CRITICAL,
    cRLSign: CertificateKeyUsage.CRL_SIGN,
    dataEncipherment: CertificateKeyUsage.DATA_ENCIPHERMENT,
    decipherOnly: CertificateKeyUsage.DECIPHER_ONLY,
    digitalSignature: CertificateKeyUsage.DIGITAL_SIGNATURE,
    encipherOnly: CertificateKeyUsage.ENCIPHER_ONLY,
    keyAgreement: CertificateKeyUsage.KEY_AGREEMENT,
    keyCertSign: CertificateKeyUsage.KEY_CERTSIGN,
    keyEncipherment: CertificateKeyUsage.KEY_ENCIPHERMENT,
    nonRepudiation: CertificateKeyUsage.NON_REPUDIATION,
}

/**
 * @see https://github.com/fidm/x509/blob/4acca1b2919832178fd52c88f2f1ecb12a14088f/src/x509.ts#L449
 */
const extendedKeyUsageMap: ReadonlyRecord<string, CertificateExtendedKeyUsage> = {
    critical: CertificateExtendedKeyUsage.CRITICAL,
    clientAuth: CertificateExtendedKeyUsage.CLIENT_AUTH,
    codeSigning: CertificateExtendedKeyUsage.CODE_SIGNING,
    emailProtection: CertificateExtendedKeyUsage.EMAIL_PROTECTION,
    OCSPSigning: CertificateExtendedKeyUsage.OCSP_SIGNING,
    serverAuth: CertificateExtendedKeyUsage.SERVER_AUTH,
    timeStamping: CertificateExtendedKeyUsage.TIME_STAMPING,
}

export type CertificateResponse = Readonly<{
    id: string
    issuedBy: Readonly<{
        commonName: string | null
        organization: string
        organizationalUnit: string | null
    }>
    issuedTo: Readonly<{
        commonName: string | null
        organization: string | null
        organizationalUnit: string | null
        alternativeNames: string[]
    }>
    keyUsage: (
        | 'critical'
        | 'cRLSign'
        | 'dataEncipherment'
        | 'decipherOnly'
        | 'digitalSignature'
        | 'encipherOnly'
        | 'keyAgreement'
        | 'keyCertSign'
        | 'keyEncipherment'
        | 'nonRepudiation'
        | string
    )[]
    extendedKeyUsage: ('critical' | 'clientAuth' | 'codeSigning' | 'emailProtection' | 'OCSPSigning' | 'serverAuth' | 'timeStamping' | string)[]
    basicConstraints: { isCA: boolean, pathLength: number }
    issuedOn: number
    expiresOn: number
    fingerprints: ReadonlyRecord<'sha1' | 'sha256', string>
    removable: boolean
}>

const mapUsageResponse = <T extends string>(usagesResponse: string[], map: ReadonlyRecord<string, T>): T[] =>
    objectEntries(map).reduce<T[]>((usages, [name, usage]) => (usagesResponse.includes(name) ? [...usages, usage] : usages), [])

const mapReponse = ({
    id,
    issuedBy,
    issuedTo,
    keyUsage,
    extendedKeyUsage,
    basicConstraints,
    fingerprints,
    issuedOn,
    expiresOn,
    removable,
}: CertificateResponse): Certificate => ({
    id,
    issuedBy: { ...issuedBy, commonName: issuedBy.commonName || null },
    issuedTo: { ...issuedTo, commonName: issuedTo.commonName || null },

    keyUsage: mapUsageResponse(keyUsage, keyUsageMap),
    extendedKeyUsage: mapUsageResponse(extendedKeyUsage, extendedKeyUsageMap),

    basicConstraints: basicConstraints.isCA ? { authority: true, pathLength: basicConstraints.pathLength } : { authority: false },

    issuedOn: new Date(issuedOn),
    expiresOn: new Date(expiresOn),

    fingerprints: { sha1: splitByLength(fingerprints.sha1, 2).join(':'), sha256: splitByLength(fingerprints.sha256, 2).join(':') },
    removable,
})

export class ConnectwareHTTPCertificatesService extends FetchConnectwareHTTPService implements CertificatesService {
    fetchCertificates (): Promise<Certificate[]> {
        return this.request({
            capability: Capability.CERTIFICATES_MANAGE,
            method: 'GET',
            path: '/api/certificates',
            authenticate: true,
            handlers: {
                200: (response) => response.getJson<CertificateResponse[]>().then((data) => data.map<Certificate>(mapReponse)),
            },
        })
    }

    delete ({ id }: Certificate): Promise<void> {
        const queryParams: BackendQueryParameters<'/api/certificates', 'delete'> = { id }

        return this.request({
            capability: Capability.CERTIFICATES_MANAGE,
            method: 'DELETE',
            path: '/api/certificates',
            authenticate: true,
            queryParams,
            handlers: { 200: () => Promise.resolve() },
        })
    }

    upload (certificateFile: string): Promise<Certificate> {
        return this.request<'/api/certificates', BackendJsonRequestContent<'/api/certificates', 'post'>, Certificate>({
            capability: Capability.CERTIFICATES_MANAGE,
            method: 'POST',
            path: '/api/certificates',
            authenticate: true,
            body: certificateFile,
            handlers: {
                201: (response) => response.getJson<NonEmptyArray<CertificateResponse>>().then(([cert]) => mapReponse(cert)),
            },
        })
    }

    fetchCertificateFileContent ({ id }: Certificate): Promise<string> {
        const queryParams: BackendQueryParameters<'/api/certificates/content', 'get'> = { id }
        return this.request({
            capability: Capability.CERTIFICATES_MANAGE,
            method: 'GET',
            path: '/api/certificates/content',
            authenticate: true,
            queryParams,
            handlers: { 200: (response) => response.getJson<BackendJsonResponseContent<'/api/certificates/content', 'get', 200>>() },
        })
    }
}

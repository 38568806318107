import type { ReadonlyRecord } from '../../../../../../utils'
import { ConnectwareError, ConnectwareErrorType, type CybusDetailedMapping } from '../../../../../../domain'

import { mapDetailedMapping, type MappingProxyParams } from '../../../../../Connectware'
import type { MappingProxy } from '../../../../proxies'
import { createProxyEventsHandler } from '..'
import { VrpcMappingToOneSubscriptionHandler } from './Base'

export class VrpcDetailedMappingProxyInstanceHandler extends VrpcMappingToOneSubscriptionHandler<'mapping', ReadonlyRecord<string, string[]>> {
    readonly optionalFilters = []

    readonly ignoreInstanceByFilter = null

    readonly onChange = createProxyEventsHandler<MappingProxy>('state', 'executionError')

    protected override mapMapping (params: MappingProxyParams, topics: ReadonlyRecord<string, string[]>): CybusDetailedMapping {
        return mapDetailedMapping(params, topics)
    }

    protected async fetchInformation (proxy: MappingProxy): Promise<ReadonlyRecord<string, string[]>> {
        try {
            return await proxy.getMappedEndpointTopics()
        } catch (e: unknown) {
            throw new ConnectwareError(ConnectwareErrorType.SERVER_ERROR, 'Could not retrieve configured endpoints topics on mapping', {
                id: proxy._targetId,
                message: (e as Error).message,
            })
        }
    }
}

import type { Mutable, PickByValue, PickByValueExact, UnionToIntersection } from 'utility-types'

import type { ArrayfyValues, ArrayType, OnlyDeepPrimitive, ReadonlyRecord, SubProperty } from '../../../utils'

import type * as authServer from './auth-server'
import type * as containerManager from './container-manager'
import type * as protocolMapper from './protocol-mapper'
import type * as serviceManager from './service-manager'
import type * as systemControlServer from './system-control-server'
import type * as rst from './resource-status-tracking'

type TypeEntry<Components, Paths> = Readonly<{ components: Components, paths: Paths }>
type SubtypeExtractor<P extends keyof TypeEntry<unknown, unknown>> = { [K in keyof AllTypeEntries]: AllTypeEntries[K][P] }

/**
 * Focal point of all supported types
 * Organized by the containers name
 */
type AllTypeEntries = Readonly<{
    ['auth-server']: TypeEntry<authServer.components, authServer.paths>
    ['container-manager']: TypeEntry<containerManager.components, containerManager.paths>
    ['protocol-mapper']: TypeEntry<protocolMapper.components, protocolMapper.paths>
    ['resource-status-tracking']: TypeEntry<rst.components, rst.paths>
    ['service-manager']: TypeEntry<serviceManager.components, serviceManager.paths>
    ['system-control-server']: TypeEntry<systemControlServer.components, systemControlServer.paths>
}>

/**
 * List of all container names available to the codebase
 */
type Names = keyof AllTypeEntries

type ComponentsSchema<C extends Names> =
    /**
     * Extract from components the schema by name
     */
    SubtypeExtractor<'components'>[C]['schemas']

type Definition<C extends Names, P extends keyof ComponentsSchema<C>> =
    /**
     * Retrieves a backend definition by its container name and property name
     */
    ComponentsSchema<C>[P]

type UnifiedPaths<C extends Names> =
    /**
     * All paths rolled into one single entry
     * This works on the understanding that no two paths collide
     */
    UnionToIntersection<SubtypeExtractor<'paths'>[C]>

type Path<C extends Names = Names> =
    /**
     * The wanted backend path, if not narrowed will list all paths
     */
    keyof UnifiedPaths<C>

type MethodPath<M extends SupportedHttpMethods> =
    /**
     * Paths only for the given HTTP method
     */
    keyof PickByValue<UnifiedPaths<Names>, ReadonlyRecord<M, unknown>>

type Endpoint<P extends Path> =
    /**
     * Get the configuration of the whole endpoint
     */
    UnifiedPaths<Names>[P]

/**
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods
 */
type SupportedHttpMethods = 'connect' | 'delete' | 'get' | 'head' | 'options' | 'patch' | 'post' | 'put' | 'trace'

type RawHttpMethod<P extends Path> =
    /**
     * Goes to the method configuration and extracts only the present config (those not undefined or never)
     * Then, extracts only valid http methods
     */
    Extract<keyof Omit<Endpoint<P>, keyof PickByValueExact<Endpoint<P>, never | undefined>>, SupportedHttpMethods>

type HttpMethod<P extends Path> = Uppercase<RawHttpMethod<P>>

type EndpointAtMethod<P extends Path, M extends RawHttpMethod<P>> =
    /**
     * Gets all the configuration of the given path and http method
     */
    Endpoint<P>[M]

type Parameters<P extends Path, M extends RawHttpMethod<P>> =
    /**
     * Extracts the parameters top level type of the given endpoint at the given method
     */
    SubProperty<EndpointAtMethod<P, M>, 'parameters'>

type PathParameters<P extends Path, HttpMethod extends RawHttpMethod<P> = RawHttpMethod<P>> =
    /**
     * With the given path and http method then yield an array of path the needed path parameters
     */
    ArrayfyValues<SubProperty<Parameters<P, HttpMethod>, 'path'>>

type StringifiedParameter<T> = T extends string | boolean | number ? `${T}` : T extends any[] | readonly any[] ? ArrayType<Mutable<T>>[] : never
type StringifiedParameters<T> = { [P in keyof T]: StringifiedParameter<T[P]> }
type QueryParameters<P extends Path, H extends RawHttpMethod<P> = RawHttpMethod<P>> =
    /**
     * With the given path and http method then yield an the needed query parameters
     * They are stringified as only strings can actually be passed
     */
    StringifiedParameters<SubProperty<Parameters<P, H>, 'query'>>

type RequestBodyContent<P extends Path, H extends RawHttpMethod<P>> =
    /**
     * For the path and http method, get the requestBody
     */
    SubProperty<SubProperty<EndpointAtMethod<P, H>, 'requestBody'>, 'content'>

type JsonRequestContent<P extends Path, H extends RawHttpMethod<P> = RawHttpMethod<P>> =
    /**
     * From the response, get the json response
     * But only accept values that can be properly converted to JSON
     */
    OnlyDeepPrimitive<SubProperty<RequestBodyContent<P, H>, 'application/json'>>

type Responses<P extends Path, H extends RawHttpMethod<P>> =
    /**
     * For the path and method, get the responses
     */
    SubProperty<EndpointAtMethod<P, H>, 'responses'>

type ResponseStatuses<P extends Path, H extends RawHttpMethod<P> = RawHttpMethod<P>> =
    /**
     * For the path and method, get the keys (status codes) and make sure that they are numbers
     */
    Extract<keyof Responses<P, H>, number>

type ResponseContent<P extends Path, H extends RawHttpMethod<P>, S extends ResponseStatuses<P, H>> =
    /**
     * For the path, method and status, get the given content
     */
    SubProperty<Responses<P, H>[S], 'content'>

type JsonResponseContent<P extends Path, H extends RawHttpMethod<P> = RawHttpMethod<P>, S extends ResponseStatuses<P, H> = ResponseStatuses<P, H>> =
    /**
     * For the path, method and status, get the given json content
     */
    OnlyDeepPrimitive<SubProperty<ResponseContent<P, H, S>, 'application/json'>>

type EventStreamResponseContent<P extends Path, H extends RawHttpMethod<P> = RawHttpMethod<P>, S extends ResponseStatuses<P, H> = ResponseStatuses<P, H>> =
    /**
     * For the path, method and status, get the given websocket content
     */
    OnlyDeepPrimitive<SubProperty<ResponseContent<P, H, S>, 'text/event-stream'>>

export type {
    Path as BackendPath,
    MethodPath as BackendMethodPath,
    HttpMethod as BackendHttpMethod,
    ResponseStatuses as BackendResponseStatuses,
    PathParameters as BackendPathParameters,
    QueryParameters as BackendQueryParameters,
    JsonRequestContent as BackendJsonRequestContent,
    JsonResponseContent as BackendJsonResponseContent,
    EventStreamResponseContent as BackendEventStreamResponseContent,
    Definition as BackendDefinition,
}

export * from './Legacy'

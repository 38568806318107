import type { NonUndefined, Primitive, UnionToIntersection, ValuesType } from 'utility-types'

export type ExcludeValues<T, E> = Readonly<{ [P in keyof T]: Exclude<T[P], E> }>

export type ReplaceValues<T, ReplaceProps extends keyof T, Replacement> = Omit<T, ReplaceProps> & Readonly<{ [P in ReplaceProps]: Replacement }>

export type NullableValues<T, K extends keyof T = keyof T> = Omit<T, K> & Readonly<{ [P in keyof T & K]: T[P] | null }>

export type NonNullableValues<T, K extends keyof T = keyof T> = Omit<T, K> & Readonly<{ [P in keyof T & K]: NonNullable<T[P]> }>

export type NullValues<T> = Readonly<{ [P in keyof T]: null }>

export type ObjectOrArray<T> = T | T[]

export type ArrayType<T extends Array<unknown> | readonly unknown[]> = T extends Array<infer U> ? U : T extends readonly (infer U)[] ? U : never

export type ArrayIntersection<A extends Array<unknown>> = ArrayType<A>[]

export type NonEmptyArray<T> = [T, ...T[]] & T[]

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ReadonlyRecord<K extends keyof any, T> = Readonly<Record<K, T>>

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type PartialRecord<K extends keyof any, T> = Partial<Record<K, T>>

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type PartialReadonlyRecord<K extends keyof any, T> = Partial<ReadonlyRecord<K, T>>

/**
 * This type extracts all possible keys of an Union
 *
 * It works based on UnionToIntersection, but since
 * UnionToIntersection does not allow for mismatched unions to become
 * an impossible intersection (so we can extract all the keys all of it)
 * we first then need to make all of the keys to have a common type
 * the easiest to get to being to use Partial
 *
 * @see https://github.com/piotrwitek/utility-types/issues/192
 */
export type AllUnionKeys<U> = keyof UnionToIntersection<Partial<U>>

/**
 * The NonUndefined is here to handle properties that are present, but optional
 * If they are retrieved, then the undefined needs to go as it means nothing to the output, and should be a never instead
 */
export type SubProperty<T, P extends keyof any> = NonUndefined<T extends PartialRecord<P, unknown> ? T[P] : never>

type UnionToOverloadedFunctions<U> = UnionToIntersection<U extends any ? (f: U) => void : never>
type LastUnionMember<U> = UnionToOverloadedFunctions<U> extends (a: infer A) => void ? A : never
type IsUnion<T> = [T] extends [UnionToIntersection<T>] ? false : true
type UnionToArray<T, A extends unknown[] = []> = IsUnion<T> extends true ? UnionToArray<Exclude<T, LastUnionMember<T>>, [LastUnionMember<T>, ...A]> : [T, ...A]
type Detupple<T> = { [P in keyof T]: T[P] extends [any, infer Type] ? Type : never }
type Tupple<T> = { [P in keyof T]: [P, T[P]] }

/**
 * Turns the given type into a tupple of the given properties
 * @example { id: string, age: number, name: string } -> [string, number, string]
 */
export type ArrayfyValues<T> = T extends never ? never : Detupple<UnionToArray<ValuesType<Tupple<T>>>>

/**
 * This type will yield a never whenever a union is given to it
 * @see https://stackoverflow.com/questions/50639496/is-there-a-way-to-prevent-union-types-in-typescript#answer-50641073
 */
export type NonUnion<Key> = [Key] extends [UnionToIntersection<Key>] ? Key : never

/**
 * Yields from the given type a new type that only contains primitives
 *
 * @example { foo: string, bar: Date } => { foo: string }
 * @example { foo: string, bar: { foo: string, bar: Date } } => { foo: string, bar: { foo: string } }
 * @example { foo: string, bar: Date }[] => { foo: string }[]
 * @example Date => never
 */
export type OnlyDeepPrimitive<T> =
    /** If array, iterate the inner items */
    T extends Array<infer A>
        ? OnlyDeepPrimitive<A>[]
        : /** If object, iterate the inner items, but ignore the internal non primitives */
        T extends Record<keyof any, any>
        ? { [K in keyof T]: OnlyDeepPrimitive<T[K]> }
        : /** Finally just print out those that are primitive */
          Extract<T, Primitive>

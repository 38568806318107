import { isArray } from '../../../../utils'
import {
    type AppState,
    type CommissioningFileForm,
    type CommissioningFileFormState,
    ConnectwareError,
    ConnectwareErrorType,
    isCommissioningFileFormInState,
    mapEditTemplateToState,
    selectEditTemplate,
} from '../../../../domain'

import { CommissioningFileUsecase } from '../Commissioning'

export abstract class EditServiceTemplateUsecase extends CommissioningFileUsecase {
    protected static selectFormFromState: (s: AppState) => CommissioningFileForm = selectEditTemplate

    protected static mapEditTemplateToState: (s: CommissioningFileForm) => Partial<AppState> = mapEditTemplateToState

    protected setForm (form: CommissioningFileForm): void {
        this.setState(EditServiceTemplateUsecase.mapEditTemplateToState(form))
    }

    protected updateForm (form: CommissioningFileForm, expected: CommissioningFileForm): void {
        this.assertTypedForm(expected)
        this.setForm(form)
    }

    protected getTypedForm<T extends CommissioningFileFormState> (
        expected: T[] | Extract<CommissioningFileForm, { type: T }>
    ): Extract<CommissioningFileForm, { type: T }> {
        const actual = EditServiceTemplateUsecase.selectFormFromState(this.getState())

        if (actual === expected) {
            /** Full comparison is mandated */
            return expected
        }

        if (isArray(expected) && isCommissioningFileFormInState(actual, ...expected)) {
            /** Only a type check is mandated */
            return actual
        }

        throw new ConnectwareError(ConnectwareErrorType.STATE, 'Form was not in expected state', { expected, actual })
    }

    protected assertTypedForm<T extends CommissioningFileFormState> (expected: T[] | Extract<CommissioningFileForm, { type: T }>): void {
        this.getTypedForm(expected)
    }
}

import { ConnectwareError, type ConnectwareErrorExtras, ConnectwareErrorType } from './Error'
import type { Loadable } from './Loadable'

export enum SSOAuthenticationMethod {
    MICROSOFT_ENTRA_ID = 'MICROSOFT_ENTRA_ID',
}

export type SSOAppState = Readonly<{ supportedSSOs: Loadable<SSOAuthenticationMethod[]> }>

export const selectSupportedSSOs = (s: SSOAppState): SSOAppState['supportedSSOs'] => s.supportedSSOs

export class SSOAuthenticationError extends ConnectwareError<
    ConnectwareErrorType.AUTHENTICATION,
    Readonly<{ method: SSOAuthenticationMethod, reason: 'invalid_client' | 'user_already_exists' | 'failed_login_redirect' | 'unknown' }> &
        ConnectwareErrorExtras<ConnectwareError>
> {
    static override is (e: unknown): e is SSOAuthenticationError {
        return e instanceof SSOAuthenticationError
    }

    constructor (extras: ConnectwareErrorExtras<SSOAuthenticationError>) {
        super(ConnectwareErrorType.AUTHENTICATION, 'Could not authenticate with SSO', extras)
    }
}

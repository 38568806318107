import React, { type FC } from 'react'
import { Alert, Box, type SxProps, type Theme, Typography } from '@mui/material'
import { Launch } from '@mui/icons-material'

import { Translation } from '../../../../../domain'
import { DocumentationType } from '../../../../../application'

import { FormattedTranslation, useTranslator } from '../../../Internationalization'
import { DocumentationLink } from '../../../Documentation'

const actionNeededStyle: SxProps<Theme> = { my: '10px', mx: 0 }
const openDocumentationLinkStyle: SxProps<Theme> = { height: '1rem', width: '1rem', verticalAlign: 'text-bottom' }

export const NoLicense: FC = () => {
    const translator = useTranslator()

    return (
        <Box>
            <Alert data-testid="no-license-alert" sx={actionNeededStyle} severity="warning">
                <FormattedTranslation id={Translation.LICENSE_ACTIVATION_NEEDED} />
            </Alert>
            <FormattedTranslation
                id={Translation.LICENSE_ACTIVATION_NEEDED_DESCRIPTION}
                values={{
                    paragraph: (...content: string[]) => <Typography variant="body1">{content}</Typography>,
                    link: (...content: string[]) => (
                        <DocumentationLink
                            data-testid="no-license-link"
                            title={translator.formatTranslation(Translation.OPEN_DOCUMENTATION)}
                            type={DocumentationType.LICENSE_OFFLINE_ACTIVATION}
                        >
                            {content} <Launch sx={openDocumentationLinkStyle} />
                        </DocumentationLink>
                    ),
                }}
            />
        </Box>
    )
}

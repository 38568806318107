import type { CybusServer } from '../../../../../../domain'

import { mapCybusServer } from '../../../../../Connectware'
import { OPCUA_SERVER_CLASSNAME_FILTER } from '../../../../constants'
import type { ServerProxy } from '../../../../proxies'
import {
    createProxyEventsHandler,
    excludeInstanceByServiceName,
    SubscriptionHandlerType,
    type VrpcHandlerMappingPropertiesArgs,
    type VrpcInstanceToOneSubscriptionHandler,
} from '..'

type ServerHandler = VrpcInstanceToOneSubscriptionHandler<ServerProxy, CybusServer>
type ServerHandlerArgs = VrpcHandlerMappingPropertiesArgs<ServerHandler>

abstract class VrpcBaseServerProxyToCybusServerHandler implements ServerHandler {
    abstract readonly optionalFilters: ServerHandler['optionalFilters']

    abstract readonly ignoreInstanceByFilter: ServerHandler['ignoreInstanceByFilter']

    readonly type = SubscriptionHandlerType.INSTANCE_ONE_TO_ONE

    readonly requiredFilters = []

    readonly classNameFilter = OPCUA_SERVER_CLASSNAME_FILTER

    readonly ignoreInstances = null

    readonly sourceInstanceName = null

    readonly agent = null

    readonly onChange = createProxyEventsHandler<ServerProxy>('state')

    mapToDomain ({ instance }: ServerHandlerArgs['DomainMapperArgs']): Promise<ServerHandlerArgs['Domain']> {
        return instance.getParams().then(mapCybusServer)
    }
}

export class VrpcServerProxyToCybusServersHandler extends VrpcBaseServerProxyToCybusServerHandler {
    readonly optionalFilters = ['service' as const]

    readonly ignoreInstanceByFilter = excludeInstanceByServiceName
}

export class VrpcServerProxyToCybusServerHandler extends VrpcBaseServerProxyToCybusServerHandler {
    readonly optionalFilters = []

    readonly ignoreInstanceByFilter = null
}

import { areCybusMappingEquals, Capability, type CybusMapping } from '../../../../../../domain'
import type { SubscriptionFilterArgs } from '../../../../../../application'

import { FilteredAsyncMapper, mapMapping, type MappingProxyParams } from '../../../../../Connectware'
import { BaseSubscriptionStrategy } from '../Base'

class MappingsMapper extends FilteredAsyncMapper<MappingProxyParams, CybusMapping> {
    protected override map (mapping: MappingProxyParams): Promise<CybusMapping> | null {
        const { service } = this.filter

        const cybusMapping = mapMapping(mapping)

        if (service && service !== cybusMapping.service) {
            return null
        }

        return Promise.resolve(cybusMapping)
    }
}

export class MappingsStrategy extends BaseSubscriptionStrategy<'mappings'> {
    protected override readonly supportedFilters: (keyof SubscriptionFilterArgs)[] = ['service']

    readonly requiredPermissions = [Capability.MAPPINGS_READ]

    protected override readonly areEquals = areCybusMappingEquals

    override async retrieveAll ({ service }: SubscriptionFilterArgs): Promise<CybusMapping[]> {
        return this.retrieve({
            capability: Capability.MAPPINGS_READ,
            method: 'GET',
            path: '/api/mappings',
            mapper: (data: MappingProxyParams[]) => {
                const mapper = new MappingsMapper({ service })

                for (const mapping of data) {
                    mapper.push(mapping)
                }

                return mapper.values
            },
        })
    }
}

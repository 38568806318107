import React, { type FC, type HTMLAttributes } from 'react'

import { Box, LinearProgress, Typography } from '@mui/material'
import { Person, Security } from '@mui/icons-material'

import { isArray } from '../../../utils'
import { ConnectwareError, type CybusRole, type CybusUser, Translation } from '../../../domain'

import { FormattedTranslation } from '../Internationalization'
import { ErrorMessage } from '../ErrorMessage'
import { EntryLabel } from './Modal'

export const Option: FC<HTMLAttributes<HTMLLIElement> & { value: CybusUser | CybusRole }> = ({ value, ...props }) => {
    const { name, icon, permissions } =
        'username' in value
            ? { name: value.username, icon: Person, permissions: value.allPermissions }
            : { name: value.name, icon: Security, permissions: value.permissions }

    return (
        <li {...props}>
            <EntryLabel icon={icon}>
                <div data-testid={`option-${name}`}>
                    <Typography variant="body1">{name}</Typography>
                    <Typography variant="body2">
                        <FormattedTranslation id={Translation.TOTAL_PERMISSIONS} values={{ count: permissions.length }} />
                    </Typography>
                </div>
            </EntryLabel>
        </li>
    )
}

export const NoOptions: FC<{ data: unknown[] | ConnectwareError | null, notFound: Translation }> = ({ data, notFound }) => (
    <>
        {isArray(data) && (
            <Typography variant="body2">
                <FormattedTranslation id={notFound} />
            </Typography>
        )}
        {data === null && (
            <Box>
                <LinearProgress />
            </Box>
        )}
        {ConnectwareError.is(data) && <ErrorMessage titleVariant="body2" error={data} />}
    </>
)

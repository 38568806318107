import type { ContainerInspectInfo } from 'dockerode'

import { type CybusCoreContainer, type CybusServiceContainer, StatusType } from '../../../../domain'
import { mapToStatusType } from '../Status'

type Info = Pick<ContainerInspectInfo['State'], 'Running' | 'Paused' | 'Restarting' | 'Dead' | 'StartedAt' | 'FinishedAt' | 'Status'>

/**
 * This maps the proper last update property according to the code below
 *
 * @see https://github.com/moby/moby/blob/b44b5bbc8ba48f50343602a21e7d44c017c1e23d/container/state.go#L42
 */
export const mapDockerContainerState = ({
    Running,
    Paused,
    Restarting,
    Dead,
    StartedAt,
    FinishedAt,
    Status,
}: Info): Pick<CybusCoreContainer | CybusServiceContainer, 'status' | 'lastUpdate'> => {
    if (Running) {
        if (Paused) {
            return { status: StatusType.PAUSED, lastUpdate: new Date(StartedAt) }
        }

        if (Restarting) {
            return { status: StatusType.RESTARTING, lastUpdate: new Date(FinishedAt) }
        }

        return { status: StatusType.RUNNING, lastUpdate: new Date(StartedAt) }
    }

    if (Dead || !StartedAt || !FinishedAt) {
        /**
         * https://i.imgur.com/MPbX6OD.jpg
         */
        return { status: mapToStatusType(Status), lastUpdate: null }
    }

    return { status: mapToStatusType(Status), lastUpdate: new Date(FinishedAt) }
}

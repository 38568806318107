import type { ReadonlyRecord } from '../../../utils'

/**
 * @todo generate this type from BE images
 * @see https://cybusio.atlassian.net/browse/CC-2690
 * @see https://bitbucket.org/cybusio/cybus/src/624d6b87cbeb24b8a53bc0e25ae894f68b1c6234/protocol-mapper/src/Node.js#lines-160
 * @see https://bitbucket.org/cybusio/cybus/src/624d6b87cbeb24b8a53bc0e25ae894f68b1c6234/protocol-mapper/src/swagger/swagger.yaml#lines-578
 */
export type NodeProxyParams = Readonly<{
    id: string
    operation?: string
    targetState: string
    topicPrefix: string
    parentId: string
    initialValue?: string
    qos: number
    retain: boolean
    rules?: ReadonlyRecord<string, unknown>
    /** @todo better define */
    inputBuffering?: unknown
    options: unknown
    currentState: string
    protocol: string
}>

import {
    areArrayEquals,
    areRecordsEquals,
    createArrayComparator,
    createEqualityChecker,
    createNamedEqualityChecker,
    isArray,
    type ReadonlyRecord,
} from '../../utils'
import type { CybusDetailedConnection, CybusPersistedPermission, CybusVolume } from '..'
import type { CybusEndpoint } from '../Endpoint'

export type CybusPermissionConfiguration = Pick<CybusPersistedPermission, 'resource' | 'context' | 'read' | 'write'>
export type CybusNamedPermissionConfiguration = Readonly<{ name: string, permissions: CybusPermissionConfiguration[] }>

export type CybusTCPIngressRouteConfiguration = Readonly<{ container: string, connectwarePort: number, containerPort: number }>
export type CybusHTTPIngressRouteConfiguration = Readonly<{ container: string, slug: string }>

type CybusContainerDevice = Readonly<{ pathOnHost: string, pathInContainer: string, cGroupPermissions: string }>

export type CybusConnectionConfiguration = Readonly<{ name: string, protocol: string }> &
    Pick<
        CybusDetailedConnection,
        'host' | 'port' | 'deviceAddress' | 'deviceInstance' | 'ipAddress' | 'cncType' | 'agent' | 'className' | 'device' | 'localInterface' | 'brokers'
    >

export enum CybusServerProtocol {
    HTTP = 'HTTP',
    OPCUA = 'OPCUA',
}

export type CybusServerConfiguration = Readonly<{
    protocol: CybusServerProtocol
    name: string
    basePath: string | null
    port: number | null
    allowsAnonymous: boolean | null
}>

export type CybusContainerConfiguration = Readonly<{
    image: string
    name: string
    privileged: boolean
    ports: string[]
    capabilities: CybusContainerCapability[]
    devices: CybusContainerDevice[]
}>

export enum CybusContainerCapability {
    ALL = 'ALL',
    AUDIT_CONTROL = 'AUDIT_CONTROL',
    AUDIT_WRITE = 'AUDIT_WRITE',
    CHOWN = 'CHOWN',
    DAC_OVERRIDE = 'DAC_OVERRIDE',
    DAC_READ_SEARCH = 'DAC_READ_SEARCH',
    FOWNER = 'FOWNER',
    FSETID = 'FSETID',
    IPC_LOCK = 'IPC_LOCK',
    IPC_OWNER = 'IPC_OWNER',
    KILL = 'KILL',
    LEASE = 'LEASE',
    LINUX_IMMUTABLE = 'LINUX_IMMUTABLE',
    MAC_ADMIN = 'MAC_ADMIN',
    MAC_OVERRIDE = 'MAC_OVERRIDE',
    MKNOD = 'MKNOD',
    NET_ADMIN = 'NET_ADMIN',
    NET_BIND_SERVICE = 'NET_BIND_SERVICE',
    NET_BROADCAST = 'NET_BROADCAST',
    NET_RAW = 'NET_RAW',
    SETFCAP = 'SETFCAP',
    SETGID = 'SETGID',
    SETPCAP = 'SETPCAP',
    SETUID = 'SETUID',
    SYS_ADMIN = 'SYS_ADMIN',
    SYS_BOOT = 'SYS_BOOT',
    SYS_CHROOT = 'SYS_CHROOT',
    SYS_MODULE = 'SYS_MODULE',
    SYS_NICE = 'SYS_NICE',
    SYS_PACCT = 'SYS_PACCT',
    SYS_PTRACE = 'SYS_PTRACE',
    SYS_RAWIO = 'SYS_RAWIO',
    SYS_RESOURCE = 'SYS_RESOURCE',
    SYS_TIME = 'SYS_TIME',
    SYS_TTY_CONFIG = 'SYS_TTY_CONFIG',
    SYSLOG = 'SYSLOG',
    WAKE_ALARM = 'WAKE_ALARM',
}

export type CybusMappingPubSub = Readonly<{ topic: string | null, externalBroker: string | null, endpoint: string | null }>
export type CybusMappingConfiguration = Readonly<{ publish: CybusMappingPubSub, subscribe: CybusMappingPubSub[] }>

/**
 * The Cybus Service Resources as they appear on the commissioning file (with relevancy for the frontend)
 */
export type CybusConfiguredResources = Readonly<{
    roles: CybusNamedPermissionConfiguration[]
    users: CybusNamedPermissionConfiguration[]
    tcpRoutes: CybusTCPIngressRouteConfiguration[]
    httpRoutes: CybusHTTPIngressRouteConfiguration[]
    containers: CybusContainerConfiguration[]
    volumes: CybusVolume['id'][]
    connections: CybusConnectionConfiguration[]
    mappings: CybusMappingConfiguration[]
    servers: CybusServerConfiguration[]
    endpoints: CybusEndpoint['id'][]
}>

const areCybusNamedPermissionConfigurationsEquals = createEqualityChecker<CybusNamedPermissionConfiguration>({
    name: null,
    permissions: createArrayComparator(createEqualityChecker<CybusPermissionConfiguration>({ read: null, write: null, context: null, resource: null })),
})

const areCybusPubSubsEquals = createEqualityChecker<CybusMappingPubSub>({ topic: null, endpoint: null, externalBroker: null })

export const areCybusConfiguredResourcesEquals = createNamedEqualityChecker<CybusConfiguredResources>(
    {
        roles: createArrayComparator(areCybusNamedPermissionConfigurationsEquals),
        users: createArrayComparator(areCybusNamedPermissionConfigurationsEquals),
        tcpRoutes: createArrayComparator(
            createEqualityChecker<CybusTCPIngressRouteConfiguration>({ container: null, connectwarePort: null, containerPort: null })
        ),
        httpRoutes: createArrayComparator(createEqualityChecker<CybusHTTPIngressRouteConfiguration>({ container: null, slug: null })),
        containers: createArrayComparator(
            createEqualityChecker<CybusContainerConfiguration>({
                image: null,
                name: null,
                privileged: null,
                ports: createArrayComparator(),
                capabilities: createArrayComparator(),
                devices: createArrayComparator(
                    createEqualityChecker<CybusContainerDevice>({ cGroupPermissions: null, pathInContainer: null, pathOnHost: null })
                ),
            })
        ),
        volumes: createArrayComparator(),
        connections: createArrayComparator(
            createEqualityChecker<CybusConnectionConfiguration>({
                agent: null,
                brokers: (a, b) => isArray(a) && isArray(b) && areArrayEquals(a, b, { sort: false }),
                className: null,
                cncType: null,
                device: null,
                deviceAddress: null,
                deviceInstance: null,
                host: null,
                ipAddress: null,
                localInterface: null,
                name: null,
                port: null,
                protocol: null,
            })
        ),
        mappings: createArrayComparator(
            createEqualityChecker<CybusMappingConfiguration>({ publish: areCybusPubSubsEquals, subscribe: createArrayComparator(areCybusPubSubsEquals) })
        ),
        servers: createArrayComparator(
            createEqualityChecker<CybusServerConfiguration>({ protocol: null, name: null, basePath: null, port: null, allowsAnonymous: null })
        ),
        endpoints: createArrayComparator(),
    },
    'areCybusConfiguredResourcesEquals'
)

export type CybusAvailableResources = ReadonlyRecord<'servers' | 'containers' | 'volumes' | 'connections' | 'endpoints' | 'mappings', number>

export const areCybusAvailableResourcesEquals: (a: CybusAvailableResources, b: CybusAvailableResources) => boolean = areRecordsEquals

import type { CybusMapping } from '../../../../../../domain'

import { mapMapping, type MappingProxyParams } from '../../../../../Connectware'
import type { MappingProxy } from '../../../../proxies'
import { createProxyEventsHandler, excludeInstanceByServiceName } from '..'
import { VrpcMappingToOneSubscriptionHandler } from './Base'

export class VrpcMappingProxyInstanceHandler extends VrpcMappingToOneSubscriptionHandler<'mappings', void> {
    readonly optionalFilters = ['service' as const]

    readonly ignoreInstanceByFilter = excludeInstanceByServiceName

    readonly onChange = createProxyEventsHandler<MappingProxy>('state')

    protected override mapMapping (params: MappingProxyParams): CybusMapping {
        return mapMapping(params)
    }

    protected fetchInformation (): Promise<void> {
        return Promise.resolve()
    }
}

import { ConnectwareError, ConnectwareErrorType, type CybusDetailedVolume, type CybusVolume } from '../../../../../../domain'

import { CONTAINER_MANAGER_AGENT, mapDetailedVolume, mapVolumes } from '../../../../../Connectware'
import { CONTAINER_MANAGER_CLASSNAME, CONTAINER_MANAGER_DOCKER_INSTANCE } from '../../../../constants'
import type { ContainerManagerOrchestratorProxy as ContainerManagerProxy } from '../../../../proxies'
import {
    SubscriptionHandlerType,
    type VrpcHandlerMappingPropertiesArgs,
    type VrpcInstanceToListSubscriptionHandler,
    type VrpcInstanceToVirtualOneSubscriptionHandler,
} from '..'
import { createContainerManagerOrchestratorProxyHandler } from './Listener'

type CybusVolumesHandler = VrpcInstanceToListSubscriptionHandler<ContainerManagerProxy, CybusVolume>
type CybusVolumesHandlerArgs = VrpcHandlerMappingPropertiesArgs<CybusVolumesHandler>

export class VrpcContainerManagerOrchestratorProxyToVolumeHandler implements CybusVolumesHandler {
    readonly type = SubscriptionHandlerType.INSTANCE_ONE_TO_LIST

    readonly optionalFilters = ['service' as const]

    readonly requiredFilters = []

    readonly classNameFilter = CONTAINER_MANAGER_CLASSNAME

    readonly agent = CONTAINER_MANAGER_AGENT

    readonly sourceInstanceName = CONTAINER_MANAGER_DOCKER_INSTANCE

    readonly ignoreInstances = null

    readonly ignoreInstanceByFilter = null

    readonly onChange = createContainerManagerOrchestratorProxyHandler

    mapToDomain ({ instance, filter }: CybusVolumesHandlerArgs['DomainMapperArgs']): Promise<CybusVolumesHandlerArgs['Domain']> {
        return instance.listVolumes().then(({ Volumes }) => mapVolumes(Volumes, filter))
    }
}

type CybusDetailedVolumeHandler = VrpcInstanceToVirtualOneSubscriptionHandler<ContainerManagerProxy, CybusDetailedVolume>
type CybusDetailedVolumeHandlerArgs = VrpcHandlerMappingPropertiesArgs<CybusDetailedVolumeHandler>

export class VrpcContainerManagerOrchestratorProxyToDetailedVolumeHandler implements CybusDetailedVolumeHandler {
    readonly type = SubscriptionHandlerType.INSTANCE_ONE_TO_VIRTUAL_ONE

    readonly optionalFilters = []

    readonly requiredFilters = []

    readonly classNameFilter = CONTAINER_MANAGER_CLASSNAME

    readonly agent = CONTAINER_MANAGER_AGENT

    readonly sourceInstanceName = CONTAINER_MANAGER_DOCKER_INSTANCE

    readonly ignoreInstances = null

    readonly ignoreInstanceByFilter = null

    readonly onChange = createContainerManagerOrchestratorProxyHandler

    async mapToDomain ({ instance, id }: CybusDetailedVolumeHandlerArgs['DomainMapperArgs']): Promise<CybusDetailedVolumeHandlerArgs['Domain']> {
        const volume = await instance.inspectVolume(id)

        if (!volume) {
            throw new ConnectwareError(ConnectwareErrorType.NOT_FOUND, 'Could not find volume', { id })
        }

        return mapDetailedVolume(volume)
    }
}

import { areCybusVolumeEquals, Capability, type CybusVolume } from '../../../../../../domain'
import type { SubscriptionFilterArgs } from '../../../../../../application'

import { type DockerVolumesResponse, mapVolumes } from '../../../../../Connectware'
import { BaseSubscriptionStrategy } from '../Base'

export class VolumesStrategy extends BaseSubscriptionStrategy<'volumes'> {
    readonly requiredPermissions = [Capability.VOLUMES_READ]

    protected override readonly supportedFilters: (keyof SubscriptionFilterArgs)[] = ['service']

    protected override readonly areEquals = areCybusVolumeEquals

    override retrieveAll (filter: SubscriptionFilterArgs): Promise<CybusVolume[]> {
        return this.retrieve({
            capability: Capability.VOLUMES_READ,
            method: 'GET',
            path: '/api/volumes',
            mapper: (data: DockerVolumesResponse) => mapVolumes(data.Volumes, filter),
        })
    }
}

import type {
    AgentsAppState,
    AuthenticationAppState,
    BackupAppState,
    CertificatesAppState,
    ConnectionsAppState,
    ContainersAppState,
    EndpointsAppState,
    ExplorerAppState,
    InfoAppState,
    LastCopyIdAppState,
    LicenseAppState,
    LoadingAppState,
    LoginAppState,
    MappingsAppState,
    MfaAppState,
    PasswordAppState,
    ResourcesAppState,
    RuleEngineAppState,
    ServersAppState,
    ServicesAppState,
    SSOAppState,
    UsersManagementAppState,
    VersionsAppState,
    VolumesAppState,
} from '.'

/**
 * The state of the application, managed by the usecases
 */
export type AppState = LicenseAppState &
    VersionsAppState &
    InfoAppState &
    ExplorerAppState &
    RuleEngineAppState &
    CertificatesAppState &
    ServersAppState &
    AgentsAppState &
    BackupAppState &
    ResourcesAppState &
    LoginAppState &
    PasswordAppState &
    UsersManagementAppState &
    ServicesAppState &
    EndpointsAppState &
    AuthenticationAppState &
    VolumesAppState &
    ContainersAppState &
    ConnectionsAppState &
    MappingsAppState &
    LastCopyIdAppState &
    MfaAppState &
    LoadingAppState &
    SSOAppState

import { type EventListener, isArray, type NonEmptyArray, objectEntries, objectKeys, type ReadonlyRecord, throwCaptured } from '../../../utils'
import { ConnectwareError, ConnectwareErrorType } from '../../../domain'

import type { AuthenticatedResource, BackendHttpMethod, BackendPath } from '../../Connectware'
import { buildPath } from './Path'

/**
 * The http method being used
 */
type HttpMethod = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'CONNECT' | 'HEAD' | 'OPTIONS' | 'PATCH' | 'TRACE'

/**
 * The possible request bodies to be sent
 */
export type HttpRequestBody = FormData | ReadonlyRecord<string, unknown> | unknown[] | readonly unknown[] | string

/**
 * The response that is recieved by the handlers
 */
export interface HttpResponse {
    getStatus(): number
    getHeaders(): [string, string][]
    /**
     * @throws ConnectwareError
     */
    getBlob(): Promise<Blob>
    /**
     * @throws ConnectwareError
     */
    getText(): Promise<string>
    /**
     * @throws ConnectwareError
     */
    getJson<T>(): Promise<T>
}

/**
 * The internal constructed fetch args
 */
export type HttpFetchArgs = Readonly<{
    url: string
    method: HttpMethod
    headers: ReadonlyRecord<string, string>
    body: FormData | string | null
}>

type RequiredArgs<Path extends BackendPath, Result> = AuthenticatedResource<Path> &
    Readonly<{
        method: BackendHttpMethod<Path>

        /**
         * The handlers that will deal with the response
         */
        handlers: ReadonlyRecord<number, (response: HttpResponse) => ConnectwareError | Result | Promise<ConnectwareError | Result>>
    }>

type OptionalArgs<ReqBody extends HttpRequestBody> = Readonly<{
    /**
     * How should the method authenticate
     *
     * @example true -> use from state
     * @example `string` -> use as token
     */
    authenticate: boolean | string

    /**
     * What body will be sent to the endpoint
     *
     * Non form-data objects will be stringified
     */
    body: ReqBody

    /**
     * The dynamic part of the path
     */
    pathParams: NonEmptyArray<string>

    /**
     * The query params for the url
     */
    queryParams: ReadonlyRecord<string, string | string[]>
}>

export type HttpRequestArgs<Path extends BackendPath, ReqBody extends HttpRequestBody, Return> = RequiredArgs<Path, Return> & Partial<OptionalArgs<ReqBody>>

export type ConnectwareHTTPServiceOptions = Readonly<{
    baseURL: string
    getToken?: () => string | null
    responseHandler?: Pick<EventListener<HttpResponse>, 'trigger'>
}>

export abstract class BaseConnectwareHTTPService {
    private static prepareContent<ReqBody extends HttpRequestBody> (
        body: OptionalArgs<ReqBody>['body'] | null = null
    ): [body: FormData | string | null, headers: ReadonlyRecord<string, string>] {
        let content: FormData | string | null = null
        let contentType: string | null = null

        if (body instanceof FormData) {
            content = body
            /**
             * fetch itself generate the content header type according to the FormData object
             * @see https://github.com/github/fetch/issues/505#issuecomment-293064470
             */
        } else if (body !== null) {
            content = JSON.stringify(body)
            contentType = 'application/json'
        }

        return [content, contentType ? { 'Content-Type': contentType } : {}]
    }

    constructor (private readonly options: ConnectwareHTTPServiceOptions) {}

    private createAuthenticationHeader (auth: OptionalArgs<never>['authenticate'] = false): ReadonlyRecord<string, string> {
        let token: string | null = null

        if (typeof auth === 'string') {
            token = auth
        } else if (auth) {
            token = this.options.getToken?.() ?? null

            if (!token) {
                throw new ConnectwareError(ConnectwareErrorType.AUTHENTICATION, 'Token could not be found')
            }
        }

        return token === null ? {} : { Authorization: `Bearer ${token}` }
    }

    private createUrl (
        path: string,
        pathParams: OptionalArgs<never>['pathParams'] | null = null,
        queryParams: OptionalArgs<never>['queryParams'] | null = null
    ): URL {
        if (pathParams) {
            path = buildPath(path, ...pathParams)
        }

        const url = new URL(path, this.options.baseURL)

        if (queryParams) {
            /**
             * This is happening because URLSearchParams doesn't like when you have types
             * as described on their documentation
             */
            for (const [key, valueOrValues] of objectEntries(queryParams)) {
                const values = isArray(valueOrValues) ? valueOrValues : [valueOrValues]
                values.forEach((value) => url.searchParams.append(key, value))
            }
        }

        return url
    }

    /**
     * Should not yield a ConnectwareError unless there is a major exception
     * A response should always be yielded
     */
    protected abstract fetch (args: HttpFetchArgs): Promise<HttpResponse>

    protected async request<Path extends BackendPath, ReqBody extends HttpRequestBody, Return> ({
        method,
        handlers,
        ...args
    }: HttpRequestArgs<Path, ReqBody, Return>): Promise<Return> {
        try {
            const authenticationHeaders = this.createAuthenticationHeader(args.authenticate)
            const [body, bodyHeaders] = BaseConnectwareHTTPService.prepareContent(args.body)
            const url = this.createUrl(args.path, args.pathParams, args.queryParams).toString()

            const response = await this.fetch({ url, method, headers: { ...authenticationHeaders, ...bodyHeaders }, body })

            /** Notify the middleware */
            this.options.responseHandler?.trigger(response)

            const responseStatus = response.getStatus()
            const handler = handlers[responseStatus]

            /**
             * Default error should **not** happen in production scenarios
             */
            const result = handler
                ? await handler(response)
                : new ConnectwareError(ConnectwareErrorType.UNEXPECTED, 'Unexpected status code in response', {
                      url,
                      status: responseStatus,
                      supportedStatuses: objectKeys(handlers),
                      headers: response.getHeaders(),
                  })

            if (ConnectwareError.is(result)) {
                throw result
            }

            return result
        } catch (e: unknown) {
            let error: ConnectwareError

            if (ConnectwareError.is(e)) {
                error = e
            } else if (e instanceof Error) {
                /** Should **not** happen in production scenarios */
                error = new ConnectwareError(ConnectwareErrorType.UNEXPECTED, 'Unexpected error', { originalMessage: e.message })
            } else {
                /** Should **not** happen in production scenarios */
                error = new ConnectwareError(ConnectwareErrorType.UNEXPECTED, 'Unknown object thrown', { error: e })
            }

            // eslint-disable-next-line @typescript-eslint/unbound-method
            return throwCaptured(error, this.request)
        }
    }
}

import { MFAStatus } from '../../../../domain'
import type { CybusRole, UserCreationRequest, UserEditingRequest } from '../../../../domain'

import type { BackendDefinition } from '../../../Connectware'
import { mapToDefault } from './Commons'
import { mapAuthenticationMethodToGrantType } from './Grant'
import { mapPermissionRequest } from './Permission'

type UserPutRequest = BackendDefinition<'auth-server', 'UserPutRequest'>
type UserPostRequest = BackendDefinition<'auth-server', 'UserPostRequest'>

const mapRoles = (roles: CybusRole[]): string[] => roles.map(({ id }) => id)

const createBasicMapper =
    <P extends keyof (UserEditingRequest | UserCreationRequest | UserPostRequest | UserPutRequest)>(
        map: (value: (UserEditingRequest | UserCreationRequest)[P]) => (UserPostRequest | UserPutRequest)[P]
    ): typeof map =>
    (prop) =>
        map(prop)

const mapMqttPublishPrefix = createBasicMapper<'mqttPublishPrefix'>((v) => (v === null ? '' : mapToDefault(v, undefined)))
const mapPassword = createBasicMapper<'password'>((v) => mapToDefault(v, undefined))

export const mapUserEditingRequestToUserPutRequest = ({
    authenticationMethods,
    mfaStatus,
    username,
    permissions,
    roles,
    password,
    mqttPublishPrefix,
    isMfaEnforced,
}: Omit<UserEditingRequest, 'id'>): UserPutRequest => ({
    roles: roles && mapRoles(roles),
    password: mapPassword(password),
    mqttPublishPrefix: mapMqttPublishPrefix(mqttPublishPrefix),
    enforceMFAEnrollment: mapToDefault(isMfaEnforced, undefined),
    grantTypes: authenticationMethods?.map(mapAuthenticationMethodToGrantType),
    // Set "disableMfa" to true for the user if request.mfaStatus is DISABLED, otherwise keep MFA enabled if request.mfa is ENABLED
    disableMfa: mfaStatus && mfaStatus === MFAStatus.DISABLED,
    username,
    permissions: permissions?.map(mapPermissionRequest),
})

export const mapUserCreationRequestToUserPostRequest = ({
    username,
    permissions,
    authenticationMethods,
    roles,
    password,
    mqttPublishPrefix,
    isMfaEnforced,
}: UserCreationRequest): UserPostRequest => ({
    roles: mapRoles(roles),
    password: mapPassword(password),
    mqttPublishPrefix: mapMqttPublishPrefix(mqttPublishPrefix),
    enforceMFAEnrollment: isMfaEnforced === true,
    grantTypes: authenticationMethods.map(mapAuthenticationMethodToGrantType),
    username,
    initialPermissions: permissions.map(mapPermissionRequest),
    identityProvider: 'local',
    autoGenerated: false,
})

import React, { type FC, Fragment } from 'react'

import { Box, Checkbox, checkboxClasses, FormControlLabel, formControlLabelClasses, type SxProps, type Theme } from '@mui/material'
import { TreeItem, TreeView } from '@mui/lab'
import { ChevronRight, Delete, ExpandMore } from '@mui/icons-material'

import { isArray, isArrayNotEmpty } from '../../../../utils'
import { areTopicTreesEquals, selectConfigurationLoadError, selectExplorerTopicTrees, type TopicTree, TopicType, Translation } from '../../../../domain'

import { createHandlerWithoutPropagation } from '../..'
import { useAppState, useAppUsecase } from '../../State'
import { FormattedTranslation } from '../../Internationalization'
import { ErrorMessage } from '../../ErrorMessage'
import { AnchorProvider } from '../../common'
import { usePagination } from '../Pagination'
import { ResourcesPopover } from './ResourcesInfo'
import { ErrorInfoPopover } from './ErrorInfo'

/**
 * Due to rendering processing issues
 * We limit how many items we display on the popover
 */
const MAX_RESOURCES_ON_POPOVER = 10

/**
 * This deals with topics with a name that is too long
 */
const wrapperStyle: SxProps<Theme> = { width: '100%', display: 'flex' }
const labelStyle: SxProps<Theme> = {
    minWidth: 'calc(100% - 54px)',
    maxWidth: 0,
    [`& > .${checkboxClasses.root}`]: { py: 0 },
    [`& > .${formControlLabelClasses.label}`]: {
        textOverflow: 'ellipsis',
        overflowX: 'hidden',
        whiteSpace: 'nowrap',
        fontSize: 'body2.fontSize',
    },
}
const actionsWrapperStyle: SxProps<Theme> = { width: '100%' }
const actionsStyle: SxProps<Theme> = { display: 'flex', cursor: 'pointer', justifyContent: 'right' }
const errorMessageStyle: SxProps<Theme> = { mt: 2 }

const stopPropagation = createHandlerWithoutPropagation()

const Topic: FC<{ tree: TopicTree }> = ({ tree }) => {
    const manage = useAppUsecase('explorerTopicManagementUsecase')

    const [page, button] = usePagination(tree.children)

    const children = page.map((child, k) => <Topic key={k} tree={child} />)
    if (button) {
        children.push(<Fragment key="more">{button}</Fragment>)
    }

    const path = tree.path.join('/')
    const label = tree.label.join('/')

    return (
        <TreeItem
            data-testid={path}
            nodeId={path}
            title={label}
            label={
                <Box sx={wrapperStyle}>
                    <FormControlLabel
                        sx={labelStyle}
                        title={label}
                        control={
                            <Checkbox
                                title={label}
                                checked={Boolean(tree.selected)}
                                indeterminate={tree.selected === null}
                                onChange={() => manage.toggleTopic(tree.path)}
                                onClick={stopPropagation}
                            />
                        }
                        label={label}
                    />
                    <Box sx={actionsWrapperStyle}>
                        <Box sx={actionsStyle}>
                            {tree.subscriptionErrors.size > 0 && <ErrorInfoPopover tree={tree} />}

                            {tree.source === TopicType.CUSTOM && (
                                <Delete
                                    color="action"
                                    className="remove-custom-topic-icon"
                                    fontSize="medium"
                                    onClick={createHandlerWithoutPropagation(() => manage.removeTopic(tree.path))}
                                />
                            )}

                            {isArray(tree.source) && tree.source.length <= MAX_RESOURCES_ON_POPOVER && (
                                <AnchorProvider>
                                    <ResourcesPopover source={tree.source} />
                                </AnchorProvider>
                            )}
                        </Box>
                    </Box>
                </Box>
            }
        >
            {children}
        </TreeItem>
    )
}

export const TopicsTree: FC = () => {
    const tree = useAppState(selectExplorerTopicTrees, areTopicTreesEquals)
    const items = tree.map((tree, k) => <Topic key={k} tree={tree} />)
    const configuredTopicError = useAppState(selectConfigurationLoadError)

    return (
        <TreeView
            id="availableTopicsTree"
            defaultCollapseIcon={<ExpandMore className="collappseTree" />}
            defaultExpandIcon={<ChevronRight className="expandTree" />}
        >
            {isArrayNotEmpty(items) && items}
            {!isArrayNotEmpty(items) && !configuredTopicError && <FormattedTranslation id={Translation.NO_TOPICS_AVAILABLE} />}
            {configuredTopicError && (
                <ErrorMessage data-testid="configured-topic-error" sx={errorMessageStyle} titleVariant="subtitle1" error={configuredTopicError} />
            )}
        </TreeView>
    )
}

/* eslint-disable typescript-sort-keys/string-enum */
import { type ExcludeValues, isArray, type ReadonlyRecord } from '../utils'
import {
    type AuthenticationInformation,
    type Capability,
    ConnectwareError,
    ConnectwareErrorType,
    createAreAllCapabilitiesIncludedChecker,
    isOtpValid,
    selectAuthenticationToken,
    type ValidOtp,
} from '.'
import type { Loadable } from './Loadable'

export type MfaActivationData = Readonly<{ uri: URL, secret: string }>
export enum MfaSettingsState {
    LOADING = 'LOADING',
    DISABLED = 'DISABLED',
    DISABLING = 'DISABLING',
    ENABLING = 'ENABLING',
    ENABLED = 'ENABLED',
    REGENERATING_BACKUP_CODES = 'REGENERATING_BACKUP_CODES',
    REGENERATED_BACKUP_CODES = 'REGENERATED_BACKUP_CODES',
}

export enum MFAStatus {
    ENABLED = 'ENABLED',
    DISABLED = 'DISABLED',
    NOT_APPLICABLE = 'NOT_APPLICABLE',
    NOT_SETUP = 'NOT_SETUP',
}

export type MfaUpdateCredentials = Readonly<{ otp: ValidOtp, backupCode: null } | { otp: null, backupCode: string }>

export class MfaRequiredError extends ConnectwareError<ConnectwareErrorType.AUTHENTICATION, Readonly<{ secret: string }>> {
    static override is (e: unknown): e is MfaRequiredError {
        return e instanceof MfaRequiredError
    }

    constructor (secret: string) {
        super(ConnectwareErrorType.AUTHENTICATION, 'Multi-factor authentication required', { secret })
    }

    get secret (): string {
        return this.extras.secret
    }
}

export class MfaSetupRequiredError extends ConnectwareError<ConnectwareErrorType.AUTHENTICATION, MfaRequiredAuthentication> {
    static override is (e: unknown): e is MfaSetupRequiredError {
        return e instanceof MfaSetupRequiredError
    }

    constructor (authentication: MfaRequiredAuthentication) {
        super(ConnectwareErrorType.AUTHENTICATION, 'Multi-factor authentication setup required', authentication)
    }
}

type MfaActionCredentials = Readonly<{ otp: string[], backupCode: null } | { otp: null, backupCode: string }>

type StatefulMfaSettings<S> = Readonly<{ state: S }>

type BaseMfaSettings<S, T = ReadonlyRecord<never, never>> = StatefulMfaSettings<S> & Readonly<T>

/** Before anything loads */
type LoadingMfaSettings = BaseMfaSettings<MfaSettingsState.LOADING, { error: ConnectwareError | null }>

/** Shows if mfa is disabled for the user */
type DisabledMfaSettings = BaseMfaSettings<MfaSettingsState.DISABLED>

/** Then it starts enabling, with an activation flow that still needs to be loaded, and otp to be provided by the user */
type EnablingMfaSettings = BaseMfaSettings<MfaSettingsState.ENABLING, { activation: Loadable<MfaActivationData>, otp: string[], error: Loadable }>

/** Once enabled, it can either have backup codes (in case it was just enabling) */
type EnabledMfaSettings = BaseMfaSettings<MfaSettingsState.ENABLED, { backupCodes: string[] | null }>

/** From enabled, user can disable their mfa setup */
type DisablingMfaSettings = BaseMfaSettings<MfaSettingsState.DISABLING, { error: Loadable } & MfaActionCredentials>

/** From enabled, user can regenerate their backup codes (which load from the backend) but has to provide either otp or backup code */
type RegeneratingBackupCodesMfaSettings = BaseMfaSettings<MfaSettingsState.REGENERATING_BACKUP_CODES, { error: Loadable } & MfaActionCredentials>

/** Once regenerated, goes to this state */
type RegeneratedBackupCodesMfaSettings = BaseMfaSettings<MfaSettingsState.REGENERATED_BACKUP_CODES, { backupCodes: string[] }>

type AllMfaSettings =
    | LoadingMfaSettings
    | DisabledMfaSettings
    | EnablingMfaSettings
    | EnabledMfaSettings
    | DisablingMfaSettings
    | RegeneratingBackupCodesMfaSettings
    | RegeneratedBackupCodesMfaSettings

export type MfaSettings<S extends MfaSettingsState = MfaSettingsState> = Extract<AllMfaSettings, StatefulMfaSettings<S>>

const isMfaSettingsOnState = <S extends MfaSettingsState>(settings: MfaSettings, ...states: S[]): settings is MfaSettings<S> =>
    states.includes(settings.state as S)

export const isAuthenticatingWithOtp = (
    c: Pick<Extract<MfaSettings, MfaActionCredentials>, keyof MfaActionCredentials>
): c is MfaActionCredentials & Pick<ExcludeValues<MfaActionCredentials, null>, 'otp'> => isArray(c.otp)

export const selectMFaState = <S extends MfaSettingsState>(s: MfaAppState, ...states: S[]): MfaSettings<S> | null => {
    const settings = selectMfa(s)
    return settings && isMfaSettingsOnState(settings, ...states) ? settings : null
}

export const selectMfaUpdateCredentials = (
    settings: Pick<Extract<MfaSettings, MfaActionCredentials>, keyof MfaActionCredentials>
): MfaUpdateCredentials | null => {
    const usingOtp = isAuthenticatingWithOtp(settings)

    const input: MfaUpdateCredentials | null =
        // Try otp flow
        (usingOtp && isOtpValid(settings.otp) && { otp: settings.otp, backupCode: null }) ||
        // Try backup flow
        (!usingOtp && settings.backupCode && { otp: null, backupCode: settings.backupCode }) ||
        // Give up
        null

    return input
}

export type MfaRequiredAuthentication = Pick<AuthenticationInformation, 'token' | 'capabilities' | 'expiresAt'>
export type MfaAppState = Readonly<{ mfa: MfaSettings | null, mfaRequiredAuthentication: MfaRequiredAuthentication | null }>

export const selectMfa = (s: MfaAppState): MfaAppState['mfa'] => s.mfa
export const mapMfaState = (s: MfaAppState['mfa']): Partial<MfaAppState> => ({ mfa: s })

export const selectMfaRequiredAuthentication = (s: MfaAppState): MfaAppState['mfaRequiredAuthentication'] => s.mfaRequiredAuthentication
export const mapMfaRequiredAuthenticationState = (s: MfaAppState['mfaRequiredAuthentication']): Partial<MfaAppState> => ({ mfaRequiredAuthentication: s })

export const selectDoesRequiresMfa = (s: MfaAppState): boolean => Boolean(selectMfaRequiredAuthentication(s))

const createMfaRequiredAuthenticationSelector =
    <T>(selector: (authentication: MfaRequiredAuthentication) => T): ((s: MfaAppState) => T | null) =>
    (s: MfaAppState): T | null => {
        const auth = selectMfaRequiredAuthentication(s)
        return auth ? selector(auth) : null
    }

export const selectMfaRequiredAuthenticationToken = createMfaRequiredAuthenticationSelector(selectAuthenticationToken)

const selectChecker = createMfaRequiredAuthenticationSelector(createAreAllCapabilitiesIncludedChecker)
export const createIsAuthenticatedForRequiredMfaWithCapabilitiesSelector =
    (...requiredPermissions: Capability[]): ((s: MfaAppState) => boolean) =>
    (s) =>
        Boolean(selectChecker(s)?.(requiredPermissions))

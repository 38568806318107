import type { SubscriptionsTypes } from '../../../../../../application'

import { CybusServiceDeviationMapper, SERVICE_AGENT } from '../../../../../Connectware'
import { SERVICE_CLASSNAME } from '../../../../constants'
import type { ServiceProxy } from '../../../../proxies'
import { createProxyEventsHandler, SubscriptionHandlerType, type VrpcHandlerMappingPropertiesArgs, type VrpcInstanceToListSubscriptionHandler } from '..'

type DeviationsHandler = VrpcInstanceToListSubscriptionHandler<ServiceProxy, SubscriptionsTypes['serviceDeviations']>
type DeviationsHandlerArgs = VrpcHandlerMappingPropertiesArgs<DeviationsHandler>

export class VrpcServiceProxyInstanceToNotificationHandler implements DeviationsHandler {
    readonly type = SubscriptionHandlerType.INSTANCE_ONE_TO_LIST

    readonly optionalFilters = []

    readonly requiredFilters = []

    readonly classNameFilter = SERVICE_CLASSNAME

    readonly agent = SERVICE_AGENT

    readonly ignoreInstances = null

    readonly ignoreInstanceByFilter = null

    readonly sourceInstanceName = null

    readonly onChange = createProxyEventsHandler<ServiceProxy>('deviation', 'state')

    // eslint-disable-next-line class-methods-use-this
    mapToDomain ({ instance }: DeviationsHandlerArgs['DomainMapperArgs']): Promise<DeviationsHandlerArgs['Domain']> {
        return instance.getInfo().then(({ id, isDeviated }) => {
            const mapper = new CybusServiceDeviationMapper()
            mapper.push({ id, isDeviated })
            return mapper.values
        })
    }
}

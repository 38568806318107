import type { NonEmptyArray, ReadonlyRecord } from '../../utils'

import type { RuleEngineEndpoint, TopicConfiguration } from '../../domain'

export enum DocumentationType {
    CYBUS_DOCS = 'CYBUS_DOCS',
    CYBUS_LEARN = 'CYBUS_LEARN',
    LICENSE_OFFLINE_ACTIVATION = 'LICENSE_OFFLINE_ACTIVATION',
    LICENSING = 'LICENSING',
    MQTT_PREFIX = 'MQTT_PREFIX',
    RULE_ENGINE_PARSE_RULE = 'RULE_ENGINE_PARSE_RULE',
    SERVICES = 'SERVICES',
    SERVICES_COMMISSIONING_FILE = 'SERVICES_COMMISSIONING_FILE',
    UI_ACCESS_PERMISSIONS = 'UI_ACCESS_PERMISSIONS',
}

export interface ConfigurationService {
    getResourcesPaginationSizes(): [short: number, medium: number, large: number]
    /**
     * How often then client registry should be updated in milliseconds
     * @see https://developer.mozilla.org/en-US/docs/Web/API/setInterval#sect1
     */
    getClientRegistryRefreshRate(): number
    getApplicationMetadata(): ReadonlyRecord<string, string>
    getDocumentationURL(type: DocumentationType): URL
    getWorkbenchURL(): URL

    /**
     * @returns A list of file type extensions
     * @example ['yml', 'yaml', 'json']
     */
    getSupportedServiceCommissioningFileTypes(): NonEmptyArray<string>
    getSupportedLicenseFileTypes(): NonEmptyArray<string>
    getCertificateFileTypes(): NonEmptyArray<string>
    getSupportedRestoreBackupFileExtensions(): NonEmptyArray<string>

    getServiceCommissioningFileType(): string
    getServiceCommissioningFileEncodingType(): string

    fetchTopicsConfiguration(): Promise<TopicConfiguration[]>
    fetchRuleEngineEndpointsConfiguration(): Promise<RuleEngineEndpoint[]>

    getSalesEmail(): string

    getSupportEmail(): string

    /**
     * In Milliseconds, needs to be less than a 32bit int value
     * @see https://developer.mozilla.org/en-US/docs/Web/API/setInterval#sect1
     */
    getInfoPageRefreshRate(): number

    getUserManagementDelay(): number
}

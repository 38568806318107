import type { CybusDetailedService } from '../../../../../../domain'

import { mapDetailedService, SERVICE_AGENT } from '../../../../../Connectware'
import { SERVICE_CLASSNAME } from '../../../../constants'
import type { ServiceProxy } from '../../../../proxies'
import { createProxyEventsHandler, SubscriptionHandlerType, type VrpcHandlerMappingPropertiesArgs, type VrpcInstanceToOneSubscriptionHandler } from '..'

type DetailedServiceHandler = VrpcInstanceToOneSubscriptionHandler<ServiceProxy, CybusDetailedService>
type DetailedServiceHandlerArgs = VrpcHandlerMappingPropertiesArgs<DetailedServiceHandler>

export class VrpcDetailedServiceProxyInstanceHandler implements DetailedServiceHandler {
    readonly type = SubscriptionHandlerType.INSTANCE_ONE_TO_ONE

    readonly optionalFilters = []

    readonly requiredFilters = []

    readonly classNameFilter = SERVICE_CLASSNAME

    readonly agent = SERVICE_AGENT

    readonly ignoreInstances = null

    readonly ignoreInstanceByFilter = null

    readonly sourceInstanceName = null

    readonly onChange = createProxyEventsHandler<ServiceProxy>('state', 'deviation')

    mapToDomain ({ instance }: DetailedServiceHandlerArgs['DomainMapperArgs']): Promise<CybusDetailedService> {
        return Promise.all([instance.getParams(), instance.getDependencies()]).then(([params, dependencies]) => mapDetailedService(params, dependencies))
    }
}

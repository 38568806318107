import { isArray } from '../utils'
import type { ConnectwareError } from './Error'

/**
 * This represents a validation result
 * It can be:
 *  - `null` if it is on the initial state
 *  - `ConnectwareError` if there was an issue with the validation
 *  - `(ConnectwareError | string)[]` if the validation was successful, with ConnectwareError noting an invalid field
 * @see {ConnectwareError}
 */
export type FieldValidation = (ConnectwareError | string)[] | ConnectwareError | null

export const isFieldValidated = (validation: FieldValidation): validation is ConnectwareError =>
    isArray(validation) && validation.every((v) => typeof v === 'string')

import { areCybusServersEquals, Capability, type CybusServer } from '../../../../../../domain'
import type { SubscriptionFilterArgs } from '../../../../../../application'

import { FilteredAsyncMapper, mapCybusServer, type ServerProxyParams } from '../../../../../Connectware'
import { BaseSubscriptionStrategy } from '../Base'

class ServersMapper extends FilteredAsyncMapper<ServerProxyParams, CybusServer> {
    protected override map (server: ServerProxyParams): Promise<CybusServer> | null {
        const { service } = this.filter

        const cybusServer = mapCybusServer(server)

        if (service && service !== cybusServer.service) {
            return null
        }

        return Promise.resolve(cybusServer)
    }
}

export class ServerStrategy extends BaseSubscriptionStrategy<'servers' | 'server'> {
    readonly requiredPermissions = [Capability.SERVERS_READ, Capability.SERVER_READ]

    protected override readonly supportedFilters: (keyof SubscriptionFilterArgs)[] = ['service']

    protected override readonly areEquals = areCybusServersEquals

    override async retrieveAll ({ service }: SubscriptionFilterArgs): Promise<CybusServer[]> {
        return this.retrieve({
            capability: Capability.SERVERS_READ,
            method: 'GET',
            path: '/api/servers',
            mapper: (data: ServerProxyParams[]) => {
                const mapper = new ServersMapper({ service })

                for (const server of data) {
                    mapper.push(server)
                }

                return mapper.values
            },
        })
    }

    override retrieveOne (id: string): Promise<CybusServer> {
        return this.retrieve({
            capability: Capability.SERVER_READ,
            method: 'GET',
            path: '/api/servers/+',
            pathParams: [id],
            mapper: (data: ServerProxyParams) => mapCybusServer(data),
            handleNotFound: true,
        })
    }
}

import {
    areArrayEquals,
    areRecordsEquals,
    type Comparators,
    createArrayComparator,
    createEqualityChecker,
    createNamedEqualityChecker,
    isArray,
    type ReadonlyRecord,
} from '../../utils'
import { type CybusRuleEngineExecutionError, StatusType, type TopicSubscriptionError } from '..'

type BaseCybusMapping = Readonly<{ id: string, status: StatusType, service: string | null, name: string, agent: string | null }>

export enum CybusPubSubType {
    ENDPOINT = 'ENDPOINT',
    TOPIC = 'TOPIC',
}

export type CybusPubSub = Readonly<{
    type: CybusPubSubType
    id: string
    topics: string[]
    externalBroker: string | null
    /** If subscribed to, and issues occur, this set will have items on it */
    subscriptionErrors: ReadonlySet<TopicSubscriptionError>
}>

export type CybusMappingDetailedEntry = Readonly<{
    subscribe: CybusPubSub[]
    publish: CybusPubSub
    rules: ReadonlyRecord<string, unknown>[] | null
    executionError: CybusRuleEngineExecutionError | null
}>

export type CybusMapping = Readonly<{ entriesCount: number }> & BaseCybusMapping
export type CybusDetailedMapping = Readonly<{ entries: CybusMappingDetailedEntry[] }> & BaseCybusMapping

export const canMappingBeDeleted = (service: Pick<BaseCybusMapping, 'status'>): boolean => service.status === StatusType.DISABLED
export const canMappingBeEnabled = (service: Pick<BaseCybusMapping, 'status'>): boolean => service.status === StatusType.DISABLED
export const canMappingBeDisabled = (service: Pick<BaseCybusMapping, 'status'>): boolean => service.status === StatusType.ENABLED

const areCybusPubSubsEquals = createEqualityChecker<CybusPubSub>({
    type: null,
    id: null,
    externalBroker: null,
    topics: createArrayComparator(),
    subscriptionErrors: (a, b) => areArrayEquals(Array.from(a), Array.from(b), { sort: false }),
})

const areCybusMappingEntriesEquals = createEqualityChecker<CybusMappingDetailedEntry>({
    subscribe: createArrayComparator(areCybusPubSubsEquals),
    publish: areCybusPubSubsEquals,
    rules: (a, b) => isArray(a) && isArray(b) && areArrayEquals(a, b, { sort: false, equals: areRecordsEquals }),
    executionError: null,
})

const baseCybusMappingComparators: Comparators<BaseCybusMapping> = { id: null, name: null, service: null, status: null, agent: null }

export const areCybusMappingEquals = createNamedEqualityChecker<CybusMapping>({ ...baseCybusMappingComparators, entriesCount: null }, 'areCybusMappingEquals')

export const areDetailedCybusMappingEquals = createNamedEqualityChecker<CybusDetailedMapping>(
    { ...baseCybusMappingComparators, entries: createArrayComparator(areCybusMappingEntriesEquals) },
    'areDetailedCybusMappingEquals'
)

import {
    areArrayEquals,
    areRecordsEquals,
    type Comparators,
    createArrayComparator,
    createNamedEqualityChecker,
    isArray,
    type ReadonlyRecord,
} from '../../utils'
import { StatusType } from '../Status'

type BaseCybusEndpoint = Readonly<{
    id: string
    name: string
    service: string | null
    protocol: string
    operation: string
    agent: string | null
    status: StatusType
}>

export type CybusEndpointAddress = ReadonlyRecord<string, string | number | boolean | ReadonlyRecord<string, string>>

export type CybusEndpoint = Readonly<{ connection: string | null }> & BaseCybusEndpoint

export type CybusDetailedEndpoint = Readonly<{ address: CybusEndpointAddress, rules: unknown[] | null, topics: string[] }> & BaseCybusEndpoint

export const canEndpointBeEnabled = (endpoint: Pick<CybusEndpoint, 'status'>): boolean => endpoint.status === StatusType.DISABLED
export const canEndpointBeDisabled = (endpoint: Pick<CybusEndpoint, 'status'>): boolean => endpoint.status === StatusType.ENABLED

const baseEndpointComparators: Comparators<BaseCybusEndpoint> = {
    id: null,
    name: null,
    service: null,
    protocol: null,
    operation: null,
    agent: null,
    status: null,
}

export const areCybusEndpointEquals = createNamedEqualityChecker<CybusEndpoint>({ ...baseEndpointComparators, connection: null }, 'areCybusEndpointEquals')

export const areDetailedCybusEndpointEquals = createNamedEqualityChecker<CybusDetailedEndpoint>(
    {
        ...baseEndpointComparators,
        address: areRecordsEquals,
        rules: (a, b) => isArray(a) && isArray(b) && areArrayEquals(a, b, { sort: false }),
        topics: createArrayComparator(),
    },
    'areDetailedCybusEndpointEquals'
)

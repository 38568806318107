import { isArray } from '../../../utils'
import {
    ConnectwareError,
    ConnectwareErrorType,
    isAuthenticatingWithOtp,
    type MfaSettings,
    MfaSettingsState,
    type MfaUpdateCredentials,
    selectMfaUpdateCredentials,
} from '../../../domain'

import { initialOtp } from '../..'
import { MfaUsecase } from './Base'

/** Enabled Stable states */
const stableStates = [MfaSettingsState.ENABLED, MfaSettingsState.REGENERATED_BACKUP_CODES] as const

/** The states this usecase will work with */
type StatesDerivedFromEnabledStableStates = MfaSettings<MfaSettingsState.DISABLING | MfaSettingsState.REGENERATING_BACKUP_CODES>

/**
 * Generic usecase made to perform changes on stable and enabled mfa states
 */
export abstract class ChangeEnabledMfaUsecase<Settings extends StatesDerivedFromEnabledStableStates = StatesDerivedFromEnabledStableStates> extends MfaUsecase {
    protected abstract readonly initialSettings: Settings

    /**
     * Function that will be called with the validated updating credentials
     * Should yield the full output state or an error
     *
     * @throws `ConnectwareError`
     * @see {ConnectwareError}
     */
    protected abstract execute (credentials: MfaUpdateCredentials): Promise<MfaSettings>

    start (): void {
        /** Asseting that is currently enabled */
        this.getMFaSettings(...stableStates)

        /** Start process */
        this.setMfa(this.initialSettings)
    }

    cancel (): void {
        this.setMfa({ state: MfaSettingsState.ENABLED, backupCodes: null })
    }

    /**
     * Toggle between using otp or backup codes
     */
    toggle (): void {
        const settings = this.getMFaSettings(this.initialSettings.state)

        this.updateMfaSettings({
            state: this.initialSettings.state,
            ...(isAuthenticatingWithOtp(settings) ? { otp: null, backupCode: '' } : { otp: initialOtp, backupCode: null }),
        })
    }

    /**
     * Update the current input, that being otp or backup code
     */
    updateInput (update: string | string[]): void {
        const settings = this.getMFaSettings(this.initialSettings.state)

        const isUsingOtp = isAuthenticatingWithOtp(settings)
        const arrayness = isArray(update)

        const settingsUpdate = (isUsingOtp && arrayness && { otp: update }) || (!isUsingOtp && !arrayness && { backupCode: update })

        if (!settingsUpdate) {
            throw new ConnectwareError(ConnectwareErrorType.STATE, 'Invalid Update input', { settings, update })
        }

        this.updateMfaSettings({ state: this.initialSettings.state, ...settingsUpdate })
    }

    /** Does **not** throw errors in expected conditions */
    async confirm (): Promise<void> {
        const settings = this.getMFaSettings(this.initialSettings.state)

        try {
            const input = selectMfaUpdateCredentials(settings)

            if (!input) {
                throw new ConnectwareError(ConnectwareErrorType.STATE, 'Invalid state for confirmation', settings)
            }

            /** Reset any possible codes errors */
            this.updateMfaSettings({ state: this.initialSettings.state, error: null })

            /** Either perform action and move user to next state or show error */
            this.setMfa(await this.execute(input))
        } catch (e: unknown) {
            this.updateMfaSettings({ state: this.initialSettings.state, error: e as ConnectwareError })
        }
    }
}

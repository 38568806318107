import type { StatusType } from '../../../../../../domain'

import { mapToStatusType } from '../../../../../Connectware'
import { ENDPOINT_CLASSNAME_FILTER, MAPPING_CLASSNAME_FILTER, NODE_CLASSNAME_FILTER } from '../../../../constants'
import type { EndpointProxy, MappingProxy, NodeProxy } from '../../../../proxies'
import { createProxyEventsHandler, SubscriptionHandlerType, type VrpcHandlerMappingPropertiesArgs, type VrpcInstanceToOneSubscriptionHandler } from '..'

type SupportedProxy = MappingProxy | EndpointProxy | NodeProxy
type StatusHandler<Proxy extends SupportedProxy> = VrpcInstanceToOneSubscriptionHandler<Proxy, StatusType>
type StatusHandlerArgs<Proxy extends SupportedProxy> = VrpcHandlerMappingPropertiesArgs<StatusHandler<Proxy>>

abstract class VrpcProxyInstanceStatusHandler<Proxy extends SupportedProxy> implements StatusHandler<Proxy> {
    readonly type = SubscriptionHandlerType.INSTANCE_ONE_TO_ONE

    readonly optionalFilters = []

    readonly requiredFilters = []

    readonly ignoreInstances = null

    readonly ignoreInstanceByFilter = null

    readonly sourceInstanceName = null

    readonly agent = null

    readonly onChange = createProxyEventsHandler<SupportedProxy>('state')

    constructor (readonly classNameFilter: StatusHandler<Proxy>['classNameFilter']) {}

    mapToDomain ({ instance }: StatusHandlerArgs<Proxy>['DomainMapperArgs']): Promise<StatusHandlerArgs<Proxy>['Domain']> {
        return instance.getState().then(mapToStatusType)
    }
}

export class VrpcMappingProxyInstanceStatusHandler extends VrpcProxyInstanceStatusHandler<MappingProxy> {
    constructor () {
        super(MAPPING_CLASSNAME_FILTER)
    }
}

export class VrpcEndpointProxyInstanceStatusHandler extends VrpcProxyInstanceStatusHandler<EndpointProxy> {
    constructor () {
        super(ENDPOINT_CLASSNAME_FILTER)
    }
}

export class VrpcNodeProxyInstanceStatusHandler extends VrpcProxyInstanceStatusHandler<NodeProxy> {
    constructor () {
        super(NODE_CLASSNAME_FILTER)
    }
}

/* eslint-disable typescript-sort-keys/string-enum */

export enum Capability {
    /**
     * @todo this is deprecated and only here to support legacy checks
     * Once technologies that work an all-or-nothing authentication system are removed
     * This also can be removed
     * @deprecated
     */
    LEGACY_ADMIN_PERMISSION = 'LEGACY_ADMIN_PERMISSION',
    /**
     * Resources
     */
    AGENTS_MANAGE = 'AGENTS_MANAGE',
    AGENTS_READ = 'AGENTS_READ',

    CONNECTION_READ = 'CONNECTION_READ',
    CONNECTIONS_MANAGE = 'CONNECTIONS_MANAGE',
    CONNECTIONS_READ = 'CONNECTIONS_READ',

    CORE_CONTAINER_READ = 'CORE_CONTAINER_READ',
    CORE_CONTAINERS_MANAGE = 'CORE_CONTAINERS_MANAGE',
    CORE_CONTAINERS_READ = 'CORE_CONTAINERS_READ',

    SERVICE_CONTAINER_READ = 'SERVICE_CONTAINER_READ',
    SERVICE_CONTAINERS_MANAGE = 'SERVICE_CONTAINERS_MANAGE',
    SERVICE_CONTAINERS_READ = 'SERVICE_CONTAINERS_READ',

    ENDPOINT_READ = 'ENDPOINT_READ',
    ENDPOINT_STATE_READ = 'ENDPOINT_STATE_READ',
    ENDPOINTS_MANAGE = 'ENDPOINTS_MANAGE',
    ENDPOINTS_READ = 'ENDPOINTS_READ',

    MAPPING_READ = 'MAPPING_READ',
    MAPPING_STATE_READ = 'MAPPING_STATE_READ',
    MAPPINGS_MANAGE = 'MAPPINGS_MANAGE',
    MAPPINGS_READ = 'MAPPINGS_READ',

    NODE_STATE_READ = 'NODE_STATE_READ',
    NODES_READ = 'NODES_READ',

    SERVER_READ = 'SERVER_READ',
    SERVERS_MANAGE = 'SERVERS_MANAGE',
    SERVERS_READ = 'SERVERS_READ',

    SERVICE_READ = 'SERVICE_READ',
    SERVICES_MANAGE = 'SERVICES_MANAGE',
    SERVICES_CREATE_OR_UPDATE = 'SERVICES_CREATE_OR_UPDATE',
    SERVICE_TEMPLATE_EDIT = 'SERVICE_TEMPLATE_EDIT',
    SERVICES_READ = 'SERVICES_READ',
    SERVICES_CATALOG_READ = 'SERVICES_CATALOG_READ',
    SERVICE_DEVIATIONS_READ = 'SERVICE_DEVIATIONS_READ',
    SERVICE_SUBSCRIPTION_METADATA = 'SERVICE_SUBSCRIPTION_METADATA',

    VOLUME_READ = 'VOLUME_READ',
    VOLUMES_MANAGE = 'VOLUMES_MANAGE',
    VOLUMES_READ = 'VOLUMES_READ',

    /**
     * User management
     */
    MANAGE_USERS = 'MANAGE_USERS',
    MANAGE_ROLES = 'MANAGE_ROLES',
    MANAGE_PERMISSIONS = 'MANAGE_PERMISSIONS',
    CLIENT_REGISTRY_MANAGE = 'CLIENT_REGISTRY_MANAGE',
    CERTIFICATES_MANAGE = 'CERTIFICATES_MANAGE',
    USER_MANAGEMENT_CONFIGURATION = 'USER_MANAGEMENT_CONFIGURATION',

    /**
     * Others
     */
    TOPICS_SUBSCRIPTION_METADATA = 'TOPICS_SUBSCRIPTION_METADATA',
    TOPICS_SUBSCRIPTION = 'TOPICS_SUBSCRIPTION',
    WORKBENCH = 'WORKBENCH',
    RULE_ENGINE_SANDBOX_USE = 'RULE_ENGINE_SANDBOX_USE',
    LOGS_READ = 'LOGS_READ',
    MINIMUM = 'MINIMUM',
    USE_MFA = 'USE_MFA',

    /**
     * System
     */
    SYSTEM_LICENSE_MANAGE = 'SYSTEM_LICENSE_MANAGE',
    SYSTEM_METRICS_MANAGE = 'SYSTEM_METRICS_MANAGE',
    SYSTEM_METRICS_READ = 'SYSTEM_METRICS_READ',
    SYSTEM_BACKUP_MANAGE = 'SYSTEM_BACKUP_MANAGE',

    /** Change password */
    MANAGE_OWN_PASSWORD = 'MANAGE_OWN_PASSWORD',

    /** password policy */
    RETRIEVE_PASSWORD_POLICY = 'RETRIEVE_PASSWORD_POLICY',
}

import { isArray, objectEntries, type ReadonlyRecord } from '../../../utils'
import { type Certificate, type ConnectwareError, selectCertificates } from '../../../domain'

import { useAppState } from '../State'

export const useCertificatesByOrganization = (): [string, Certificate[]][] | ConnectwareError | null => {
    const certificates = useAppState(selectCertificates)

    if (!isArray(certificates)) {
        return certificates
    }

    const byOrganization = certificates.reduce<ReadonlyRecord<string, Certificate[]>>(
        (r, certificate) => ({ ...r, [certificate.issuedBy.organization]: [...(r[certificate.issuedBy.organization] || []), certificate] }),
        {}
    )

    return objectEntries(byOrganization).sort(([a], [b]) => a.localeCompare(b))
}

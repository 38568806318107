import type { CybusNode } from '../../../../../../domain'

import { CybusNodeMapper } from '../../../../../Connectware'
import { NODE_CLASSNAME_FILTER } from '../../../../constants'
import type { NodeProxy } from '../../../../proxies'
import { createProxyEventsHandler, SubscriptionHandlerType, type VrpcHandlerMappingPropertiesArgs, type VrpcInstanceToListSubscriptionHandler } from '..'

type NodesHandler = VrpcInstanceToListSubscriptionHandler<NodeProxy, CybusNode>
type NodesHandlerArgs = VrpcHandlerMappingPropertiesArgs<NodesHandler>

export class VrpcNodeProxyToCybusNodeHandler implements NodesHandler {
    readonly type = SubscriptionHandlerType.INSTANCE_ONE_TO_LIST

    readonly optionalFilters = ['server' as const]

    readonly requiredFilters = ['server' as const]

    readonly classNameFilter = NODE_CLASSNAME_FILTER

    readonly ignoreInstances = null

    readonly ignoreInstanceByFilter = null

    readonly sourceInstanceName = null

    readonly agent = null

    readonly onChange = createProxyEventsHandler<NodeProxy>('state')

    // eslint-disable-next-line class-methods-use-this
    mapToDomain ({ instance, filter }: NodesHandlerArgs['DomainMapperArgs']): Promise<NodesHandlerArgs['Domain']> {
        return instance.getParams().then((params) => {
            const mapper = new CybusNodeMapper(filter)
            mapper.push(params)
            return mapper.values
        })
    }
}

import { areCybusServiceDeviationsEquals, Capability, type CybusServiceDeviation } from '../../../../../../domain'

import { CybusServiceDeviationMapper, type CybusServiceProxyInfo } from '../../../../../Connectware'
import { BaseSubscriptionStrategy } from '../Base'

export class DeviationsStrategy extends BaseSubscriptionStrategy<'serviceDeviations'> {
    readonly requiredPermissions = [Capability.SERVICE_DEVIATIONS_READ]

    protected override readonly areEquals = areCybusServiceDeviationsEquals

    override async retrieveAll (): Promise<CybusServiceDeviation[]> {
        return this.retrieve({
            capability: Capability.SERVICE_DEVIATIONS_READ,
            method: 'GET',
            path: '/api/services/info',
            mapper: (data: CybusServiceProxyInfo[]) => {
                const mapper = new CybusServiceDeviationMapper()

                for (const { id, isDeviated } of data) {
                    mapper.push({ id, isDeviated })
                }

                return mapper.values
            },
        })
    }
}

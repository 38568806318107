import { objectEntries } from '../../../../../../utils'
import type { CybusAgent } from '../../../../../../domain'

import { AgentsMapper } from '../../../../../Connectware'
import { listenToAgentsChange, VrpcDomainType } from '../../../../utils'
import { SubscriptionHandlerType, type VrpcHandlerMappingPropertiesArgs, type VrpcRemoteToListSubscriptionHandler } from '..'

type AgentsHandler = VrpcRemoteToListSubscriptionHandler<CybusAgent>
type AgentsHandlerArgs = VrpcHandlerMappingPropertiesArgs<AgentsHandler>

export class VrpcRemoteToCybusAgentHandler implements AgentsHandler {
    readonly type = SubscriptionHandlerType.REMOTE_TO_LIST

    readonly optionalFilters = []

    readonly requiredFilters = []

    readonly domains = [VrpcDomainType.DEFAULT, VrpcDomainType.EDGE]

    // eslint-disable-next-line class-methods-use-this
    mapToDomain ({ remote }: AgentsHandlerArgs['DomainMapperArgs']): Promise<AgentsHandlerArgs['Domain']> {
        const mapper = new AgentsMapper()

        for (const [name, info] of objectEntries(remote.getSystemInformation())) {
            mapper.push({ ...info, name })
        }

        return mapper.values
    }

    // eslint-disable-next-line class-methods-use-this
    onChange ({ remote, listener }: AgentsHandlerArgs['OnChangeArgs']): Promise<AgentsHandlerArgs['OnChangeUnsub']> {
        return Promise.resolve(listenToAgentsChange(remote, 200, listener))
    }
}

import type { RoleCreationRequest } from '../../../../domain'

import type { PermissionPostRequest, RolePostRequest, RolePutRequest } from '../Types'
import { mapToDefault } from './Commons'
import { mapPermissionRequest } from './Permission'

const mapMSEntraIdGroupIds = (
    msEntraIdGroupIds: RoleCreationRequest['msEntraIdGroupIds']
): RolePostRequest['msEntraIdGroupIds'] | RolePutRequest['msEntraIdGroupIds'] => mapToDefault(msEntraIdGroupIds?.join(','), undefined)

export const mapRoleCreationRequestToPostRequest = ({ name, permissions, ldapGroupDn, msEntraIdGroupIds }: RoleCreationRequest): RolePostRequest => ({
    name,
    isShared: true,
    autoGenerated: false,
    permissions: permissions.map(mapPermissionRequest) as PermissionPostRequest[],
    ldapGroupDn: mapToDefault(ldapGroupDn, undefined),
    msEntraIdGroupIds: mapMSEntraIdGroupIds(msEntraIdGroupIds),
})

export const mapRoleEditingRequestToPostRequest = ({ name, permissions, ldapGroupDn, msEntraIdGroupIds }: Omit<RoleCreationRequest, 'id'>): RolePutRequest => ({
    name,
    isShared: true,
    permissions: permissions.map(mapPermissionRequest),
    ldapGroupDn: mapToDefault(ldapGroupDn, undefined),
    msEntraIdGroupIds: mapMSEntraIdGroupIds(msEntraIdGroupIds),
})

import type { PickByValueExact, ValuesType } from 'utility-types'

import { Capability, type StatusType } from '../../../../../../domain'
import type { SubscriptionsTypes } from '../../../../../../application'

import { type BackendJsonResponseContent, type BackendPath, mapToStatusType } from '../../../../../Connectware'
import { type BaseStrategyRequestArgs, BaseSubscriptionStrategy } from '../Base'

type SupportedPaths = Extract<BackendPath, '/api/mappings/+/state' | '/api/nodes/+/state' | '/api/endpoints/+/state'>

type RequestConfig<Path extends SupportedPaths> = Pick<BaseStrategyRequestArgs<Path, never, never>, 'path' | 'method' | 'capability'>
type Config = ValuesType<{ [P in SupportedPaths]: RequestConfig<P> }>

abstract class StatusStrategy<K extends keyof PickByValueExact<SubscriptionsTypes, StatusType>> extends BaseSubscriptionStrategy<K> {
    protected abstract readonly config: Config

    override retrieveOne (id: string): Promise<SubscriptionsTypes[K]> {
        return this.retrieve({
            ...(this.config as typeof this.config & RequestConfig<SupportedPaths>),
            pathParams: [id],
            mapper: (state: BackendJsonResponseContent<SupportedPaths>) => mapToStatusType(state),
            handleNotFound: true,
        })
    }
}

export class MappingsStatusStrategy extends StatusStrategy<'mappingStatus'> {
    readonly requiredPermissions = [Capability.MAPPING_STATE_READ]

    protected readonly config: Config = { capability: Capability.MAPPING_STATE_READ, method: 'GET', path: '/api/mappings/+/state' }
}

export class NodesStatusStrategy extends StatusStrategy<'nodeStatus'> {
    readonly requiredPermissions = [Capability.NODE_STATE_READ]

    protected readonly config: Config = { capability: Capability.NODE_STATE_READ, method: 'GET', path: '/api/nodes/+/state' }
}

export class EndpointsStatusStrategy extends StatusStrategy<'endpointStatus'> {
    readonly requiredPermissions = [Capability.ENDPOINT_STATE_READ]

    protected readonly config: Config = { capability: Capability.ENDPOINT_STATE_READ, method: 'GET', path: '/api/endpoints/+/state' }
}

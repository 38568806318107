import { type CybusService, StatusType } from '../../../../domain'

import { type CreatedCommissioningResourceProperties, type CybusServiceProxyInfo, mapToStatusType } from '../..'

export const mapBaseService = ({ id, name, state, version, isDeviated }: CybusServiceProxyInfo): Omit<CybusService, 'links'> => {
    const status = mapToStatusType(state)
    return {
        id,
        name,
        version: version || null,
        status,
        deviation: isDeviated ? StatusType.DEVIATED : status === StatusType.DISABLED ? StatusType.NOT_ENABLED : StatusType.NO_DEVIATION,
    }
}

export const mapService = (info: CybusServiceProxyInfo, links: CreatedCommissioningResourceProperties<'Cybus::Link'>[]): CybusService => ({
    ...mapBaseService(info),
    links: links.map(({ physicalId: { href, name } }) => ({ href, name })),
})

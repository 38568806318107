import { isArray, type NonNullableValues } from '../../utils'
import { type CybusUser, isOtpValid, type ValidOtp } from '..'

export type LoginForm = Pick<CybusUser, 'username'> & Readonly<{ password: string, otpSecret: string | null, otp: string[] | null, backup: string | null }>

export type AuthenticationRequest = Pick<CybusUser, 'username'> & Readonly<{ password: string }>

export type AuthenticationWithOtpRequest = Pick<CybusUser, 'username'> & Readonly<{ otp: ValidOtp, secret: string }>

export type LoginAppState = Readonly<{ loginForm: LoginForm }>

export type AuthenticationWithBackupcode = Pick<CybusUser, 'username'> & Readonly<{ backupCode: string, secret: string }>

export const selectLoginForm = (s: LoginAppState): LoginForm => s.loginForm

export const isMfaRequired = (form: LoginForm): form is NonNullableValues<LoginForm, 'otpSecret'> => typeof form.otpSecret === 'string'

export const isUsingOtp = (form: LoginForm): form is NonNullableValues<LoginForm, 'otp'> => isArray(form.otp)

export const isUsingBackupCodes = (form: LoginForm): form is NonNullableValues<LoginForm, 'backup'> => typeof form.backup === 'string'

export const selectAuthenticationRequest = (s: LoginAppState): AuthenticationRequest | AuthenticationWithOtpRequest | AuthenticationWithBackupcode | null => {
    const form = selectLoginForm(s)

    const { username } = form

    if (username) {
        if (isMfaRequired(form) && isUsingOtp(form) && isOtpValid(form.otp)) {
            /** User is using otp */
            return { username, secret: form.otpSecret, otp: form.otp }
        }

        if (isMfaRequired(form) && isUsingBackupCodes(form) && form.backup) {
            /** User is using backup codes */
            return { username, secret: form.otpSecret, backupCode: form.backup }
        }

        if (!isMfaRequired(form) && form.password) {
            /** User is using username and password */
            return { username, password: form.password }
        }
    }

    /** No valid means of login found, giving up */
    return null
}

export const selectCanAuthenticate = (s: LoginAppState): boolean => Boolean(selectAuthenticationRequest(s))

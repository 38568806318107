import type { AuthenticationInformation, ConnectwareError } from '../../domain'

export interface BrowserService {
    authenticate(info: AuthenticationInformation | null): void
    reload(): void
    /**
     * @throws {ConnectwareError} if there are issues while redirecting
     */
    redirect(url: URL): void
    getRedirectionURL(): URL | null
    /**
     * Extract from the URL the authentication args
     * Errors are expected, not as if there is an error during the process
     * But because there was an error while generating the authentication token elsewhere
     */
    extractURLAuthentication(): string | ConnectwareError | null
    /**
     * Drop any url authentication parameters
     */
    removeURLAuthentication(): void
    /**
     * @throws {ConnectwareError} if there are issues while opening the window
     */
    open(url: URL, name: string | null): void
    copy(value: unknown): string | null
    sendEmail(target: string, subject: string | null): void
    isInspecting(): boolean
}

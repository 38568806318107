import type { CybusAgent } from '../../../../domain'

import { FilteredAsyncMapper, mapToStatusType } from '..'

type AgentInfo = Readonly<{ name: string, status: string, hostname: string, version: string }>

export class AgentsMapper extends FilteredAsyncMapper<AgentInfo, CybusAgent> {
    /**
     * Agents that should not be considered for listing
     */
    private readonly rejectAgents = new Set(['service-manager', 'system-control-server', 'container-manager'])

    constructor () {
        super({})
    }

    protected override map ({ name, version, hostname, status }: AgentInfo): Promise<CybusAgent> | null {
        if (this.rejectAgents.has(name)) {
            return null
        }

        const nullifiedHostname = hostname || null

        // If agent container was started without hostname argument, we get
        // just the Docker container ID here, which is somewhat confusing.
        const isHostnameContainerId = nullifiedHostname && /^[0-9a-f]{12}$/.exec(nullifiedHostname)

        return Promise.resolve({
            name,
            status: mapToStatusType(status),
            version,
            hostname: isHostnameContainerId ? null : nullifiedHostname,
            containerId: isHostnameContainerId ? nullifiedHostname : null,
        })
    }
}

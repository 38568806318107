import React, { type FC, Fragment, type PropsWithChildren, type ReactElement, type ReactNode } from 'react'
import { Redirect as BaseRedirect, Route as BaseRoute, Switch as BaseSwitch } from 'react-router-dom'

import { type ArrayType, isArray, isEnumOf } from '../../../../utils'

import {
    AbsoluteRouteOnlyPath,
    type AbsoluteRoutePath,
    type AbsoluteRoutePathWithId,
    type AbsoluteRoutePathWithServiceAndResourceId,
    type AbsoluteRoutePathWithServiceId,
} from '../Domain'
import { getRouteInternalPath } from '../Config'
import { ConnectwareRoute, ConnectwareRouter } from './InternalRouter'

type RouteNavigationProps = Readonly<{
    /** Optional right column content */
    rightContent?: ReactNode
}>

type RoutePathsProps = Readonly<
    (
        | { path: AbsoluteRoutePath }
        | { paths: AbsoluteRouteOnlyPath[] | (AbsoluteRoutePathWithServiceId | AbsoluteRoutePathWithId | AbsoluteRoutePathWithServiceAndResourceId)[] }
    ) &
        RouteNavigationProps
>

type RouteProps = PropsWithChildren<RoutePathsProps & RouteNavigationProps>

type AllProps = Readonly<{
    /**
     * Navigations components to be rendered (through injection) inside the router
     */
    appNavigation?: FC

    /**
     * Components that will be rendered again and again for each route
     */
    routeNavigation?: FC<RouteNavigationProps>

    /**
     * Where the application will start
     */
    initial: AbsoluteRouteOnlyPath

    /**
     * In case you get redirected elsewhere
     */
    onNotFound?: AbsoluteRouteOnlyPath

    /**
     * Only expect routes as children
     */
    children: ReactElement<RouteProps>[] | ReactElement<RouteProps>
}>

export const Redirect: FC<{ to: AbsoluteRoutePath }> = ({ to }) => <BaseRedirect to={getRouteInternalPath(to)} />

export const Route: FC<RouteProps> = () => <Fragment />

type SwitchProps = Omit<AllProps, 'appNavigation' | 'router' | 'initial'>

const DefaultRouteNavigation: FC<RouteNavigationProps> = ({ children }) => <>{children}</>

export const Switch: FC<SwitchProps> = ({ routeNavigation: RouteNavigation = DefaultRouteNavigation, onNotFound, children }: SwitchProps) => (
    <BaseSwitch>
        {(isArray(children) ? children : [children]).map((child, k) =>
            child && child.type === Route ? (
                /**
                 * Don't try to move this to another place
                 * The ('react-router-dom').Route needs to be exposed directly to the Switch
                 */
                <BaseRoute
                    key={k}
                    path={'paths' in child.props ? child.props.paths.map((p) => getRouteInternalPath(p)) : getRouteInternalPath(child.props.path)}
                    exact={
                        'paths' in child.props
                            ? child.props.paths.some((p) => isEnumOf(AbsoluteRouteOnlyPath, p))
                            : isEnumOf(AbsoluteRouteOnlyPath, child.props.path)
                    }
                    render={({ match }) => (
                        <RouteNavigation rightContent={child.props.rightContent}>
                            <ConnectwareRoute
                                path={
                                    'paths' in child.props
                                        ? ((child.props.paths as ArrayType<typeof child.props.paths>[]).find(
                                              (p) => getRouteInternalPath(p) === match.path
                                          ) as AbsoluteRoutePath)
                                        : child.props.path
                                }
                            >
                                {child.props.children}
                            </ConnectwareRoute>
                        </RouteNavigation>
                    )}
                />
            ) : (
                <Fragment key={k}>{child}</Fragment>
            )
        )}
        {/* Fallback */}
        {onNotFound && <Redirect to={onNotFound}>{children}</Redirect>}
    </BaseSwitch>
)

export const CybusRouter: FC<AllProps> = ({ appNavigation: AppNavigation = Fragment, ...props }) => (
    <ConnectwareRouter initial={props.initial}>
        <AppNavigation>
            <Switch {...props} />
        </AppNavigation>
    </ConnectwareRouter>
)

import type { ConnectwareError } from '../../../domain'
import { Usecase } from '../Usecase'

export class WorkbenchUsecase extends Usecase {
    open (): void {
        try {
            this.browserService.open(this.configurationService.getWorkbenchURL(), null)
        } catch (e: unknown) {
            this.logger.error(e as ConnectwareError)
        }
    }
}

import type { ConnectwareError, CybusResourceDeviation, CybusService, StatusType } from '../../../domain'
import type { PageSubscriptionsTypes, SingleSubscriptionsTypes } from '../../../application'

type AllTypes = keyof (PageSubscriptionsTypes & SingleSubscriptionsTypes)

export type StatusSupportedType = Extract<
    AllTypes,
    | 'agents'
    | 'connection'
    | 'connections'
    | 'endpoint'
    | 'endpoints'
    | 'endpointStatus'
    | 'mapping'
    | 'mappings'
    | 'mappingStatus'
    | 'nodes'
    | 'nodeStatus'
    | 'server'
    | 'servers'
>

export interface StatusTypeFetcher {
    fetchStatusType(type: StatusSupportedType, resourceId: string): Promise<StatusType>
}

export type ServiceDeviationSupportedType = Extract<AllTypes, 'service' | 'serviceDeviations' | 'serviceResources' | 'services' | 'servicesLinks'>

export type ResourceDeviationSupportedType = Extract<AllTypes, 'service'>

export interface DeviationFetcher {
    /**
     * This method does not detect if the service exists
     * Only if, for that given service, there is a deviation
     */
    fetchIsDeviated(type: ServiceDeviationSupportedType, serviceId: CybusService['id']): Promise<boolean>

    fetchResourceDeviations(type: ResourceDeviationSupportedType, serviceId: CybusService['id']): Promise<CybusResourceDeviation[]>
}

export interface StatusTypeSubscription {
    useCachedStatusType(type: StatusSupportedType, id: string): StatusType | ConnectwareError | null
    /**
     * @param handler called once when setup, and then further when there are changes to that resource state
     */
    subscribeToStatusType(type: StatusSupportedType, id: string, handler: VoidFunction): Promise<VoidFunction>
}

export interface DeviationsSubscription {
    useCachedIsDeviated(type: ServiceDeviationSupportedType, serviceId: CybusService['id']): boolean | ConnectwareError | null

    /**
     * @param handler called once when setup, and then further when there are changes to the deviations count of the given service
     */
    subscribeToServiceDeviationCount(type: ServiceDeviationSupportedType, serviceId: CybusService['id'], handler: VoidFunction): Promise<VoidFunction>
}

export type RSTAdapter = Pick<DeviationFetcher, 'fetchIsDeviated'> &
    Pick<DeviationFetcher, 'fetchResourceDeviations'> &
    Pick<DeviationsSubscription, 'subscribeToServiceDeviationCount'> &
    Pick<StatusTypeFetcher, 'fetchStatusType'> &
    Pick<StatusTypeSubscription, 'subscribeToStatusType'>

import type { CybusNode } from '../../../domain'

import { mapResourceNames, mapToStatusType, type NodeProxyParams } from '..'
import { FilteredAsyncMapper } from './Async'

const mapCybusNode = ({ id, parentId, operation, protocol, currentState }: NodeProxyParams): CybusNode => {
    const [service, name] = mapResourceNames(id)

    return { id, service, name, parent: parentId, operation: operation || null, protocol, status: mapToStatusType(currentState) }
}

export class CybusNodeMapper extends FilteredAsyncMapper<NodeProxyParams, CybusNode> {
    protected map (params: NodeProxyParams): Promise<CybusNode> | null {
        const { server } = this.filter

        if (!server || server === params.parentId) {
            return Promise.resolve(mapCybusNode(params))
        }

        return null
    }
}

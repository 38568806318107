import type { Mutable } from 'utility-types'

import { delay } from '../../../utils'

import {
    type AppState,
    type AuthenticationInformation,
    ConnectwareError,
    isAuthenticated,
    selectAuthenticatedToken,
    selectAuthentication,
    selectAuthenticationExpiresAt,
    selectLoginForm,
} from '../../../domain'

import { initialState } from '../..'
import { Usecase } from '../Usecase'

export abstract class LoginUsecase extends Usecase {
    /**
     * This checks if the user should be redirected, and then does it
     */
    private attemptRedirection (): void {
        const currentUrl = this.browserService.getRedirectionURL()

        if (!currentUrl) {
            return
        }

        try {
            // There is a url, take the user there
            this.browserService.redirect(currentUrl)
        } catch (e: unknown) {
            this.logger.error(e as ConnectwareError)
        }
    }

    private async setupTimedLogout (): Promise<void> {
        const oldAuthentication = selectAuthentication(this.getState())

        if (!isAuthenticated(oldAuthentication)) {
            /**
             * No need for timer, user is not authenticated
             */
            return
        }

        /**
         * Await for token to expire
         */
        await delay(Math.max(selectAuthenticationExpiresAt(oldAuthentication).getTime() - Date.now(), 0))

        const authenticationToken = selectAuthenticatedToken(this.getState())

        if (authenticationToken === oldAuthentication.token) {
            /**
             * If it is the same token after all this time, perform an auto-logout
             */
            this.logout()
        }
    }

    protected persist (info: AuthenticationInformation | null): void {
        /**
         * Authentication on browser
         */
        this.browserService.authenticate(info)

        if (info) {
            this.authenticationPersistenceService.persist(info)
        } else {
            this.authenticationPersistenceService.flush()
        }
    }

    protected updateAuthentication (authentication: AppState['authentication']): void {
        /**
         * Save authentication if there is no error
         */
        if (!ConnectwareError.is(authentication)) {
            this.persist(authentication)
        }

        /**
         * Finally, update the state
         *
         * If the user is authenticated, remove their password so it cannot be reused
         */
        const state: Mutable<Partial<AppState>> = { authentication }
        if (isAuthenticated(authentication)) {
            /** Reset everything but the username */
            state.loginForm = { ...selectLoginForm(initialState), username: authentication.username }
        }
        this.setState(state)

        /**
         * Set up logout to when the token expires
         */
        void this.setupTimedLogout()

        /**
         * Finally redirect the user
         */
        if (isAuthenticated(authentication)) {
            this.attemptRedirection()
        }
    }

    protected updateAuthenticationFromToken (token: string): Promise<void> {
        return this.authenticationService
            .fetchSession(token)
            .then((session) => this.updateAuthentication({ ...session, token, obsolete: null }))
            .catch((error: ConnectwareError) => this.updateAuthentication(error))
    }

    protected logout (): void {
        const token = selectAuthenticatedToken(this.getState())

        if (token !== null) {
            this.authenticationService.flushAuthentication(token).catch((e: ConnectwareError) => this.logger.error(e))
        }

        this.persist(null)
        this.setState({ authentication: null })
    }
}

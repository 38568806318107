import { executeOnce } from '../../../utils'

import type { BackendJsonResponseContent } from '../../Connectware'
import { FetchConnectwareHTTPService } from '../Base'

export abstract class ConnectwareHTTPCapabilitiesService extends FetchConnectwareHTTPService {
    protected readonly fetchEntraStatus = executeOnce(() =>
        this.request({
            /**
             * This endpoint does not require authentication
             */
            capability: null,
            method: 'GET',
            path: '/api/auth/msEntraId',
            authenticate: false,
            handlers: {
                200: async (response) => {
                    const { enabled } = await response.getJson<BackendJsonResponseContent<'/api/auth/msEntraId'>>()
                    return enabled
                },
            },
        })
    )
}

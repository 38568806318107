import { areArrayEquals, createArrayComparator, createEqualityChecker } from '../../utils'
import type { PermissionedEntity, PermissionedForm } from './Base'
import { arePermissionArrayEquals, type CybusUser, isPermissionValid } from '.'

export type CybusRole = PermissionedEntity &
    Readonly<{
        /**
         * The name of the role
         */
        name: string

        /**
         * List of users that are associated with this role
         */
        users: CybusUser['username'][]

        ldapGroupDn: string | null

        /**
         * List of msEntraId groups that are associated with this role
         */
        msEntraIdGroupIds: string[] | null
    }>

type FullRoleForm = Pick<CybusRole, 'name' | 'id' | 'users' | 'ldapGroupDn' | 'canDelete' | 'canEditOthers' | 'canEditName' | 'msEntraIdGroupIds'> &
    PermissionedForm

export type RoleCreationRequest = Pick<FullRoleForm, 'name' | 'permissions' | 'ldapGroupDn' | 'msEntraIdGroupIds'>
export type RoleCreationForm = Pick<
    FullRoleForm,
    | 'name'
    | 'permissions'
    | 'templateInput'
    | 'selectedTemplate'
    | 'templates'
    | 'ldapGroupDn'
    | 'canDelete'
    | 'canEditOthers'
    | 'canEditName'
    | 'msEntraIdGroupIds'
>

export type RoleEditingRequest = Pick<FullRoleForm, 'name' | 'permissions' | 'id' | 'ldapGroupDn' | 'msEntraIdGroupIds'>
export type RoleEditingForm = Pick<
    FullRoleForm,
    | 'name'
    | 'permissions'
    | 'id'
    | 'users'
    | 'templateInput'
    | 'selectedTemplate'
    | 'templates'
    | 'ldapGroupDn'
    | 'canDelete'
    | 'canEditOthers'
    | 'canEditName'
    | 'msEntraIdGroupIds'
>

const areMSEntraIdGroupIdsEquals = (a: CybusRole['msEntraIdGroupIds'], b: CybusRole['msEntraIdGroupIds']): boolean =>
    a === null || b === null ? a === b : areArrayEquals(a, b, { sort: false })

const areRolesEquals = createEqualityChecker<CybusRole>({
    name: null,
    id: null,
    ldapGroupDn: null,
    msEntraIdGroupIds: areMSEntraIdGroupIdsEquals,
    users: createArrayComparator(),
    permissions: arePermissionArrayEquals,
    canEditName: null,
    canEditOthers: null,
    canDelete: null,
})

export const areRoleArraysEquals = (a: CybusRole[], b: CybusRole[]): boolean => areArrayEquals(a, b, { sort: false, equals: areRolesEquals })

/** Compares the editable parts of the role form */
export const areRoleFormsEqual = createEqualityChecker<Pick<RoleCreationForm | RoleEditingForm, 'name' | 'permissions' | 'ldapGroupDn' | 'msEntraIdGroupIds'>>({
    name: null,
    ldapGroupDn: null,
    msEntraIdGroupIds: areMSEntraIdGroupIdsEquals,
    permissions: arePermissionArrayEquals,
})

export const isRoleCreatable = ({ name, permissions }: RoleCreationForm): boolean => name.length > 0 && permissions.every(isPermissionValid)
export const isRoleUpdatable = (form: RoleEditingForm): boolean => form.canEditOthers && isRoleCreatable(form)
export const isRoleRemovable = (r: RoleEditingForm): boolean => Boolean(r.id) && r.canDelete

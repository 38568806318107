import React, { type FC, useCallback, useEffect } from 'react'
import { Box, Button, buttonClasses, type SxProps, type Theme } from '@mui/material'

import { isArray } from '../../../../utils'
import {
    canPasswordBeChanged,
    type ChangePasswordForm as ChangePasswordFormModel,
    ConnectwareError,
    ConnectwareErrorType,
    Translation,
} from '../../../../domain'
import type { ChangePasswordUsecase } from '../../../../application'

import { createOnEnterHandler } from '../..'
import { useAppUsecase } from '../../State'
import { FormattedTranslation, useTranslator } from '../../Internationalization'
import { useAsyncCallback } from '../../Callback'
import { TextField, type TextFieldProps, validationClasses, Validations, validationsClasses } from '../../common'

const wrapperStyle: SxProps<Theme> = {
    [['& > *', `& > .${validationsClasses.root} > .${validationClasses.root}`, `& > .${buttonClasses.root}`].join(', ')]: { mb: 2 },
}
const textFieldProps: Pick<TextFieldProps, 'size' | 'type' | 'required' | 'fullWidth'> = {
    size: 'small',
    type: 'password',
    required: true,
    fullWidth: true,
} as const

const Response: FC<Readonly<{ response: void | ConnectwareError }>> = ({ response }) => {
    const translator = useTranslator()

    /** General error is displayed by default */
    let translation = Translation.PROBLEM_CHANGING_PASSWORD
    let values = {}

    if (response === undefined) {
        /** All good, password changed */
        translation = Translation.PASSWORD_SUCCESSFULLY_CHANGED
    } else if (ConnectwareError.isOfTypes(response, ConnectwareErrorType.AUTHENTICATION)) {
        /** Could not authenticate for whatever reason */
        translation = Translation.AUTHENTICATION_ERROR
        values = { error: ConnectwareErrorType.AUTHENTICATION, authType: null }
    }

    const message = translator.formatTranslation(translation, values)
    return <Validations data-testid="change-password-response" validations={[response ? new ConnectwareError(response, message) : message]} />
}

type Updatable = keyof Parameters<ChangePasswordUsecase['updateForm']>['0']
const useOnPasswordFormChange = <K extends Updatable,>(name: K): ((value: ChangePasswordFormModel[K]) => void) => {
    const usecase = useAppUsecase('changePasswordUsecase')
    return useCallback((value) => usecase.updateForm({ [name]: value }), [usecase, name])
}

export const ChangePasswordForm: FC<Readonly<{ form: ChangePasswordFormModel }>> = ({ form }) => {
    const usecase = useAppUsecase('changePasswordUsecase')
    const [changePassword, isLoading, response] = useAsyncCallback(() => usecase.invoke(), [usecase])

    const canChange = canPasswordBeChanged(form)

    const cantRequestChange = !canChange || isLoading

    const setPassword = useOnPasswordFormChange('password')
    const setNewPassword = useOnPasswordFormChange('newPassword')
    const setConfirmPassword = useOnPasswordFormChange('confirmPassword')

    const passwordValidation =
        form.newPassword || form.confirmPassword
            ? form.passwordValidation === null
                ? []
                : isArray(form.passwordValidation)
                ? form.passwordValidation
                : [form.passwordValidation]
            : null

    useEffect(() => {
        /** When leaving, reset the form, so other people don't see it */
        return () => usecase.toggle()
    }, [usecase])

    return (
        <Box sx={wrapperStyle}>
            <TextField
                {...textFieldProps}
                id="change-password-password"
                name="password"
                label={Translation.CURRENT_PASSWORD}
                labelVariant="body2"
                value={form.password}
                disabled={isLoading}
                onChange={setPassword}
            />

            <TextField
                {...textFieldProps}
                id="change-password-new-password"
                name="new-password"
                label={Translation.NEW_PASSWORD}
                labelVariant="body2"
                value={form.newPassword}
                disabled={isLoading}
                onChange={setNewPassword}
            />

            <TextField
                {...textFieldProps}
                id="change-password-new-confirm-password"
                name="confirm-password"
                placeholder={Translation.CONFIRM_PASSWORD}
                value={form.confirmPassword}
                disabled={isLoading}
                onChange={setConfirmPassword}
                onKeyUp={cantRequestChange ? undefined : createOnEnterHandler(changePassword)}
            />

            <Button
                id="change-password-confirm-new-password"
                variant="contained"
                color="primary"
                fullWidth
                disabled={cantRequestChange}
                onClick={changePassword}
            >
                <FormattedTranslation id={Translation.SAVE_NEW_PASSWORD} />
            </Button>

            {passwordValidation && <Validations data-testid="change-password-form-validation" validations={passwordValidation} />}

            {response !== null && <Response response={response} />}
        </Box>
    )
}

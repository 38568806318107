import React, { type FC, useEffect } from 'react'
import { Box, Link, type SxProps, type Theme, Typography } from '@mui/material'

import { isArray } from '../../../../utils'
import { ConnectwareError, ConnectwareErrorType, selectInfo, Translation } from '../../../../domain'

import { FormattedTranslation } from '../../Internationalization'
import { ErrorMessage } from '../../ErrorMessage'
import { AbsoluteRouteOnlyPath, PermissionedLink } from '../../routing'
import { useAppState, useAppUsecase } from '../../State'
import { CircularLoader, Table } from '../../common'

const wrapperStyle: SxProps<Theme> = { py: 4 }
const titleStyle: SxProps<Theme> = { width: 250 }
const linkStyle: SxProps<Theme> = { fontWeight: 500, textTransform: 'uppercase' }

const ServiceCountText: FC<{ count: number }> = ({ count }) => (
    <>
        {count} <FormattedTranslation id={Translation.SERVICE} values={{ count }} />
    </>
)

export const InformationPage: FC = () => {
    const infoUsecase = useAppUsecase('informationUsecase')
    useEffect(() => infoUsecase.loadInformation(), [])

    const info = useAppState(selectInfo)
    if (info === null || info.servicesCount === null || !info.connectivity) return <CircularLoader />

    const { servicesCount, connectivity, version } = info

    return (
        <Box data-testid="info-details" sx={wrapperStyle}>
            <Table
                hideHeader
                columns={{
                    title: {
                        customCellRender: (title) => (
                            <FormattedTranslation id={isArray(title) ? title[0] : title} values={isArray(title) ? title[1] : undefined} />
                        ),
                        sx: titleStyle,
                    },
                    data: {
                        customCellRender: (value) => (ConnectwareError.is(value) ? <ErrorMessage error={value} extras="popover" /> : value),
                    },
                }}
                data={[
                    {
                        title: Translation.CONNECTWARE_VERSION,
                        data: ConnectwareError.is(connectivity) ? (
                            connectivity
                        ) : (
                            <>
                                <FormattedTranslation
                                    id={Translation.CONNECTWARE_VERSION_INFORMATION}
                                    values={{ latest: version === connectivity.latest?.version ? true : connectivity.latest?.version ?? null, version }}
                                />
                                {Boolean(connectivity.latest?.version && version !== connectivity.latest.version) && (
                                    <>
                                        {' '}
                                        <FormattedTranslation
                                            id={Translation.CONNECTWARE_NEW_SELF_HOSTED_APPLIANCE_VERSION}
                                            values={{
                                                latestVersion: connectivity.latest?.version,
                                                InstallerLink: (...content: string[]) => (
                                                    <Link data-testid="installer-link" href={connectivity.latest?.installerURL.toString()}>
                                                        {content}
                                                    </Link>
                                                ),
                                                ReleaseNotesLink: (...content: string[]) => (
                                                    <Link data-testid="release-notes-link" href={connectivity.latest?.releaseNotes.toString()}>
                                                        {content}
                                                    </Link>
                                                ),
                                            }}
                                        />
                                    </>
                                )}
                            </>
                        ),
                    },
                    {
                        title: [Translation.INSTALLED_SERVICES, { count: 0 }] as const,
                        data: ConnectwareError.is(servicesCount) ? (
                            ConnectwareError.isOfTypes(servicesCount, ConnectwareErrorType.AUTHENTICATION) ? (
                                <Typography color="text.disabled">No permission to see the number of installed services</Typography>
                            ) : (
                                servicesCount
                            )
                        ) : (
                            <PermissionedLink path={AbsoluteRouteOnlyPath.SERVICES_OVERVIEW} sx={linkStyle}>
                                <ServiceCountText count={servicesCount} />
                            </PermissionedLink>
                        ),
                    },
                ]}
            />
        </Box>
    )
}

import type { CybusService } from '../../../../../../domain'
import type { SubscriptionsTypes } from '../../../../../../application'

import { mapService, SERVICE_AGENT } from '../../../../../Connectware'
import { SERVICE_CLASSNAME } from '../../../../constants'
import type { ServiceProxy } from '../../../../proxies'
import { createProxyEventsHandler, SubscriptionHandlerType, type VrpcHandlerMappingPropertiesArgs, type VrpcInstanceToOneSubscriptionHandler } from '..'

type ServiceHandler = VrpcInstanceToOneSubscriptionHandler<ServiceProxy, SubscriptionsTypes['services']>
type HandlerPropertiesArgs = VrpcHandlerMappingPropertiesArgs<ServiceHandler>
type DomainMappingArgs = Pick<HandlerPropertiesArgs['DomainMapperArgs'], 'instance'>

export abstract class BaseServiceProxyHandler<T extends SubscriptionHandlerType> {
    abstract readonly type: T

    readonly optionalFilters = []

    readonly requiredFilters = []

    readonly classNameFilter = SERVICE_CLASSNAME

    readonly agent = SERVICE_AGENT

    readonly ignoreInstances = null

    readonly ignoreInstanceByFilter = null

    readonly sourceInstanceName = null

    readonly onChange = createProxyEventsHandler<ServiceProxy>('state', 'deviation')

    protected async mapToCybusService ({ instance }: DomainMappingArgs): Promise<CybusService> {
        return Promise.all([instance.getInfo(), instance.getLinks()]).then(([info, links]) => mapService(info, links))
    }
}

export class VrpcServiceProxyInstanceHandler extends BaseServiceProxyHandler<SubscriptionHandlerType.INSTANCE_ONE_TO_ONE> implements ServiceHandler {
    readonly type = SubscriptionHandlerType.INSTANCE_ONE_TO_ONE

    mapToDomain (args: DomainMappingArgs): Promise<CybusService> {
        return this.mapToCybusService(args)
    }
}

import { areAgentEquals, Capability, type CybusAgent } from '../../../../../../domain'

import { type AgentResponse, AgentsMapper } from '../../../../../Connectware'
import { BaseSubscriptionStrategy } from '../Base'

export class AgentsStrategy extends BaseSubscriptionStrategy<'agents'> {
    readonly requiredPermissions = [Capability.AGENTS_READ]

    protected override readonly areEquals = areAgentEquals

    override retrieveAll (): Promise<CybusAgent[]> {
        return this.retrieve({
            capability: Capability.AGENTS_READ,
            method: 'GET',
            path: '/api/system/agents',
            mapper: (data: AgentResponse[]) => data,
        }).then((agents) => {
            const mapper = new AgentsMapper()

            for (const info of agents) {
                mapper.push(info)
            }

            return mapper.values
        })
    }
}

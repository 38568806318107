import type { CybusEndpoint } from '../../../../../../domain'
import type { SubscriptionFilterArgs } from '../../../../../../application'

import { CybusEndpointMapper, type EndpointProxyParams } from '../../../../../Connectware'
import { ENDPOINT_CLASSNAME_FILTER, PROTOCOL_MAPPER_RESOURCE_STATE_LISTENER } from '../../../../constants'
import type { EndpointProxy, ResourceStateListenerProxy } from '../../../../proxies'
import { createProxyEventsHandler, SubscriptionHandlerType, type VrpcHandlerMappingPropertiesArgs, type VrpcInstanceToListSubscriptionHandler } from '..'

type EndpointsHandler = VrpcInstanceToListSubscriptionHandler<ResourceStateListenerProxy, CybusEndpoint>
type EndpointsHandlerArgs = VrpcHandlerMappingPropertiesArgs<EndpointsHandler>

/** Retrieve the content on the pattern */
const ENDPOINT_PATTERN = ENDPOINT_CLASSNAME_FILTER.toString().replace(/\//g, '')

const getEndpointsParams = (instance: ResourceStateListenerProxy, { service, connection }: SubscriptionFilterArgs): Promise<EndpointProxyParams[]> =>
    instance.getParams<EndpointProxy>(ENDPOINT_PATTERN, {
        serviceIds: service ? [service] : undefined,
        connectionIds: connection ? [connection] : undefined,
    })

export class VrpcResourceStateListenerEndpointProxyInstanceHandler implements EndpointsHandler {
    readonly type = SubscriptionHandlerType.INSTANCE_ONE_TO_LIST

    readonly optionalFilters = ['connection' as const, 'service' as const]

    readonly requiredFilters = []

    readonly classNameFilter = PROTOCOL_MAPPER_RESOURCE_STATE_LISTENER

    readonly agent = null

    readonly ignoreInstances = null

    readonly ignoreInstanceByFilter = null

    readonly sourceInstanceName = null

    readonly onChange = createProxyEventsHandler<ResourceStateListenerProxy>('state', 'registered', 'unregistered')

    // eslint-disable-next-line class-methods-use-this
    async mapToDomain ({ instance, filter }: EndpointsHandlerArgs['DomainMapperArgs']): Promise<EndpointsHandlerArgs['Domain']> {
        const params = await getEndpointsParams(instance, filter)
        const mapper = new CybusEndpointMapper({})
        params.forEach((param) => mapper.push(param))
        return mapper.values
    }
}

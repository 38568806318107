import type { Mutable } from 'utility-types'

import { executeOnce, isArrayNotEmpty, type NonEmptyArray, type ReadonlyRecord } from '../../../utils'
import { Capability, ConnectwareError, ConnectwareErrorType, type CybusLog, CybusLogSource, Translation } from '../../../domain'
import type { ConnectwareLogsService, LogOptions, RawLogOptions, TranslationService } from '../../../application'

import {
    type AgentResponse,
    type BackendQueryParameters,
    CONTAINER_MANAGER_AGENT,
    type DockerContainersResponse,
    type KubernetesContainersResponse,
    type OrchestratorResponse,
    SERVICE_AGENT,
} from '../../Connectware'
import { type ConnectwareHTTPServiceOptions, FetchConnectwareHTTPService } from '../Base'
import { LogsProcessor } from './Processor'

const PROTOCOL_MAPPER_AGENT = 'protocol-mapper'

type InternalOptions<M> = Readonly<{
    type: CybusLogSource
    container: LogOptions['resource']
    mapper: (logs: string) => M[]
}> &
    Omit<LogOptions, 'resource' | 'levels'>

export class ConnectwareHTTPLogsService extends FetchConnectwareHTTPService implements ConnectwareLogsService {
    private readonly supportsUntil = executeOnce(() =>
        this.request({
            capability: Capability.LOGS_READ,
            method: 'GET',
            path: '/api/core-containers/orchestrator',
            authenticate: true,
            handlers: { 200: (r) => r.getJson<OrchestratorResponse>().then((value) => value.orchestrator === 'Docker') },
        })
    )

    /**
     * List of the container that contain the logs for each type of resource
     *
     * If the value is null, it means the resource it-self manages its logging
     */
    private static readonly internalLoggingContainerName: ReadonlyRecord<CybusLogSource, string | null> = {
        [CybusLogSource.SERVER]: PROTOCOL_MAPPER_AGENT,
        [CybusLogSource.CONNECTION]: PROTOCOL_MAPPER_AGENT,
        [CybusLogSource.SERVICE_CONTAINER]: null,
        [CybusLogSource.CORE_CONTAINER]: null,
        [CybusLogSource.ENDPOINT]: PROTOCOL_MAPPER_AGENT,
        [CybusLogSource.MAPPING]: PROTOCOL_MAPPER_AGENT,
        [CybusLogSource.SERVICE]: SERVICE_AGENT,
        [CybusLogSource.VOLUME]: CONTAINER_MANAGER_AGENT,
    }

    private static readonly fileNames: ReadonlyRecord<CybusLogSource, Translation> = {
        [CybusLogSource.SERVER]: Translation.SERVER,
        [CybusLogSource.CONNECTION]: Translation.CONNECTION,
        [CybusLogSource.SERVICE_CONTAINER]: Translation.CONTAINER,
        [CybusLogSource.CORE_CONTAINER]: Translation.CONTAINER,
        [CybusLogSource.ENDPOINT]: Translation.ENDPOINT,
        [CybusLogSource.MAPPING]: Translation.MAPPING,
        [CybusLogSource.SERVICE]: Translation.SERVICE,
        [CybusLogSource.VOLUME]: Translation.VOLUME,
    }

    /**
     * Certain types of resources provide their own logging, this retrieves them
     */
    private static getInternalLoggingContainerName (type: CybusLogSource): string | null {
        return ConnectwareHTTPLogsService.internalLoggingContainerName[type]
    }

    constructor (options: ConnectwareHTTPServiceOptions, private readonly translationService: TranslationService) {
        super(options)
    }

    private async fetchRelevantContainerNames (containerNameFilter: string): Promise<string[]> {
        const containers = await this.request({
            capability: Capability.LOGS_READ,
            path: '/api/core-containers',
            method: 'GET',
            authenticate: true,
            handlers: { 200: (response) => response.getJson<DockerContainersResponse | KubernetesContainersResponse>() },
        })

        /**
         * Filter relevant containers
         *
         * For each container
         *  Check if it is the `containerNameFilter`
         *  then adds it to the list with some light transforming
         */
        return containers.flatMap(({ Names }) =>
            Names.flatMap((containerName) =>
                containerName && (containerName.includes(`_${containerNameFilter}`) || containerName.includes(`${containerNameFilter}-`))
                    ? [containerName.startsWith('/') ? containerName.slice(1) : containerName]
                    : []
            )
        )
    }

    private async fetchLoggingContainers (type: CybusLogSource, container: LogOptions['resource']): Promise<NonEmptyArray<string>> {
        const internalContainer = ConnectwareHTTPLogsService.getInternalLoggingContainerName(type)
        const filteredContainerNames = internalContainer ? await this.fetchRelevantContainerNames(internalContainer) : [container]

        if (!isArrayNotEmpty(filteredContainerNames) || filteredContainerNames.includes(null)) {
            /**
             * Logs can not be fetched
             */
            throw new ConnectwareError(ConnectwareErrorType.UNEXPECTED, 'No valid containers found', { type, container })
        }

        return filteredContainerNames as NonEmptyArray<string>
    }

    private async fetchContainerLogs<M> ({ type, container, lines, start, end, mapper }: InternalOptions<M>): Promise<M[]> {
        const filteredContainerNames = await this.fetchLoggingContainers(type, container)

        const queryParams: Mutable<BackendQueryParameters<'/api/core-containers/+/logs'>> = {
            timestamps: String(true),
            tail: lines === 'all' ? lines : String(500),
        }

        if (start) {
            queryParams.since = String(start.getTime())
        }

        if (end && (await this.supportsUntil())) {
            queryParams.until = String(end.getTime())
        }

        return Promise.all(
            filteredContainerNames.map(async (loggerContainer) =>
                this.request({
                    capability: Capability.LOGS_READ,
                    path: '/api/core-containers/+/logs',
                    pathParams: [loggerContainer],
                    method: 'GET',
                    authenticate: true,
                    queryParams,
                    handlers: { 200: (resp) => resp.getText().then(mapper) },
                })
            )
        ).then((allLines) => allLines.flat())
    }

    private getFileName (type: CybusLogSource, id: string | null): string {
        /**
         * To figure out the file name
         * We either check if the resource itself does the logging
         * In which case, use it as the name of the file
         * Or use the general translation name
         */
        const name =
            ConnectwareHTTPLogsService.getInternalLoggingContainerName(type) === null && id
                ? `${this.getTimestamp()
                      .toISOString()
                      .split(/\D+/g)
                      .filter((v) => v)
                      .join('-')}-${id}`
                : this.translationService
                      .translate(Translation.ALL_RAW_LOGS, {
                          type: this.translationService.translate(ConnectwareHTTPLogsService.fileNames[type], { count: 0 }),
                      })
                      .toLowerCase()
                      .replaceAll(' ', '-')

        return `${name}.log`
    }

    protected getTimestamp (): Date {
        return new Date()
    }

    /**
     * Only Cybus resource instances with-in a certain a list of agents can have logs
     */
    doesCollectLogs (type: CybusLogSource, id: string): Promise<boolean> {
        const loggingContainer = ConnectwareHTTPLogsService.getInternalLoggingContainerName(type)

        if (loggingContainer === null) {
            /**
             * Since the logging is not internal (the component has logging abilities)
             * It always collects logs
             */
            return Promise.resolve(true)
        }

        return this.request({
            capability: Capability.LOGS_READ,
            method: 'GET',
            path: '/api/system/agents',
            authenticate: true,
            handlers: {
                200: (response) =>
                    response.getJson<AgentResponse[]>().then((agents) => {
                        /** By the type of the resource (EG connection or mapping), retrieve the relevant agent */
                        const agentInfo = agents.find((a) => a.name === loggingContainer) || false

                        /** If agent does not include the given id, it means it does **not** support logging */
                        return agentInfo && Object.values(agentInfo.classes).some((c) => c.instances.includes(id))
                    }),
            },
        })
    }

    async fetchLogs (type: CybusLogSource, { resource, levels, start, end, lines }: LogOptions): Promise<CybusLog[]> {
        const processor = new LogsProcessor(
            /** If the logging is internal, then a search should be done inside it */
            ConnectwareHTTPLogsService.getInternalLoggingContainerName(type) ? resource : null,
            levels,
            /** If setting the until is not supported on the BE, it needs to be done on the FE */
            end !== null && !(await this.supportsUntil()) ? end : null
        )

        return this.fetchContainerLogs({
            type,
            start,
            end,
            container: resource,
            lines,
            mapper: (logs) => processor.process(logs),
        })
    }

    async fetchAllRawLogs (type: CybusLogSource, { resource, lines }: RawLogOptions): Promise<File> {
        const logs = await this.fetchContainerLogs({
            type,
            container: resource,
            lines,
            start: null,
            end: null,
            mapper: (content) => [content],
        })

        return new File([logs.join('')], this.getFileName(type, resource), { type: 'data:text/plain;charset=utf-8' })
    }
}

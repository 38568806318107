import React, { type FC, type ReactNode } from 'react'
import { Box, Collapse, Grid, gridClasses, type GridSize, Input, InputLabel, type InputProps, type SxProps, type Theme, Typography } from '@mui/material'
import type { SvgIconComponent } from '@mui/icons-material'

import { isEnumOf, type ReadonlyRecord } from '../../../../utils'
import { Translation } from '../../../../domain'

import { FormattedTranslation } from '../../Internationalization'

type NumberGridSize = Extract<GridSize, number>

const itemStyle: SxProps<Theme> = { [`&.${gridClasses.item}`]: { py: 0 } }

export const GridFormItem: FC<{ expand: boolean | null, size: NumberGridSize }> = ({ expand, size, children }) => {
    if (expand !== null) {
        children = (
            <Collapse in={expand} unmountOnExit>
                {children}
            </Collapse>
        )
    }

    return (
        <Grid sx={expand === false ? itemStyle : undefined} item xs={size}>
            {children}
        </Grid>
    )
}

export type GridFormEntryProps = Readonly<{
    labelSize?: NumberGridSize
    label?: ReactNode
    endAdornmentSize?: NumberGridSize
    endAdornment?: ReactNode
    expand?: boolean | null
}>

const labelWrapperStyle: SxProps<Theme> = { display: 'flex', '& > *': { my: 'auto', mx: 0 } }

export const GridFormEntry: FC<GridFormEntryProps> = ({ label, labelSize = 3, endAdornmentSize = 2, endAdornment = null, expand = null, children }) => {
    const inputSize = 12 - labelSize - (endAdornment ? endAdornmentSize : 0)

    return (
        <>
            <GridFormItem expand={expand} size={labelSize}>
                <Box sx={labelWrapperStyle}>{label}</Box>
            </GridFormItem>
            <GridFormItem expand={expand} size={inputSize}>
                {children}
            </GridFormItem>
            {endAdornment !== null && (
                <GridFormItem expand={expand} size={endAdornmentSize}>
                    {endAdornment}
                </GridFormItem>
            )}
        </>
    )
}

const labelIconStyle: SxProps<Theme> = { color: 'secondary.main', mr: 1 }

export const EntryLabel: FC<{ icon?: SvgIconComponent }> = ({ icon: Icon, children }) => (
    <>
        {Icon && <Icon sx={labelIconStyle} />}
        {children}
    </>
)

export const EntryInputLabel: FC<{ label: Translation | string, shrink?: boolean, icon?: SvgIconComponent, values?: ReadonlyRecord<string, ReactNode> }> = ({
    label,
    shrink,
    values,
    icon,
}) => (
    <EntryLabel icon={icon}>
        <InputLabel shrink={shrink}>{isEnumOf(Translation, label) ? <FormattedTranslation id={label} values={values} /> : label}</InputLabel>
    </EntryLabel>
)

export const EntryInput: FC<
    Pick<InputProps, 'type' | 'placeholder' | 'value' | 'disabled' | 'onChange' | 'endAdornment' | 'startAdornment' | 'autoComplete'>
> = (props) => <Input fullWidth {...props} />

export const EditableWarning: FC<{ type: 'user' | 'role', canEditAll: boolean, canUpdateMfa?: boolean }> = ({ type, canEditAll, canUpdateMfa = false }) => {
    if (canEditAll) {
        return null
    }
    return (
        <GridFormEntry>
            <Typography data-testid={`${type}-not-editable`} variant="subtitle1">
                <FormattedTranslation id={Translation.NOT_EDITABLE} values={{ canUpdateMfa, type }} />
            </Typography>
        </GridFormEntry>
    )
}

import {
    type AuthenticationInformation,
    ConnectwareError,
    ConnectwareErrorType,
    isAuthenticated,
    selectAuthenticatedToken,
    selectAuthentication,
    selectAuthenticationObsolescence,
} from '../../../domain'

import { LoginUsecase } from './Login'

export class MissingPermissionsUsecase extends LoginUsecase {
    private getNonObsoleteAuthenticationInfo (): AuthenticationInformation | null {
        const authentication = selectAuthentication(this.getState())

        if (!isAuthenticated(authentication) || selectAuthenticationObsolescence(authentication) !== null) {
            /** Already obsolete */
            return null
        }

        return authentication
    }

    private getAuthenticatedToken (): string {
        const token = selectAuthenticatedToken(this.getState())

        if (token === null) {
            throw new ConnectwareError(ConnectwareErrorType.STATE, 'User is not authenticated')
        }

        return token
    }

    /**
     * Used to detect auhthentication obsolence
     */
    async updateObsolence (): Promise<void> {
        if (this.getNonObsoleteAuthenticationInfo() === null) {
            return
        }

        /** Get if obsolence status */
        const status = await this.authenticationService.fetchAuthenticationStatus(this.getAuthenticatedToken()).catch((e: ConnectwareError) => e)

        const authentication = this.getNonObsoleteAuthenticationInfo()
        if (authentication === null) {
            return
        }

        this.updateAuthentication({ ...authentication, obsolete: status })
    }

    /**
     * Used to reload authentication from backend using token on application state
     */
    async reload (): Promise<void> {
        await this.updateAuthenticationFromToken(this.getAuthenticatedToken())
    }
}

import { isArray } from '../../../utils'
import { ConnectwareError, ConnectwareErrorType, type RuleEngineEndpoint, RuleEngineType, selectSandbox } from '../../../domain'

import { BaseSandboxUsecase } from './Base'

export class FilterSandboxUsecase extends BaseSandboxUsecase {
    selectType (type: RuleEngineType): void {
        const { input } = selectSandbox(this.getState())

        this.setSandbox({
            type,
            /** Clear the endpoint selection */
            selectedEndpoints: type === RuleEngineType.ENDPOINT ? [] : null,
            /** De-select all topics */
            selectedTopics: null,
            /**
             * Reset input
             * if the new type is json, attempt to migrate from previous value, or set empty object
             * otherwise, just nulify it as unloaded
             */
            input:
                type === RuleEngineType.JSON
                    ? { loaded: true, topic: null, value: ConnectwareError.is(input.value) || input.value === null ? {} : input.value }
                    : { loaded: false, topic: null, value: null },
        })
    }

    setJson (json: unknown): void {
        this.setSandbox({
            type: RuleEngineType.JSON,
            selectedEndpoints: null,
            selectedTopics: null,
            input: { loaded: true, topic: null, value: json },
        })
    }

    selectEndpoints (selectedEndpoints: RuleEngineEndpoint['id'][]): void {
        const { endpoints } = selectSandbox(this.getState())

        if (!isArray(endpoints)) {
            throw new ConnectwareError(ConnectwareErrorType.STATE, 'Endpoints have not loaded, this flow should not be possible')
        }

        const ids = new Set(selectedEndpoints)
        const selected: RuleEngineEndpoint['id'][] = []
        const selectedTopics: string[] = []

        endpoints.forEach(({ id, topics }) => {
            if (ids.has(id)) {
                selected.push(id)
                selectedTopics.push(...topics)
            }
        })

        this.setSandbox({ selectedEndpoints: selected, selectedTopics })
    }

    selectTopics (selectedTopics: string[]): void {
        this.setSandbox({ selectedTopics })
    }

    setTransformation (transformation: string): void {
        this.setSandbox({ transformation })
    }
}

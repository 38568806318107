import type { ReadonlyRecord } from '../../../utils'

/**
 * @todo generate this type from BE images
 * @see https://cybusio.atlassian.net/browse/CC-2690
 * @see https://bitbucket.org/cybusio/cybus/src/e9747b43bf8d5e80b3a7976129d30a8a60e7cd8d/protocol-mapper/src/Endpoint.js?at=main#lines-176
 * @see https://bitbucket.org/cybusio/cybus/src/db84a1b7ca09d3d37998da0664249760ffefe8b6/protocol-mapper/src/swagger/swagger.yaml?at=main#lines-522
 */
export type EndpointProxyParams = Readonly<{
    id: string
    currentState: string
    targetState: string

    agentName: string

    /** @see https://docs.cybus.io/1-7-3/documentation/services/structure-of-commissioning-files/resources/cybus-endpoint */
    protocol:
        | 'Ads'
        | 'Bacnet'
        | 'EthernetIp'
        | 'GenericVrpc'
        | 'Hbmdaq'
        | 'Heidenhain'
        | 'Http'
        | 'Modbus'
        | 'Mqtt'
        | 'Mssql'
        | 'Opcda'
        | 'Opcua'
        | 'Profinet'
        | 'S7'
        | 'Shdr'
        | 'Sinumerik'
        | 'Sopas'
        | 'Sql'
        | 'Werma'
        | string

    operation: 'read' | 'subscribe' | 'write'

    connectionId?: string

    address: ReadonlyRecord<string, unknown>
    topicPrefix: string

    rules?: unknown[]

    /** @todo finish mapping */
    /** @todo move to backend @see https://cybusio.atlassian.net/browse/CYB-3713 */
}>

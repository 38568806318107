import React, { type FC } from 'react'
import type { SxProps, Theme } from '@mui/material'

import { Translation } from '../../../../domain'
import { DocumentationType } from '../../../../application'

import { DocumentationLink } from '../../Documentation'
import { createFormatter, FormattedTranslation } from '../../Internationalization'

const documentationLinkStyle: SxProps<Theme> = { cursor: 'pointer' }

export const CommissioningFileDocumentationLink: FC<{ renderer?: typeof FormattedTranslation }> = ({ renderer: Renderer = FormattedTranslation }) => (
    <Renderer
        id={Translation.EDIT_SERVICE_PAGE_CHECKOUT_DOCUMENTATION}
        values={{
            link: createFormatter(DocumentationLink, { sx: documentationLinkStyle, color: 'inherit', type: DocumentationType.SERVICES_COMMISSIONING_FILE }),
        }}
    />
)

import { copyObjectWithoutKeys, decodeFromBase64, objectEntries } from '../../../../utils'
import type { CybusConfiguredResources, CybusDetailedService } from '../../../../domain'
import {
    type CreatedCommissioningResourceProperties,
    type CybusServiceDependencies,
    type CybusServiceProxyParams,
    mapConnectionConfiguration,
    mapContainer,
    mapCybusAvailableResources,
    mapCybusResourceDeviations,
    mapHttpServer,
    mapMappingsConfiguration,
    mapMarketplace,
    mapOpcuaServer,
    mapService,
    RoutesMapper,
    UserAndRolesConfigurationMapper,
} from '../..'

export const mapDetailedService = (
    { id, currentState, commissioningData, resources, marketplace, parameters, commissioningFile, deviation }: CybusServiceProxyParams,
    dependencies: CybusServiceDependencies
): CybusDetailedService => {
    const resourceDeviations = mapCybusResourceDeviations(deviation)
    const domainService = mapService(
        {
            id,
            name: commissioningData.metadata.name,
            state: currentState,
            version: commissioningData.metadata.version,
            isDeviated: resourceDeviations.length > 0,
        },
        Object.values(resources).filter((r): r is CreatedCommissioningResourceProperties<'Cybus::Link'> => r.type === 'Cybus::Link')
    )

    const userAndRoles = new UserAndRolesConfigurationMapper()
    const routes = new RoutesMapper()
    const containers: CybusConfiguredResources['containers'] = []
    const volumes: CybusConfiguredResources['volumes'] = []
    const connections: CybusConfiguredResources['connections'] = []
    const mappings: CybusConfiguredResources['mappings'] = []
    const servers: CybusConfiguredResources['servers'] = []
    const endpoints: CybusConfiguredResources['endpoints'] = []

    objectEntries(commissioningData.resources).forEach(([name, resource]) => {
        switch (resource.type) {
            case 'Cybus::Role':
                userAndRoles.addRole(name, resource.properties.permissions)
                break
            case 'Cybus::User':
                userAndRoles.addUser(name, resource.properties.roles, resource.properties.permissions)
                break
            case 'Cybus::IngressRoute':
                routes.map(resource.properties)
                break
            case 'Cybus::Container':
                containers.push(mapContainer(name, resource.properties))
                break
            case 'Cybus::Volume':
                volumes.push(`${domainService.id}-${name}`)
                break
            case 'Cybus::Connection':
                connections.push(mapConnectionConfiguration(name, resource.properties))
                break
            case 'Cybus::Mapping':
                mappings.push(...mapMappingsConfiguration(resource.properties, domainService.id))
                break
            case 'Cybus::Server::Opcua':
                servers.push(mapOpcuaServer(name, 'properties' in resource ? resource.properties : null))
                break
            case 'Cybus::Server::Http':
                servers.push(mapHttpServer(name, 'properties' in resource ? resource.properties : null))
                break
            case 'Cybus::Endpoint':
                endpoints.push(name)
                break
        }
    })

    const servicesCatalog = mapMarketplace(marketplace)

    return {
        ...domainService,
        version: (servicesCatalog ? servicesCatalog.version : domainService.version) || null,
        description: commissioningData.description.trim(),
        icon: commissioningData.metadata.icon || null,
        provider: commissioningData.metadata.provider || null,
        catalog: servicesCatalog ? { directory: servicesCatalog.directory, filename: servicesCatalog.filename } : null,
        updatedAt: servicesCatalog && !isNaN(servicesCatalog.updatedAt.getTime()) ? servicesCatalog.updatedAt : null,
        configuredResources: {
            ...userAndRoles.flush,
            tcpRoutes: routes.tcpRoutes,
            httpRoutes: routes.httpRoutes,
            containers,
            volumes,
            connections,
            mappings,
            servers,
            endpoints,
        },
        availableResources: mapCybusAvailableResources(resources, resourceDeviations),
        commissioningFile: decodeFromBase64(commissioningFile),
        /** Id should not be passed on the parameters */
        parameters: copyObjectWithoutKeys(parameters, 'id'),
        deviations: resourceDeviations,
        dependencies,
    }
}

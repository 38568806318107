import {
    type BackupMaintenanceStatus,
    ConnectwareError,
    ConnectwareErrorType,
    type Metrics,
    MissingBackupFileError,
    type SystemConnectivity,
} from '../../../domain'

import type { HttpResponse } from '../Base'
import type { MaintenanceDbResponse, MaintenanceDbStatusResponse, MetricsResponse, PreflightResponse } from './Response'

export const isVersionOutdated = (latestVersion: string, currentVersion: string): boolean => {
    return latestVersion.localeCompare(currentVersion, undefined, { numeric: true }) === 1
}

const licenseErrorPatterns = { unableToRefresh: /^Unable to refresh license: .*$/gm, unableToUpdate: /^Unable to update license file: .*$/gm }

export const mapLicenseRequestError = async (
    response: HttpResponse,
    errorPattern: keyof typeof licenseErrorPatterns
): Promise<ConnectwareError | undefined> => {
    const data = await response.getJson<{ message?: unknown }>()

    if ('message' in data && typeof data.message === 'string' && licenseErrorPatterns[errorPattern].test(data.message)) {
        /**
         * There was an issue with license itself
         *
         * Either is still expired or not active
         *
         * Can not be of type ConnectwareErrorType.CONFIGURATION_ERROR,
         * because it will be ignored then by https://bitbucket.org/cybusio/cybus/src/eb7e2227a7d679659cb6d6f3aa01d1e0b4631bc7/admin-web-app/src/application/usecases/License/Reload.ts
         */
        return new ConnectwareError(ConnectwareErrorType.BAD_REQUEST, data.message)
    }

    return undefined
}

export const mapConnectivity = ({ cybusPortal, cybusRegistry, dockerHub, currentVersions }: PreflightResponse): SystemConnectivity => {
    const latest = currentVersions?.series.active.latest
    return {
        endpoints: {
            cybusPortal: { url: new URL(cybusPortal.url), reachable: cybusPortal.status },
            cybusRegistry: { url: new URL(cybusRegistry.url), reachable: cybusRegistry.status },
            dockerHub: { url: new URL(dockerHub.url), reachable: dockerHub.status },
        },
        latest: latest
            ? {
                  version: latest.version,
                  releaseNotes: new URL(latest.urlReleaseNotes),
                  installerURL: new URL(latest.installerUrl),
              }
            : null,
    }
}

export const mapMetrics = ({ hourly, daily }: MetricsResponse, connectivity: SystemConnectivity): Metrics => ({
    hourly:
        hourly
            ?.filter((h) => h.numberOfConnectedDatapoints !== 0 || h.numberOfReceivedMessages !== 0)
            .map(({ numberOfConnectedDatapoints, numberOfReceivedMessages, timestamp }) => ({
                numberOfConnectedDatapoints,
                numberOfReceivedMessages,
                timestamp: new Date(timestamp),
            })) ?? [],
    daily:
        daily?.map(({ numberOfConnectedDatapoints, numberOfReceivedMessages, date }) => ({
            date: new Date(date),
            numberOfConnectedDatapoints,
            numberOfReceivedMessages,
        })) ?? [],
    isPortalReachable: connectivity.endpoints.cybusPortal.reachable,
})

const mapMaintenanceDbStatusResponse = ({ succeeded, endDate }: MaintenanceDbStatusResponse): Date | null => (succeeded && endDate ? new Date(endDate) : null)

export const mapMaintenanceDbStatus = ({ restore: { fileStatus, ...restore }, backup }: MaintenanceDbResponse): BackupMaintenanceStatus => ({
    /**
     * For some reason, the backup will be the one having an issue with the missing file
     * But its on the response of the restore Â¯\_(ãƒ„)_/Â¯
     */
    backup: fileStatus.path && fileStatus.missing ? new MissingBackupFileError(fileStatus.path) : mapMaintenanceDbStatusResponse(backup),
    restore: mapMaintenanceDbStatusResponse(restore),
})

export const mapBackupFile = async (response: Pick<HttpResponse, 'getHeaders' | 'getBlob'>): Promise<File> => {
    const headers = new Map(response.getHeaders())

    const type = headers.get('content-type')
    const contentDisposition = headers
        .get('content-disposition')
        ?.split(';')
        .reduce<string | null>((reduced, content) => {
            const [name, value] = content.trim().split('=')
            const newValue = name === 'filename' && value
            return newValue || reduced
        }, null)
    const date = headers.get('date')

    if (!date || !type || !contentDisposition) {
        throw new ConnectwareError(ConnectwareErrorType.MAPPING_ERROR, 'Could not retrieve necessary headers headers', { headers: Array.from(headers) })
    }

    return new File([await response.getBlob()], contentDisposition, { type, lastModified: new Date(date).getTime() })
}

export const mapBackupFileError = async (response: Pick<HttpResponse, 'getJson' | 'getText'>): Promise<ConnectwareError> => {
    const jsonOrError = await response.getJson().catch((e: ConnectwareError) => e)

    if (!ConnectwareError.is(jsonOrError) && jsonOrError && typeof jsonOrError === 'object' && 'file' in jsonOrError && typeof jsonOrError.file === 'string') {
        /**
         * File is missing from the BE
         */
        return new MissingBackupFileError(jsonOrError.file)
    }

    return new ConnectwareError(ConnectwareErrorType.UNEXPECTED, 'Unexpected error given by backend', { raw: await response.getText() })
}

import React, { type FC, useEffect } from 'react'
import { Box, Button, CircularProgress, type SxProps, type Theme } from '@mui/material'
import { DownloadRounded, ReportProblem } from '@mui/icons-material'

import { isArray } from '../../../../utils'
import {
    CommissioningFileFormState,
    ConnectwareError,
    ConnectwareErrorType,
    isCommissioningFileFormInState,
    selectCommissioningFileFormError,
    selectCommissioningFileFormFields,
    selectCommissioningFileFormOutput,
    selectCommissioningFileFormValues,
    selectEditTemplate,
    Translation,
} from '../../../../domain'

import { useAppState, useAppUsecase } from '../../State'
import { FormattedTranslation } from '../../Internationalization'
import { useRouting } from '../../routing'
import { ErrorMessage } from '../../ErrorMessage'
import { useAsyncCallback } from '../../Callback'
import { CircularLoader, DetailsHeader } from '../../common'
import { CommissioningFileProvider, useMetadata } from './State'
import { MetadataTable } from './Metadata'
import { CommissioningFileDocumentationLink } from './Documentation'
import { ConnectionsTable } from './Connections'
import { EndpointsTable } from './Endpoints'

const Header: FC<{ name?: string }> = ({ name }) => (
    <Box data-testid="service-edit-form-header">
        <DetailsHeader
            title={Translation.EDIT_SERVICE_PAGE_TITLE}
            titleAppendix={name}
            description={[Translation.EDIT_SERVICE_PAGE_DOCUMENTATION_TITLE, Translation.EDIT_SERVICE_PAGE_DOCUMENTATION_BODY]}
        />
    </Box>
)

const HeaderWithName: FC = () => {
    const { value } = useMetadata('name')
    return <Header name={value ?? ''} />
}

const downloadButtonStyle: SxProps<Theme> = { textTransform: 'none' }
const DownloadButton: FC = () => {
    const hasOutputFile = useAppState((s) => {
        const output = selectCommissioningFileFormOutput(selectEditTemplate(s))
        return isArray(output) && !ConnectwareError.is(output[1])
    })

    const manage = useAppUsecase('manageEditServiceTemplateUsecase')
    const [download, isDownloading, error] = useAsyncCallback(() => manage.downloadOutputFile(), [manage])

    if (!hasOutputFile) {
        return null
    }

    return (
        <Button
            data-testid="service-edit-download-button"
            disabled={isDownloading}
            sx={downloadButtonStyle}
            variant="contained"
            startIcon={
                isDownloading ? (
                    <CircularProgress color="inherit" size={20} />
                ) : ConnectwareError.is(error) ? (
                    <ReportProblem fontSize="small" />
                ) : (
                    <DownloadRounded fontSize="small" />
                )
            }
            color={ConnectwareError.is(error) ? 'error' : undefined}
            title={ConnectwareError.is(error) ? error.message : undefined}
            onClick={download}
        >
            <FormattedTranslation id={Translation.EDIT_SERVICE_DOWNLOAD_BUTTON_TEXT} />
        </Button>
    )
}

const mainColumnWrapperStyle: SxProps<Theme> = { '& > *': { mt: 3 } }
export const Form: FC = () => {
    const routing = useRouting()

    const manage = useAppUsecase('manageEditServiceTemplateUsecase')

    const isInitial = useAppState((s) => isCommissioningFileFormInState(selectEditTemplate(s), CommissioningFileFormState.INITIAL))
    const fields = useAppState((s) => selectCommissioningFileFormFields(selectEditTemplate(s)))
    const values = useAppState((s) => selectCommissioningFileFormValues(selectEditTemplate(s)))
    const error = useAppState((s) => selectCommissioningFileFormError(selectEditTemplate(s)))

    useEffect(() => {
        if (isInitial) {
            /** Nothing is loaded, take user back */
            routing.redirectToParent()
        }

        return () => {
            if (!isInitial) {
                /** Unload form */
                manage.reset()
            }
        }
    }, [isInitial, manage])

    const data = fields && !ConnectwareError.is(fields) && values && !ConnectwareError.is(values) && { fields, values }

    return (
        <Box sx={mainColumnWrapperStyle}>
            {/* Either display the data and tables or the header with an error message/loader */}
            {data ? (
                <CommissioningFileProvider value={data}>
                    <HeaderWithName />
                    <MetadataTable />
                    <ConnectionsTable />
                    <EndpointsTable />
                    <Box>
                        <DownloadButton />
                    </Box>
                </CommissioningFileProvider>
            ) : (
                <>
                    <Header />
                    {error ? (
                        <ErrorMessage
                            data-testid="service-edit-main-error"
                            titleVariant="h6"
                            error={error}
                            stack
                            extras="section"
                            customTitle={(e) => (
                                <>
                                    {e.message}
                                    {ConnectwareError.isOfTypes(e, ConnectwareErrorType.MAPPING_ERROR) && (
                                        <>
                                            {' '}
                                            <CommissioningFileDocumentationLink />
                                        </>
                                    )}
                                </>
                            )}
                        />
                    ) : (
                        <CircularLoader data-testid="service-edit-main-loader" />
                    )}
                </>
            )}
        </Box>
    )
}

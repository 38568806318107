import type { ContainerManagerOrchestratorProxy, DockerProxy } from '../../../../proxies'
import {
    createProxyEventsHandler,
    type SubscriptionHandlerType,
    type UnsubFromInstanceFunction,
    type VrpcHandlerMappingPropertiesArgs,
    type VrpcSubscriptionHandler,
} from '..'
import { fetchImplementationResolver } from './Implementation'

type SupportedTypes =
    | SubscriptionHandlerType.INSTANCE_ONE_TO_LIST
    | SubscriptionHandlerType.INSTANCE_ONE_TO_ONE
    | SubscriptionHandlerType.INSTANCE_ONE_TO_VIRTUAL_ONE
type ChangeArgs<VrpcIsntance> = VrpcHandlerMappingPropertiesArgs<VrpcSubscriptionHandler<VrpcIsntance, unknown, SupportedTypes>>['OnChangeArgs']

const emptyUnhook: VoidFunction = () => {}

/**
 * @returns a ContainerManagerOrchestratorProxy listener
 * @see {ContainerManagerOrchestratorProxy}
 */
export const createContainerManagerOrchestratorProxyHandler = async (
    args: ChangeArgs<ContainerManagerOrchestratorProxy>
): Promise<UnsubFromInstanceFunction> => {
    const resolver = await fetchImplementationResolver(args.instance)

    if (resolver.isDocker(args.instance)) {
        const createDockerProxyHandler = createProxyEventsHandler<DockerProxy>('dockerEvent')
        return createDockerProxyHandler(args as typeof args & ChangeArgs<typeof args.instance>)
    }

    /** Do nothing */
    return emptyUnhook
}

import type { CybusDetailedConnection } from '../../../../../../domain'

import { type ConnectionProxyParams, mapDetailedConnection } from '../../../../../Connectware'
import { CONNECTION_CLASSNAME_FILTER } from '../../../../constants'
import { VrpcBaseConnectionProxyInstanceHandler } from './Base'

export class VrpcDetailedConnectionProxyInstanceHandler extends VrpcBaseConnectionProxyInstanceHandler<'connection'> {
    readonly optionalFilters = []

    readonly requiredFilters = []

    readonly classNameFilter = CONNECTION_CLASSNAME_FILTER

    readonly ignoreInstances = null

    readonly ignoreInstanceByFilter = null

    protected override mapConnection (connection: ConnectionProxyParams): CybusDetailedConnection {
        return mapDetailedConnection(connection)
    }
}

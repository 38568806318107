import { areObjectsDeepEqual, Cache, type ReadonlyRecord } from '../../../utils'
import { Capability, ConnectwareError, ConnectwareErrorType, CybusRuleEngineExecutionError } from '../../../domain'
import type { RuleEngineService } from '../../../application'

import type { BackendJsonRequestContent } from '../../Connectware'
import { FetchConnectwareHTTPService } from '../Base'

export type Response = Readonly<{
    error?: Readonly<{
        prefix: string | null
        details: string
        rule: ReadonlyRecord<string, unknown>
        ruleName: string
        message: ReadonlyRecord<string, unknown>
    }>
    output?: unknown
}>

export class ConnectwareHTTPRuleEngineService extends FetchConnectwareHTTPService implements RuleEngineService {
    /** Setup cache for successful results */
    private readonly cache = new Cache<Promise<unknown>>()

    /**
     * This is needed to make sure we are only sending to the backend objects that are serializable
     */
    private static isRuleEngineTransformInput (input: unknown): input is BackendJsonRequestContent<'/api/rule-engine/transform', 'post'>['input'] {
        return areObjectsDeepEqual(input, JSON.parse(JSON.stringify(input)))
    }

    transform (input: unknown, transformation: string): Promise<unknown> {
        if (!ConnectwareHTTPRuleEngineService.isRuleEngineTransformInput(input)) {
            return Promise.reject(new ConnectwareError(ConnectwareErrorType.UNEXPECTED, 'Unsupported transformation type', { input }))
        }

        return this.cache.get(
            () =>
                this.request<'/api/rule-engine/transform', BackendJsonRequestContent<'/api/rule-engine/transform', 'post'>, unknown>({
                    capability: Capability.RULE_ENGINE_SANDBOX_USE,
                    method: 'POST',
                    path: '/api/rule-engine/transform',
                    body: { input, transformation },
                    authenticate: true,
                    handlers: {
                        200: async (response) => {
                            const data = await response.getJson<Response>()

                            if (data.error) {
                                const { prefix: messagePrefix, details: messageDetails, rule, ruleName, message: payload } = data.error
                                throw new CybusRuleEngineExecutionError({ messagePrefix, messageDetails, rule, ruleName, payload })
                            }

                            // This handles undefined output
                            return 'output' in data ? data.output : undefined
                        },
                    },
                }),
            input,
            transformation
        )
    }
}

export const areEquals = <T>(
    a: T[] | readonly T[],
    b: T[] | readonly T[],
    config: { sort?: ((a: T, b: T) => number) | false, equals?: (a: T, b: T) => boolean } = {}
): boolean => {
    if (a === b) {
        return true
    }

    const { sort, equals = (a: T, b: T) => a === b } = config
    let aCopy: T[] = a as T[]
    let bCopy: T[] = b as T[]

    if (sort !== false) {
        // Copy arrays before sorting
        aCopy = [...aCopy]
        bCopy = [...bCopy]

        aCopy.sort(sort)
        bCopy.sort(sort)
    }

    return aCopy.length === bCopy.length && aCopy.every((aElement, k) => aCopy === bCopy[k] || equals(aElement, bCopy[k] as T))
}
